import React from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaDownload,
  FaCaretDown,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./community.css";
import DownloadAppSection from "../../components/MobileApps/DownloadAppSection";
import Footer from "../Footer/Footer";
import FooterBottom from "../FooterBottom/FooterBottom";

const {
  WHITE_LOGO,
  RECYCLE_PROCESS,
  PLAY_STORE,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  THUMBNAIL1,
  THUMBNAIL2,
  THUMBNAIL3,
  THUMBNAIL4,
  THUMBNAIL5,
  THUMBNAIL6,
  THUMBNAIL7,
} = require("../../utils/imagepath");

export default function Community() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="community">
      <div className="banner">
        <div className="main-header">
          <Link className="logo">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn" to="/login">
            Login/Signup
          </Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/Partnership">
            <FaBars className="hamburger" />
          </Link>
        </div>
        <h1>HOUSING SOCIETY PROGRAMME</h1>
      </div>
      <div className="common-description">
        <div className="wrapper">
          <h1>
            WE CAN HELP YOU MAKE YOUR WORLD,
            <br /> YOUR SOCIETY BETTER, TOGETHER.
          </h1>
          <p>
            Plastic is the world's packaging choice for many foods and
            beverages, because it's hygienic, strong, light-weighted, safe and
            recyclable. However, we blame plastic for pollution, whereas in
            reality it is us who should be blamed for irresponsible behavior
            towards plastic disposal. As a responsible citizen, do not chuck
            those used plastic items anywhere and everywhere and don't allow
            them to pile up in your kitchen for days. It's time we educate
            ourselves and take measures towards efficient disposal of used
            Plastic and help them sent for direct recycling. Participate in the{" "}
            <strong>'BOTTLES FOR CHANGE'</strong> programme and set a precedent
            for your future generation to lead the path to a cleaner and greener
            tomorrow.
          </p>
          <h2>
            HERE'S HOW HOUSING SOCIETIES ARE BRINGING ABOUT
            <br /> A CHANGE BY RECYCLING RESPONSIBLY
          </h2>
        </div>
      </div>
      <Carousel
        infinite={true}
        containerClass="thumbnail-carousel"
        customLeftArrow={<FaAngleLeft className="arrow-left" />}
        customRightArrow={<FaAngleRight className="arrow-right" />}
        responsive={responsive}
      >
        <div className="item">
          <img src={THUMBNAIL1} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL2} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL3} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL4} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL5} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL6} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL7} alt="" />
        </div>
      </Carousel>
      <div className="recyle-process">
        <h3>START RECYCLING IN YOUR SOCIETIES</h3>
        <div className="grey-block">
          <img src={RECYCLE_PROCESS} alt="" />
        </div>
      </div>
      {/* <div className="join-initiative">
        <h4>JOIN THE INITIATIVE</h4>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Name of Housing Society<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Society"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Total No of Household in Society<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter No.s Household"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Name of the Key Contact Person<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Key Contact Person"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              How are you Related<span>*</span>
            </label>
            <select className="form-control">
              <option>Select</option>
              <option>Secretary</option>
              <option>Member</option>
              <option>Others</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Mobile Number<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Mobile Number"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>Alternate Number</label>
            <input
              type="text"
              value=""
              placeholder="Enter Alternate Number"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Address<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Address"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              State<span>*</span>
            </label>
            <select className="form-control">
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Delhi">Delhi</option>
              <option value="Dadra and Nagar Haveli and Daman &amp; Diu">
                Dadra and Nagar Haveli and Daman &amp; Diu
              </option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              City<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter City"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Pincode<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Pincode"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Email Id</label>
            <input
              type="text"
              value=""
              placeholder="Enter Email Id"
              className="form-control"
            />
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              <input type="checkbox" style={{ marginRight: 5 }} />I agree to{" "}
              <Link className="link">Terms & Conditions</Link>
              <span>*</span>
            </label>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1" style={{ textAlign: "center" }}>
            <Link className="discover-btn">Submit</Link>
          </div>
        </div>
      </div> */}
      {/* <div className="wrapper">
        <div className="app-section">
          <div className="app-img">
            <img src={PLAY_STORE} alt="" />
          </div>
          <div className="app-text">
            <h5>DOWNLOAD BOTTLES FOR CHANGE APP NOW</h5>
            <a href="#" className="download-btn">
              <FaDownload className="ico" /> Download the App
            </a>
            <p>
              With this app you can locate the nearest plastic agent
              (kabadiwala) in your area and put segregated plastic in the right
              channel. Segregated plastic will be picked by our plastic agent
              and then sent for recycling.
            </p>
          </div>
        </div>
      </div> */}
      <DownloadAppSection />
      <Footer />
      <FooterBottom />
      {/* <div className="footer">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="mb-4 md:mb-0">
            <ul>
              <li>
                <Link to="/about-pet">Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link to="/about-bfc">
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link to="/milestone-initative">Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
              <li>
                <Link to="/PartnershipPower">Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <a
              className="social-icon"
              href="https://www.facebook.com/bottles4change/"
              target="_blank"
            >
              <img src={FACEBOOK} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
              target="_blank"
            >
              <img src={TWITTER} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/bottles_4_change/?hl=en"
              target="_blank"
            >
              <img src={INSTAGRAM} alt="" className="ico" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
