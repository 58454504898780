import React, { useState , useEffect} from 'react';
import { Link } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
import Notification from '../../components/Notification/Notification'
import { createFeedback } from '../../api/requestorApi/sfdx_requestor_apis';
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import './NewFeedback.css'
import swal from 'sweetalert';
const {BACK_BUTTON, GIRL} =  require('../../utils/imagepath')


const NewFeedback = ({ auth, history }) => {  
  const [showModal, setshowModal] = React.useState(false)
    // function handleSubmit(){
    //     setshowModal(true);
    // }

    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [sfId, setSfId] = useState("");
    const [selectedRating, setSelectedRating] = useState(1);
    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
      };
    
      const handleDesciptionChange = (event) => {
        setDescription(event.target.value);
      };

      const handleSuccess = (response) => {
        console.log('Feedback submitted successfully:', response);
        setshowModal(true);
        window.location.href = '/Feedback'; 
      };
    

      useEffect(() => {
        const fetchData = async () => {
          try {
            const doc = await firebase
              .firestore()
              .collection("user")
              .doc(auth.uid)
              .get();
      
            console.log("FB Data ", doc.data());
            setSfId(doc.data().sf_id);
          } catch (error) {
            console.error("Error fetching mentor profile ", error);
          }
        };
      
        if (auth.uid) {
          fetchData();
        }
      }, [auth.uid]);
      
      const handleSubmit = () => {
        debugger;
        if(!description){
          swal({
            title:'New Feedback',
            text:'Please enter feedback description',
            icon:'error'
          });
          return;
        }
        if(!selectedRating){
          swal({
            title:'New Feedback',
            text:'Please select rating',
            icon:'error'
          });
          return;
        }
        const feedbackData = {
          title: '',
          comment:description,
          description: description,
          ratingNumber:selectedRating
        };
        console.log('feedback data - - ',feedbackData);
      
        if (sfId) {
      
          // Pass the callback function to createFeedback
          createFeedback(feedbackData, sfId, handleSuccess)
            .catch((error) => {
              console.error('Error submitting feedback:', error);
            });
        } else {
          console.error('sfId is not available.');
        }
      };
      
    function handleRatingChange(event){
      setSelectedRating(event.target.value);
      console.log('selected rating-- ',event.target.value);
    }
    function handleConfirm(){
        setshowModal(false);
    }
    return (
        <div className="flex flex-row">
          <div className="flex-2">
            <Sidebar />
          </div>
          <div className="flex-1">
            <div className="mid-wrap">
              <Link to="/Feedback">
                <img src={BACK_BUTTON} alt="" />
              </Link>
              <h1 style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>New Feedback</h1>
              {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                <div className="flex-1">
                  <label>Subject</label>
                  <input id="subject" value={subject} onChange={handleSubjectChange} type="text" placeholder="eg. School Plastic Recycling" className="form-control" />
                </div>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem' }}>
                <div className="flex-1">
                  <label>Description</label>
                  <textarea id="description" value={description} onChange={handleDesciptionChange} className="form-control" rows={5}></textarea>
                </div> 
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1.25rem' }}>
                <label>Overall rating</label>
                <div class="rate">
                  <input type="radio" onChange={handleRatingChange} id="star5" name="rate" value="5" />
                  <label for="star5" title="text">5 stars</label>
                  <input type="radio" onChange={handleRatingChange} id="star4" name="rate" value="4" />
                  <label for="star4" title="text">4 stars</label>
                  <input type="radio" onChange={handleRatingChange} id="star3" name="rate" value="3" />
                  <label for="star3" title="text">3 stars</label>
                  <input type="radio" onChange={handleRatingChange} id="star2" name="rate" value="2" />
                  <label for="star2" title="text">2 stars</label>
                  <input type="radio" onChange={handleRatingChange} id="star1" name="rate" value="1" />
                  <label for="star1" title="text">1 star</label>
                </div>
              </div>              
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1rem' }}>
                <Link className="ordered-btn" to="/Feedback">Cancel</Link>
                <Link onClick={handleSubmit} className="green-btn">
                  Submit
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-2">
            <div className="right-section">
              <LeaderboardScore />
              <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                <ProfileCard />
              </div>
              {/* <Notification /> */}
            </div>
          </div>
          {showModal ? (
            <div className="modal">
              <div className="modal-dialog">
                <div className="feedback-submitted">
                  <h2>Feedback Submitted Successfully</h2>
                  <p>Your feedback has been sent to Bisleri. Thank you for your valuable time.</p>
                  <img src={GIRL} alt="" />
                  <Link className="green-btn" onClick={handleConfirm}>
                    Continue
                  </Link>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    }


const mapStateToProps = (state) => {
  return {
      authError: state.auth.authError,
      auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(NewFeedback);