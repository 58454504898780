import Sidebar from '../../components/Sidebar/Sidebar'
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
import Notification from '../../components/Notification/Notification'
import Feedback from '../../components/FeedbackGiven/FeedbackGiven'
import './FeedbackGiven.css'
const { BACK_BUTTON } = require('../../utils/imagepath')

export default function FeedbackGiven() {
    const location = useLocation();
    // const feedbackList = props?.location?.state?.feedbackList || [];
    // console.log('feedbackList -------------------->', feedbackList);
    const feedabckData=[{
        title:'Collect College Garbage',
        date:'09 Nov 2023',
        description:'Collected on time',
        givenBy:'Requestor'
    }]
    debugger
    const { feedbackList } = location.state || {};
    console.log('Feedback List -- ',feedbackList);
    const [feedabckDet,setFeedbackDet]=useState([{title:'Collect College Garbage',
    date:'09 Nov 2023',
    description:'Collected on time',
    givenBy:'Requestor'}]);

    return (
        <div className="flex flex-row">
            <div className="flex-2">
                <Sidebar />
            </div>
            <div className="flex-1">
                <div className='mid-wrap'>
                    <h1>
                        <Link to='/Dashboard'>
                            <img src={BACK_BUTTON} alt="" />
                        </Link>
                        Feedback Given
                    </h1>  
                    <div>
                        {feedbackList?feedbackList.map((item,Key)=>(
                            <Feedback feedbackData={item} arrayInd={Key}/>         
                        )):<div>Data Not Available</div>}
                    
                    </div>
                </div>
            </div>
            <div className="flex-2">
                <div className='right-section'>   
                    {/* <LeaderboardScore />                     */}
                    <div style={{marginTop:'1.5rem', marginBottom: '1.5rem'}}>
                        {/* <ProfileCard /> */}
                    </div>
                    {/* <Notification /> */}
                </div>
            </div>
        </div>
    )
}