import { useState } from "react";
import "./FeedbackGiven.css";

export default function Feedback({ feedbackData, arrayInd }) {
  debugger;
  // const [selectedVal,setSelectedVal]=useState(0);
  // const [selectedOption,setSelectedOption]=useState(0);
  const feedbackReceived = feedbackData;
  const arrayIndex = arrayInd;
  console.log("Array Index -- ", arrayInd);
  const ratingArray = ["1", "2", "3", "4", "5"];
  const Ind = 0;
  debugger;
  console.log("Array Key");
  console.log("Array Data", feedbackData);
  // const selectedOption=feedbackData?feedbackData.ratingNumber?parseInt(feedbackData.ratingNumber,10):0:0;
  const selectedOption = feedbackData
    ? feedbackData.ratingNumber
      ? feedbackData.ratingNumber
      : 0
    : 0;
  // setSelectedVal(feedbackData?feedbackData.ratingNumber?feedbackData.ratingNumber:"0":"0");
  // setSelectedOption(selectedVal?parseInt(selectedOption,10):0);
  const handleChange = () => {
    //code here
  };
  return (
    <div>
      {feedbackData ? (
        // feedbackData.map((feedbackItem, index) => (
        <div className="feedback-given" style={{ paddingTop: "0.25rem" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.3596 22 8.77516 21.6039 7.35578 20.8583L3.06538 21.9753C2.6111 22.0937 2.1469 21.8213 2.02858 21.367C1.99199 21.2266 1.99198 21.0791 2.02855 20.9386L3.1449 16.6502C2.3972 15.2294 2 13.6428 2 12C2 6.47715 6.47715 2 12 2ZM13.2517 13H8.75L8.64823 13.0068C8.28215 13.0565 8 13.3703 8 13.75C8 14.1297 8.28215 14.4435 8.64823 14.4932L8.75 14.5H13.2517L13.3535 14.4932C13.7196 14.4435 14.0017 14.1297 14.0017 13.75C14.0017 13.3703 13.7196 13.0565 13.3535 13.0068L13.2517 13ZM15.25 9.5H8.75L8.64823 9.50685C8.28215 9.55651 8 9.8703 8 10.25C8 10.6297 8.28215 10.9435 8.64823 10.9932L8.75 11H15.25L15.3518 10.9932C15.7178 10.9435 16 10.6297 16 10.25C16 9.8703 15.7178 9.55651 15.3518 9.50685L15.25 9.5Z"
              fill="#009E8C"
            />
          </svg>
          <div style={{ width: "100%", paddingLeft: "0.5rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h2>{feedbackData.title}</h2> */}
              <div class="rate">
                {ratingArray.map(
                  (item, key) => (
                    <>
                      <input
                        type="radio"
                        id={`star${arrayIndex}`}
                        checked="checked"
                        name="rate"
                        value={item}
                      />
                      <label for={`star${arrayIndex}`} title="text">
                        {item} stars
                      </label>
                    </>
                  )
                  // item === selectedOption ? (
                  //   <>
                  //     <input
                  //       type="radio"
                  //       id={`star${arrayIndex}`}
                  //       checked="checked"
                  //       name="rate"
                  //       value={item}
                  //     />
                  //     <label for={`star${arrayIndex}`} title="text">
                  //       {item} stars
                  //     </label>
                  //   </>
                  // ) : (
                  //   <>
                  //     <input
                  //       type="radio"
                  //       id={`star${arrayIndex}`}
                  //       name="rate"
                  //       value={item}
                  //     />
                  //     <label for={`star${arrayIndex}`} title="text">
                  //       {item} stars
                  //     </label>
                  //   </>
                  // )
                )}

                {/* <input type="radio" id={`star5${arrayIndex}`} name="rate" checked={selectedOption === "5"} onChange={handleChange} value="5" />
                <label for={`star5${arrayIndex}`} title="text">5 stars</label>
                <input type="radio" id={`star4${arrayIndex}`} name="rate" checked={selectedOption === "4"} onChange={handleChange} value="4" />
                <label for={`star4${arrayIndex}`} title="text">4 stars</label>
                <input type="radio" id={`star3${arrayIndex}`} name="rate" checked={selectedOption === "3"} onChange={handleChange} value="3" />
                <label for={`star3${arrayIndex}`} title="text">3 stars</label>
                <input type="radio" id={`star2${arrayIndex}`} name="rate" checked={selectedOption === "2"} onChange={handleChange} value="2" />
                <label for={`star2${arrayIndex}`} title="text">2 stars</label>
                <input type="radio" id={`star1${arrayIndex}`} name="rate" checked={selectedOption === "1"} onChange={handleChange} value="1" />
                <label for={`star1${arrayIndex}`} title="text">1 star</label> */}
              </div>
              <span className="date">
                {feedbackData.createdDatetime
                  ? feedbackData.createdDatetime
                      .split(" ")[0]
                      .replaceAll("/", " ")
                  : ""}
              </span>
            </div>
            <p>{feedbackData.description}</p>
            {/* <div className='status'>{`Given by ${feedbackData.status}`}</div> */}
          </div>
        </div>
      ) : (
        // ))
        <p style={{ padding: "32%" }}>No feedback related to this record id.</p>
      )}
    </div>
  );
}
