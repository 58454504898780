module.exports = {
  BACK_IMAGE: require("../asset/images/authBackImage.png"),
  HOME_BACK: require("../asset/images/HomeBack.png"),
  LOGO: require("../asset/images/Logo.png"),
  CROSS_ICON: require("../asset/images/Dismiss.png"),
  BACK_BUTTON: require("../asset/images/backButton.png"),
  ELLIPSE_TOP: require("../asset/images/ellipse-top.png"),
  ELLIPSE_BOTTOM: require("../asset/images/ellipse-bottom.png"),
  LOGO_WHITE: require("../asset/images/logo-white.png"),
  PROFILE_IMAGE: require("../asset/images/profile.png"),
  EXPLORE_PROGRAMME_IMAGE: require("../asset/images/exploreProgramme.png"),
  SUCCESS_IMAGE: require("../asset/images/sucessImage.png"),
  DROPDOWN_ICON: require("../asset/images/dropDownIcon.png"),
  PERSON: require("../asset/images/Person.png"),
  PERSON_WHITE: require("../asset/images/PersonWhite.png"),
  BRIEFCASE_PERSON: require("../asset/images/BriefcasePerson.png"),
  BRIEFCASE_PERSON_WHITE: require("../asset/images/BriefcasePersonWhite.png"),
  FOOD: require("../asset/images/Food.png"),
  FOOD_WHITE: require("../asset/images/FoodWhite.png"),
  HAT_GRADUATION: require("../asset/images/HatGraduation.png"),
  HAT_GRADUATION_WHITE: require("../asset/images/HatGraduationWhite.png"),
  BUILDING_PEOPLE: require("../asset/images/BuildingPeople.png"),
  BUILDING_PEOPLE_WHITE: require("../asset/images/BuildingPeopleWhite.png"),
  CHECKED_ICON: require("../asset/images/checkpoint.png"),
  BACKGROUND_CIRCLE: require("../asset/images/BackroundCircle.png"),
  GIRL: require("../asset/images/sucessImage.png"),
  PICK_IMAGE: require("../asset/images/pickImage.png"),
  UPLOAD_SUCCESS: require("../asset/images/uploadSuccess.png"),
  UPLOAD_IMAGE: require("../asset/images/uploadImage.png"),
  DOCUMENT_SUBMIT: require("../asset/images/documentSubmit.png"),
  APPSTORE_BUTTON: require("../asset/images/app_store_button.png"),
  PLAYSTORE_BUTTON: require("../asset/images/play_store_button.png"),
  LOCATIONS: require("../asset/images/locations.png"),
  WHITE_LOGO: require("../asset/images/logo2.png"),
  PROGRAMME: require("../asset/images/programme.jpg"),
  PROGRAMME_MOBILE: require("../asset/images/programme-mobile.jpg"),
  CATEGORY_BANNER: require("../asset/images/category_section.gif"),
  CATEGORY_BANNER_MOBILE: require("../asset/images/category_section_mobile.jpg"),
  FACEBOOK: require("../asset/images/facebook.png"),
  TWITTER: require("../asset/images/twitter.png"),
  INSTAGRAM: require("../asset/images/instagram.png"),
  RECYCLE_PROCESS: require("../asset/images/recycleProcess.png"),
  PLAY_STORE: require("../asset/images/playstore.png"),
  THUMBNAIL1: require("../asset/images/thumbnail1.jpg"),
  THUMBNAIL2: require("../asset/images/thumbnail2.jpg"),
  THUMBNAIL3: require("../asset/images/thumbnail3.jpg"),
  THUMBNAIL4: require("../asset/images/thumbnail4.jpg"),
  THUMBNAIL5: require("../asset/images/thumbnail5.jpg"),
  THUMBNAIL6: require("../asset/images/thumbnail6.jpg"),
  THUMBNAIL7: require("../asset/images/thumbnail7.jpg"),
  THUMBNAIL8: require("../asset/images/thumbnail8.jpg"),
  THUMBNAIL9: require("../asset/images/thumbnail9.jpg"),
  THUMBNAIL10: require("../asset/images/thumbnail10.jpg"),
  THUMBNAIL11: require("../asset/images/thumbnail11.jpg"),
  THUMBNAIL12: require("../asset/images/thumbnail12.jpg"),
  THUMBNAIL13: require("../asset/images/thumbnail13.jpg"),
  PARISAR_VIKAS: require("../asset/images/parisar-vikas.png"),
  SAMPURNA_EARTH: require("../asset/images/sampurna-earth.png"),
  DALMIA: require("../asset/images/dalmia.jpg"),
  THUMBNAIL20: require("../asset/images/thumbnail20.jpg"),
  THUMBNAIL21: require("../asset/images/thumbnail21.png"),
  THUMBNAIL22: require("../asset/images/thumbnail22.png"),
  THUMBNAIL23: require("../asset/images/thumbnail23.jpg"),
  THUMBNAIL24: require("../asset/images/thumbnail24.jpg"),
  THUMBNAIL25: require("../asset/images/thumbnail25.jpg"),
  RECYCLE_PET: require("../asset/images/recycle-pet.png"),
  STEP1: require("../asset/images/step1.jpg"),
  STEP2: require("../asset/images/step2.jpeg"),
  STEP3: require("../asset/images/step3.jpg"),
  PRODUCT1: require("../asset/images/product1.png"),
  PRODUCT2: require("../asset/images/product2.png"),
  PRODUCT3: require("../asset/images/product3.jpg"),
  PRODUCT4: require("../asset/images/product4.png"),
  PRODUCT5: require("../asset/images/product5.jpg"),
  PRODUCT6: require("../asset/images/product6.jpg"),
  PRODUCT7: require("../asset/images/product7.jpg"),
  PRODUCT8: require("../asset/images/product8.jpg"),
  CONSERVES_NATURAL: require("../asset/images/Conserves_Natural_Resources.png"),
  SAVES_LANDFILL: require("../asset/images/Saves_Landfill_Space.png"),
  REDUCE_CO2: require("../asset/images/Reduces_CO2_Emission.png"),
  PRESERVES_FLORA: require("../asset/images/Preserves_Flora.png"),
  JOB_CREATION: require("../asset/images/Job_Creation.png"),
  CIRCLE1: require("../asset/images/circle1.png"),
  CIRCLE2: require("../asset/images/circle2.png"),
  CIRCLE3: require("../asset/images/circle3.png"),
  CIRCLE4: require("../asset/images/circle4.png"),
  LEAF_LET: require("../asset/images/leaflet.png"),
  INVOLVED_ICON: require("../asset/images/involved-icon.png"),
  LOGOUT: require("../asset/images/logout.png"),
  WOMEN: require("../asset/images/women.png"),
  CALENDAR: require("../asset/images/calendar_ico.png")
};

