import { useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import "../Home/home.css";
const {
  BACK_IMAGE,
  PERSON,
  PERSON_WHITE,
  BRIEFCASE_PERSON,
  BRIEFCASE_PERSON_WHITE,
  FOOD,
  FOOD_WHITE,
  HAT_GRADUATION,
  HAT_GRADUATION_WHITE,
  BUILDING_PEOPLE,
  BUILDING_PEOPLE_WHITE,
  CHECKED_ICON,
  BACKGROUND_CIRCLE,
  LOGO,
  BACK_BUTTON,
  CROSS_ICON,
} = require("../../utils/imagepath");

export default function Home() {
  const data = [
    {
      id: 1,
      src1: PERSON,
      src2: PERSON_WHITE,
      title: "Individual",
    },
    {
      id: 2,
      src1: BRIEFCASE_PERSON,
      src2: BRIEFCASE_PERSON_WHITE,
      title: "Corporates",
    },
    {
      id: 3,
      src1: FOOD,
      src2: FOOD_WHITE,
      title: "Hotels and Restaurants",
    },
    {
      id: 4,
      src1: HAT_GRADUATION,
      src2: HAT_GRADUATION_WHITE,
      title: "Schools and Colleges",
    },
    {
      id: 5,
      src1: BUILDING_PEOPLE,
      src2: BUILDING_PEOPLE_WHITE,
      title: "Housing Society",
    },
  ];
  const dataCollector = [
    {
      id: 1,
      src1: PERSON,
      src2: PERSON_WHITE,
      title: "NGO-Social Enterprise",
    },
    {
      id: 2,
      src1: BRIEFCASE_PERSON,
      src2: BRIEFCASE_PERSON_WHITE,
      title: "Plastic Agents",
    },
    {
      id: 3,
      src1: FOOD,
      src2: FOOD_WHITE,
      title: "Aggregators",
    },
    {
      id: 4,
      src1: HAT_GRADUATION,
      src2: HAT_GRADUATION_WHITE,
      title: "Recyclers",
    },
  ];

  const [isRequester, setIsRequester] = useState(true);
  const [isCollector, setIsCollector] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const handleButtonPressNext = () => {
    const selectedItem = isRequester ? data[isActive] : dataCollector[isActive];
    console.log("Selected Item:", selectedItem);

    const route = selectedItem.title === "Individual" ? "/individual" : "/orgs";
    const userType = isCollector ? "collector" : "requestor";
    window.location.href = `${route}?title=${encodeURIComponent(
      selectedItem.title
    )}&userType=${userType}`;
  };

  return (
    <div className="flex flex-row bg-white-500">
      <div className="flex-1">
        <img
          style={{ height: "100%", width: "100%" }}
          src={BACK_IMAGE}
          alt=""
        />
      </div>
      <div className="flex-1">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "3rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/login">
            <img
              style={{ marginLeft: 40 }}
              src={BACK_BUTTON}
              alt="Cross Icon"
            />
          </Link>
          <img alt="" width={300} src={LOGO} />
          <Link to="/login">
            <img
              style={{ marginRight: 40 }}
              src={CROSS_ICON}
              alt="Cross Icon"
            />
          </Link>
        </div>
        <div
          className="flex justify-center items-center"
          style={{ marginTop: "2.5rem" }}
        >
          <p style={{ color: "#00a990", fontWeight: "500", fontSize: 24 }}>
            Register as
          </p>
        </div>
        <div
          className="btnWrapper"
          style={{
            display: "flex",
            gap: "1.25rem",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.5rem",
          }}
        >
          <button
            className={`flexBtn ${isRequester ? "active" : ""}`}
            onClick={() => {
              setIsRequester(!isRequester);
              setIsCollector(false);
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              className="checkImage"
              src={isRequester ? CHECKED_ICON : BACKGROUND_CIRCLE}
              alt="Check"
            />
            <span style={{ fontWeight: "500" }} className="txt">
              I want to donate plastic
            </span>
          </button>
          <button
            className={`flexBtn ${isCollector ? "active" : ""}`}
            onClick={() => {
              setIsCollector(!isCollector);
              setIsRequester(false);
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              className="checkImage"
              src={isCollector ? CHECKED_ICON : BACKGROUND_CIRCLE}
              alt="Check"
            />
            <span style={{ fontWeight: "500" }} className="txt">
              I want to collect plastic
            </span>
          </button>
        </div>

        <div
          className="row"
          style={{
            width: "30.9375rem",
            justifyContent: "center",
            margin: "1.5rem auto 3rem auto",
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
            padding: "0 5rem",
          }}
        >
          {(isRequester ? data : dataCollector).map((item, index) => (
            <button
              style={{
                width: "9.375rem",
                height: "7.5rem",
                backgroundColor: isActive === index ? "#00a990" : "#fff",
                border: "1px solid #9AE0EB",
                borderRadius: 8,
                padding: "0 1.5rem",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                boxShadow: "0.125rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08);",
                display: "flex",
              }}
              onClick={() => setIsActive(index)}
              className={`col_6 ${isActive === index ? "active" : ""}`}
              key={item.id}
            >
              <img
                className="titleImage"
                src={isActive === index ? item.src2 : item.src1}
                alt="Icon"
              />
              <span
                className="title"
                style={{
                  color: isActive === index ? "white" : "#009e8c",
                  fontSize: 13,
                  fontWeight: "600",
                }}
              >
                {item.title}
              </span>
            </button>
          ))}
        </div>

        <button
          onClick={handleButtonPressNext}
          disabled={isActive === null}
          className="nextBtn"
          style={{
            backgroundColor:
              isActive || isActive !== null ? "#009e8c" : "#D3D3D3",
            color: isActive || isActive !== null ? "#fff" : "#000",
            marginTop: 2,
            height: 40,
            width: "20%",
            borderRadius: 10,
            margin: "0 auto",
            display: "block",
          }}
        >
          <span className="nextBtntxt">Next</span>
        </button>
      </div>
    </div>
  );
}
