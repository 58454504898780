import React, { } from 'react';

const HomePage = (props) => {


    return (
        <div className='bg-white'>
           <p style={{color:'#000', fontWeight:'bold'}}>After Login Home Page</p>
        </div>
    )
}

export default HomePage
