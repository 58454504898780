import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import Ticket from "../../components/Ticket/Ticket";
import { createRequest } from "../../api/requestorApi/sfdx_requestor_apis";
import "../MyTickets/MyTickets.css";
import "../Dashboard/Dashboard.css";
import swal from "sweetalert";
import { getMyTickets } from "../../api/requestorApi/sfdx_requestor_apis";
import { getCollectorMyTicketData } from "../../api/collectorApi/sfdx_collector_apis";
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

const { WOMEN, CALENDAR } = require("../../utils/imagepath");

const MyTickets = ({ auth, history }) => {
  const [myTickets, setMyTickets] = useState([]);
  const [fbUser, setFbUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [SFID, setSFID] = useState(false);
  const [activeTab, setActiveTab] = useState("openTickets");
  const [filterTickets, setFilterTickets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPaging, setNextPaging] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const [selectedTab, setSelectedTab] = useState("resolvedTickets");
  const [isRaiseCollectionModalOpen, setIsRaiseCollectionModalOpen] =
    useState(false);
  const [
    isRaiseCollectionSuccessfullyModalOpen,
    setIsRaiseCollectionSuccessfullyModalOpen,
  ] = useState(false);

  const openRaiseCollectionModal = () => {
    setIsRaiseCollectionModalOpen(false);
    setIsModalOpen(true);
  };

  const closeRaiseCollectionModal = () => {
    setIsRaiseCollectionModalOpen(false);
  };

  let currPage = 0;
  //const [ticketCreated,setTicketCreated]=useState(false);
  console.log("My Ticktetssss -- ", auth);
  useEffect(() => {
    fetchData();
  }, [auth.uid, currentPage]);
  const fetchData = async () => {
    try {
      // Fetch sfId
      const sfIdData = await firebase
        .firestore()
        .collection("user")
        .doc(auth.uid)
        .get();

      console.log("FB Data ", sfIdData.data());
      setFbUser(sfIdData.data());
      const fetchedSfId = sfIdData.data().sf_id;
      setSFID(fetchedSfId);
      setUserType(sfIdData.data().user_type);
      getMyTicketsData(fetchedSfId, sfIdData.data().user_type, currentPage);
      // Fetch myTickets using sfId
    } catch (error) {
      console.error("Error fetching Tickets:", error);
    }
  };
  const nextPage = () => {
    debugger;
    // setNextPaging((prevPage) => prevPage + 20);
    setPageCount((prevCount) => prevCount + 1);
    setCurrentPage((prevPage) => prevPage + 20);
    // getMyTicketsData(SFID, userType, currentPage);
    // handleTabClick("openTickets");
    // handleTabClick("resolvedTickets");
  };

  const prevPage = () => {
    debugger;
    // setCurrentPage((prevPage) => prevPage - 20);
    setPageCount((prevCount) => prevCount - 1);
    setCurrentPage((prevPage) => prevPage - 20);
    //getMyTicketsData(SFID, userType, currentPage);
  };
  const getMyTicketsData = async (SFID_id, userType_p, offset) => {
    debugger;
    if (SFID_id) {
      console.log("sfid----->", SFID_id);
      let ticketsData = null;
      debugger;
      if (userType_p.toLowerCase() === "requestor") {
        ticketsData = await getMyTickets(SFID_id, offset);
      } else {
        ticketsData = await getCollectorMyTicketData(SFID_id, offset);
      }
      console.log("My Tickets API Response", ticketsData);
      debugger;

      if (
        ticketsData &&
        ticketsData.myRequests &&
        Array.isArray(ticketsData.myRequests) &&
        ticketsData.myRequests.length > 0
      ) {
        setMyTickets(ticketsData.myRequests);
        if (activeTab === "resolvedTickets") {
          setFilterTickets(
            ticketsData.myRequests.filter((ticket) => ticket.stage === "Closed")
          );
        } else {
          setFilterTickets(
            ticketsData.myRequests.filter((ticket) => ticket.stage !== "Closed")
          );
        }
        //setActiveTab(activeTab);
      } else {
        console.error(
          "Tickets data is not in the expected format:",
          ticketsData
        );
      }
    }
  };
  console.log("myTickets-------->", myTickets);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    tab === "resolvedTickets"
      ? setFilterTickets(
          myTickets.filter((ticket) => ticket.stage === "Closed")
        )
      : setFilterTickets(
          myTickets.filter((ticket) => ticket.stage !== "Closed")
        );
  };
  const activeTable = () => {
    activeTab === "resolvedTickets"
      ? setFilterTickets(
          myTickets.filter((ticket) => ticket.stage === "Closed")
        )
      : setFilterTickets(
          myTickets.filter((ticket) => ticket.stage !== "Closed")
        );
    console.log("filtered data-->", filterTickets);
  };
  const handleNewTicket = () => {
    debugger;
    console.log(startDate);
    if (!startDate) {
      Swal.fire({
        title: "New Collection Request",
        text: "Please select collection date",
        icon: "info",
      });
      return;
    }
    const pickup_date = new Date(startDate);
    const today_date = new Date();
    if (pickup_date < today_date) {
      swal({
        title: "New Collection Request",
        text: "Collection date should be greater than today date.",
        icon: "info",
      });
      return;
    }
    console.log(pickup_date);
    setIsModalOpen(false);
    // return;
    let day = String(pickup_date.getDate()).padStart(2, "0");
    let month = String(pickup_date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    let year = pickup_date.getFullYear();
    const pickupdate_str = year + "-" + month + "-" + day;
    console.log("Pickup string date=>", pickupdate_str);
    // if (window.confirm("Do you really want to Raise a collection request?")) {
    const currentDate = new Date();
    day = String(currentDate.getDate()).padStart(2, "0");
    month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    year = currentDate.getFullYear();
    const formattedDate = currentDate.toISOString().split("T")[0];
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const dateToSubject =
      year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    console.log("collection raised date ", dateToSubject);
    const request = {
      subject:
        fbUser.first_name + " " + fbUser.last_name + " (" + dateToSubject + ")",
      add_state: fbUser.state,
      add_city: fbUser.city,
      add_street: fbUser.street,
      add_pincode: fbUser.pincode,
      add_country: "IN",
      collection_date: pickupdate_str,
    };
    console.log("ticket json", request);
    if (fbUser.sf_id) {
      // Pass the callback function to createRequest
      debugger;
      createRequest(request, fbUser.sf_id, handleSuccess).catch((error) => {
        debugger;
        console.error("Error submitting feedback:", error);
      });
      // const apiResponse =createRequest(request, fbUser.sf_id).catch((error) => {
      //         console.error('Error submitting request:', error);
      //       });
      //   } else {
      //     swal({
      //       title: "Success",
      //       text: 'Ticket raised successfully.',
      //       icon: "success",
      //     });
      //   };

      //     .catch((error) => {
      //       console.error('Error submitting request:', error);
      //     });
      // } else {
      //   console.error('sfId is not available.');
      // }
    }
    // }
  };
  const handleSuccess = (response) => {
    debugger;
    console.log("Request submitted successfully:", response);
    try {
      const responseReturn = JSON.parse(response);
      if (responseReturn.status) {
        setIsRaiseCollectionSuccessfullyModalOpen(true);
        //setTicketCreated(true);
        fetchData();
      } else {
        swal({
          title: "Fail",
          text: "something went wrong, please try again.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log("Exception in parsing resposne-- ", response);
    }
    // setshowModal(true);
    //window.location.href = '/MyTickets';
  };
  const getMyTicketsCallBack = () => {
    getMyTicketsData(SFID, userType, currentPage);
  };
  const handleDateChange = (date, field) => {
    setStartDate(date);
  };
  return (
    <div>
      {/* <Modal
        isOpen={isRaiseCollectionModalOpen}
        className="document-submitted-modal"
      >
        <h1>Documents Submitted</h1>
        <p>We at Bisleri have received all your documents.</p>
        <p>Please wait till we review them.</p>
        <img src={WOMEN} alt="" style={{ margin: "2.75rem auto" }} />
        <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
          <button class="green-btn" onClick={openRaiseCollectionModal}>
            Continue
          </button>
        </div>
      </Modal> */}
      <Modal
        isOpen={isRaiseCollectionModalOpen}
        className="raise-collaction-request"
      >
        <h2>Are you sure you want to raise a collection request?</h2>
        <img src={WOMEN} alt="" style={{ margin: "2.75rem auto" }} />
        <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
          <button class="ordered-btn" onClick={closeRaiseCollectionModal}>
            Cancel
          </button>
          <button class="green-btn" onClick={openRaiseCollectionModal}>
            Yes, Continue
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isRaiseCollectionSuccessfullyModalOpen}
        className="raise-collaction-request-successfully"
      >
        <h2>Yaay! Collection request created successfully.</h2>
        <img src={WOMEN} alt="" style={{ margin: "2.75rem auto" }} />
        <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
          <button
            class="green-btn"
            onClick={() => {
              setIsRaiseCollectionSuccessfullyModalOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </Modal>
      <div className="flex flex-row">
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content custom-date">
              <h2>Choose a date for pickup</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <img
                  src={CALENDAR}
                  alt=""
                  style={{
                    position: "absolute",
                    right: 12,
                    top: 34,
                    zIndex: 1,
                  }}
                />
                <label>Select Start Date</label>
                <DatePicker
                  selected={startDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => handleDateChange(date, "start")}
                  className="form-control"
                  placeholderText="DD/MM/YYYY"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 16,
                  marginTop: 42,
                }}
              >
                <button
                  class="ordered-btn"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button class="green-btn" onClick={handleNewTicket}>
                  Yes, Continue
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex-2">
          <Sidebar />
        </div>
        <div className="flex-1">
          <div className="mid-wrap">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>Collections</h1>
              {/* <Link to="/NewTicket" className="green-btn">
                New Ticket
              </Link> */}

              {/* {userType === "requestor" && (
                <button onClick={handleNewTicket} className="green-btn">
                  New Ticket
                </button>
              )} */}
              {userType === "requestor" && (
                <button
                  // onClick={() => {
                  //   setIsModalOpen(true);
                  // }}
                  onClick={() => {
                    setIsRaiseCollectionModalOpen(true);
                  }}
                  className="green-btn"
                >
                  New Collection Request
                </button>
              )}
            </div>
            <div className="tab-buttons">
              <button
                onClick={() => handleTabClick("openTickets")}
                className={activeTab === "openTickets" ? "active" : ""}
              >
                Open
              </button>
              <button
                onClick={() => handleTabClick("resolvedTickets")}
                className={activeTab === "resolvedTickets" ? "active" : ""}
              >
                Resolved
              </button>
            </div>

            <div className="tab-content">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: 10,
                  marginTop: 5,
                }}
              >
                <button
                  onClick={prevPage}
                  disabled={currentPage === 0}
                  className="text-btn"
                >
                  Previous
                </button>
                {/*
                <ul className="unorder-list">
                  <li>{pageCount}</li>
              </ul>*/}
                <button
                  onClick={() => {
                    nextPage();
                  }}
                  className="text-btn"
                >
                  Next
                </button>
              </div>
              {activeTab === "openTickets" &&
                (filterTickets.length > 0 ? (
                  filterTickets.map((item) => (
                    <div key={item.ticketId}>
                      <Ticket
                        subject={item.subject}
                        sfid={SFID}
                        userType={userType}
                        callBackFunc={getMyTicketsCallBack}
                        street={item.add_street}
                        city={item.add_city}
                        state={item.add_state}
                        country={item.add_country}
                        pincode={item.add_pincode}
                        rating_experience={item.ratingExperiences}
                        rating_submitted_status={item.rating_submitted_status}
                        // resolvedDate={item.resolved_time?item.resolved_time.split(' ')[0].replaceAll('/',' '):''}
                        address={
                          item.add_street +
                          " " +
                          item.add_city +
                          " " +
                          item.add_state +
                          " " +
                          item.add_country +
                          " " +
                          item.add_pincode
                        }
                        ticketId={item.caseNumber}
                        dateRaised={
                          userType === "requestor"
                            ? item.created_date
                            : item.date_raised
                        }
                        collection_date={
                          item.collection_date ? item.collection_date : ""
                        }
                        ticketAge={item.ticket_age}
                        stage={item.stage}
                      />
                    </div>
                  ))
                ) : (
                  <p
                    className="pt-4"
                    style={{ top: "50%", left: "50%", position: "absolute" }}
                  >
                    No open tickets related to this record id.
                  </p>
                ))}
              {activeTab === "resolvedTickets" &&
                (filterTickets.length > 0 ? (
                  filterTickets.map((item) => (
                    <div key={item.ticketId}>
                      <Ticket
                        subject={item.subject}
                        sfid={SFID}
                        userType={userType}
                        ticketId={item.caseNumber}
                        hardPlasticKg={
                          item.requestCollections
                            ? item.requestCollections.find(
                                (record) => record.type === "Hard"
                              )
                              ? item.requestCollections.find(
                                  (record) => record.type === "Hard"
                                ).weight
                              : null
                            : null
                        }
                        hardPlasticGm={
                          item.requestCollections
                            ? item.requestCollections.find(
                                (record) => record.type === "Hard"
                              )
                              ? item.requestCollections.find(
                                  (record) => record.type === "Hard"
                                ).gram
                              : null
                            : null
                        }
                        softPlasticKg={
                          item.requestCollections
                            ? item.requestCollections.find(
                                (record) => record.type === "Soft"
                              )
                              ? item.requestCollections.find(
                                  (record) => record.type === "Soft"
                                ).weight
                              : null
                            : null
                        }
                        softPlasticGm={
                          item.requestCollections
                            ? item.requestCollections.find(
                                (record) => record.type === "Soft"
                              )
                              ? item.requestCollections.find(
                                  (record) => record.type === "Soft"
                                ).gram
                              : null
                            : null
                        }
                        address={
                          item.add_street +
                          " " +
                          item.add_city +
                          " " +
                          item.add_state +
                          " " +
                          item.add_country +
                          " " +
                          item.add_pincode
                        }
                        rating_experience={item.ratingExperiences}
                        street={item.add_street}
                        city={item.add_city}
                        state={item.add_state}
                        country={item.add_country}
                        pincode={item.add_pincode}
                        rating_submitted_status={item.rating_submitted_status}
                        dateRaised={
                          userType === "requestor"
                            ? item.created_date
                            : item.date_raised
                        }
                        collection_date={
                          item.collection_date ? item.collection_date : ""
                        }
                        resolvedDate={
                          item.resolved_time
                            ? item.resolved_time.split(" ")[0]
                            : ""
                        }
                        stage={item.stage}
                      />
                    </div>
                  ))
                ) : (
                  <p
                    className="pt-4"
                    style={{ top: "50%", left: "50%", position: "absolute" }}
                  >
                    No resolved tickets related to this record id.
                  </p>
                ))}
            </div>
          </div>
        </div>
        <div className="flex-2">
          <div className="right-section">
            {/* <LeaderboardScore /> */}
            <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
              {/* <ProfileCard /> */}
            </div>
            {/* <Notification /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(MyTickets);
