import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import OTPVerification from "../OTPVerification/OTPVerification";
import { signIn } from "../../store/actions/authActions";
import validator from "validator";
import { LoaderSmall } from "../ui_utilities/Loaders";
import Alert from "../ui_utilities/Alert";
import Modal from "react-modal";
import "../Home/home.css";
import Swal from "sweetalert2";
import { updatePassword } from "../../api/fb_apis/fb_apis";
import { sendOTP } from "../../api/requestorApi/sfdx_requestor_apis";
import { FaEye } from "react-icons/fa";

const {
  BACK_IMAGE,
  CROSS_ICON,
  BACK_BUTTON,
  LOGO,
} = require("../../utils/imagepath");

const Login = ({ authError, auth, history, signIn, location }) => {
  console.log("firebase===>", auth);
  const [otpRepsonse, setOTPResponse] = useState(null);

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [mobileVal, setMobileVal] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [isForgotOTPScreen, SetIsForgotOTPScreen] = useState(false);
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [uid, setUID] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [state, setState] = useState({
    email: "",
    mobile: "",
    password: "",
    showLoader: false,
    showPassword: false,
    showAlert: false,
    invalidMsg: null,
    authErrorAlert: false,
  });

  useEffect(() => {
    setState({ ...state, authErrorAlert: authError ? true : false });
  }, [authError]);

  const navigate = useNavigate();
  console.log("--- auth", auth);

  const openModal = () => {
    setIsForgotPasswordModalOpen(true);
  };

  const closeModal = () => {
    setIsForgotPasswordModalOpen(false);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleNewPassword = () => {
    setNewShowPassword(!showNewPassword);
  };
  const sendForgotOTP = async () => {
    if (mobileVal.length != 10) {
      Swal.fire("Mobile No.", "Please enter a valid mobile no.", "info");
      return;
    }
    try {
      await firebase
        .firestore()
        .collection("user")
        .where("phone", "==", mobileVal)
        .get()
        .then((fbResponse) => {
          console.log("Object -->", fbResponse.docs);
          console.log("Object -->", fbResponse.docs.length);
          if (fbResponse.docs.length > 0) {
            debugger;
            console.log(fbResponse.docs[0].data().email);
            setUID(fbResponse.docs[0].id);
            setUserExists(true);
            console.log(state.email);
            handleSendOTP("f");
          } else {
            swal({
              title: "Login",
              text: "Invalid mobile number / number not registered with us.",
              icon: "error",
            });
            return;
          }
        })
        .catch((err) => {
          console.log("Querying data from the firebase", err);
          swal({
            title: "Login",
            text: "Something went wrong, please try again",
            icon: "error",
          });
          return;
        });
    } catch (error) {
      console.log("Exceptiob ocured-->", error);
      swal({
        title: "Login",
        text: "Something went wrong, please try again",
        icon: "error",
      });
      return;
      //alert("Something went wrong, please try again.");
    }

    // if (userExists) {
    //   handleSendOTP();
    //   SetIsForgotOTPScreen(true);
    // } else {
    //   Swal.fire(
    //     "Not Registered",
    //     "The mobile no. is not registered with us.",
    //     "info"
    //   );
    // }
  };
  // const openConfirmModal = () => {
  //   setIsForgotPasswordModalOpen(false);
  //   setIsConfirmPasswordModal(true);
  // };

  const closeConfirmModal = () => {
    setIsConfirmPasswordModal(false);
  };

  if (auth.uid) {
    console.log("auth ---", auth); //TODO: check collector dashboard
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        const userData = doc.data();
        console.log("FB Data ", userData);
        if (userData && userData.user_type === "requestor") {
          navigate("/dashboard");
        } else {
          userData.status === "New"
            ? navigate("/documents")
            : navigate("/collector_dashboard");
        }
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });
  }

  const togglePasswordVisiblity = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleChange = (e) => {
    //alert(e.target.id);
    if (e.target.id === "mobile") {
      const inputValue = e.target.value;
      if (!isNaN(inputValue) && inputValue.length <= 10) {
        setState({
          ...state,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setState({
        ...state,
        [e.target.id]: e.target.value,
      });
      console.log(e.target.id, " ", e.target.value);
    }
  };

  const handleLogin = async () => {
    if (activeTab === "email") {
      // Check if any input fields are empty
      if (!state.email || !state.password) {
        swal({
          title: "Please fill out all fields",
          icon: "warning",
        });
        return;
      }
      callBackLogin(state.email);
    } else {
      if (!state.mobile || !state.password) {
        swal({
          title: "Please fill out all fields",
          icon: "warning",
        });
        return;
      } else if (!state.mobile || state.mobile.length != 10) {
        setState({
          ...state,
          showAlert: true,
          invalidMsg: {
            header: "Invalid mobile:",
            msg: "Mobile number must be of 10 digits",
          },
        });
        return;
      } else if (!state.password || state.password.length < 6) {
        setState({
          ...state,
          showAlert: true,
          invalidMsg: {
            header: "Invalid Password:",
            msg: "Password must be of atleast 6 char length",
          },
        });
        return;
      }

      try {
        await firebase
          .firestore()
          .collection("user")
          .where("phone", "==", state.mobile)
          .get()
          .then((fbResponse) => {
            console.log("Object -->", fbResponse.docs);
            console.log("Object -->", fbResponse.docs.length);
            if (fbResponse.docs.length > 0) {
              console.log(fbResponse.docs[0].data().email);
              setState({ ...state, email: fbResponse.docs[0].data().email });
              console.log(state.email);
              if (fbResponse.docs[0].data().status === "Deactivate") {
                console.log("user is deactivated");
                swal({
                  title: "Login",
                  text: "Invalid login credentials",
                  icon: "error",
                });
                return;
              } else {
                handleSendOTP("login");
                //user is activate
                //signIn({ email: emailid, password: state.password });
                // signInUser(userSnapshot.email, password)
                //   .then(async (response) => {
                //     console.log("User signed in!");
                //     console.log("API Response=>" + JSON.stringify(response));
                //     signOutUser()
                //       .then(async () => {})
                //       .catch((err) => {
                //         console.log("Querying data from the firebase", err);
                //         swal({
                //           title: "Login",
                //           text: "Something went wrong, please try again",
                //           icon: "error",
                //         });
                //         return;
                //       });
                //   })
                //   .catch((err) => {
                //     console.log("Querying data from the firebase", err);
                //     swal({
                //       title: "Login",
                //       text: "Something went wrong, please try again",
                //       icon: "error",
                //     });
                //     return;
                //   });
              }
              // handleSendOTP("login");
            } else {
              swal({
                title: "Login",
                text: "Invalid mobile number / number not registered with us.",
                icon: "error",
              });
              return;
            }
          })
          .catch((err) => {
            console.log("Querying data from the firebase", err);
            swal({
              title: "Login",
              text: "Something went wrong, please try again",
              icon: "error",
            });
            return;
          });
      } catch (error) {
        console.log("Exceptiob ocured-->", error);
        swal({
          title: "Login",
          text: "Something went wrong, please try again",
          icon: "error",
        });
        return;
        //alert("Something went wrong, please try again.");
      }
    }
  };
  const setCurrentScreen = () => {
    setOTPScreen(false);
  };
  const validateMobileNo = (event) => {
    debugger;
    const inputValue = event.target.value;
    // Regular expression to check if input value contains only numeric characters
    const isNumeric = /^[0-9]+$/.test(inputValue);
    // Limit input to 10 characters
    if (inputValue.length <= 10 && isNumeric) {
      setMobileVal(inputValue);
    }
    if (inputValue.length == 0) {
      setMobileVal("");
    }
  };
  const handleSendOTP = async (sFlag) => {
    debugger;
    let mobileNumber;
    if (sFlag === "f") {
      mobileNumber = mobileVal;
    } else {
      mobileNumber = "+91" + state.mobile;
    }
    let mobile_json = {
      phone: mobileNumber,
    };
    try {
      const result = await sendOTP(mobile_json);
      console.log("OTP-->", result);
      if (result.Status !== "Success") {
        swal({
          title: "Login",
          text: "Something went wrong while sending OTP, please try again",
          icon: "error",
        });
        return false;
      }
      if (sFlag === "f") {
        closeModal();
        SetIsForgotOTPScreen(true);
      } else {
        setOTPScreen(true);
      }
      swal({
        title: "Login",
        text: "OTP has been sent on your mobile no.",
        icon: "success",
      });
      return true;
    } catch (error) {
      console.error("Error sending OTP:", error);
      //setOTPResponse({ error: "Error sending OTP" });
      swal({
        title: "Login",
        text: "Something went wrong while sending OTP, please try again",
        icon: "error",
      });
      return false;
    }
  };
  const callBackLogin = (emailid) => {
    debugger;
    try {
      console.log("Email ", emailid, "Password ", state.password);
      if (emailid.length < 4 || !validator.isEmail(emailid)) {
        setState({
          ...state,
          showAlert: true,
          invalidMsg: {
            header: "Invalid Email:",
            msg: "Enter a valid email-id",
          },
        });
      } else if (state.password.length < 1) {
        setState({
          ...state,
          showAlert: true,
          invalidMsg: {
            header: "Invalid Password:",
            msg: "Enter a valid password",
          },
        });
      } else {
        setState({ ...state, showLoader: true });
        debugger;
        signIn({ email: emailid, password: state.password });
        setState({ ...state, showLoader: false });
      }
    } catch (error) {
      console.error(error.response.data.error);
      swal({
        title: "Error",
        text: error.response.data.error,
        icon: "error",
      });
    }
  };
  const [activeTab, setActiveTab] = useState("email");
  const [otpScreen, setOTPScreen] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAlerts = (alertType) => (event) => {
    if (alertType === "CLOSE") {
      setState({
        ...state,
        authErrorAlert: false,
        showAlert: false,
        invalidMsg: null,
      });
    }
  };

  const closeModalPopup = () => {
    closeModal();
  };
  const callBackForgotPass = () => {
    setIsForgotPasswordModalOpen(false);
    SetIsForgotOTPScreen(false);
    setIsConfirmPasswordModal(true);
  };
  const handleNewPassword = async () => {
    if (!newPassword || !confirmPassword) {
      Swal.fire("New Password", "Please fill in both password fields", "info");
      return;
    }
    if (newPassword != confirmPassword) {
      Swal.fire(
        "New Password",
        "New password and confirm password does not match",
        "info"
      );
      return;
    }
    if (newPassword.length < 6) {
      Swal.fire(
        "New Password",
        "Password must be  at least six characters long",
        "info"
      );
      return;
    }
    debugger;
    try {
      const result = await updatePassword(uid, newPassword);
      console.log("Password updated successfully:", result);
      // Add any additional logic for success response
      if (result && result.status) {
        Swal.fire(
          "New Password",
          "Password has been updated successfully",
          "success"
        );
        setIsConfirmPasswordModal(false);
      }
    } catch (error) {
      console.error("Failed to update password:", error.message);
      Swal.fire(
        "New Login",
        "Failed to update password: " + error.message,
        "error"
      );
    }
  };
  const callBackCloseOTP = () => {
    SetIsForgotOTPScreen(false);
  };
  const forgotPasswordHandler = () => {
    openModal();
    // debugger;
    // if (state.email.length < 4 || !validator.isEmail(state.email)) {
    //   setState({
    //     ...state,
    //     showAlert: true,
    //     invalidMsg: {
    //       header: "Invalid Email:",
    //       msg: "Enter a valid email-id",
    //     },
    //   });
    //   return;
    // }
    // firebase
    //   .auth()
    //   .sendPasswordResetEmail(state.email)
    //   .then(() => {
    //     swal({
    //       title: "Success",
    //       text: "Password reset link have been sent to your email id",
    //       icon: "success",
    //     });
    //   })
    //   .catch((error) => {
    //     // Error occurred. Inspect error.code.
    //     if (error.code === "auth/invalid-email") {
    //       swal({
    //         title: "Error",
    //         text: "Invalid email / email not registered with us",
    //         icon: "error",
    //       });
    //     } else {
    //       swal({
    //         title: "Error",
    //         text: "Invalid email / email not registered with us",
    //         icon: "error",
    //       });
    //     }
    //   });
  };

  return (
    <div className="flex flex-row bg-white-500">
      <Modal
        isOpen={isForgotPasswordModalOpen}
        className="forgot-password-modal"
      >
        <h1>Forgot Password</h1>
        <input
          type="text"
          pattern="[0-9]"
          value={mobileVal}
          style={{
            borderWidth: 0.6,
            width: "100%",
          }}
          className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
          placeholder="Enter your mobile no."
          maxLength={10}
          onChange={validateMobileNo}
          id="mobile"
          required
        />

        <div
          style={{
            display: "flex",
            gap: 20,
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            style={{
              width: "50%",
              marginTop: "1%",
              backgroundColor: "#fff",
              border: "1px solid #00a990",
              color: "#00a990",
              fontWeight: "bold",
              height: 40,
              borderRadius: 10,
            }}
            onClick={closeModalPopup}
          >
            Cancel
          </button>
          <button
            type="submit"
            style={{
              width: "50%",
              marginTop: "1%",
              backgroundColor: "#00a990",
              color: "#fff",
              fontWeight: "bold",
              height: 40,
              borderRadius: 10,
            }}
            onClick={sendForgotOTP}
          >
            Continue
          </button>
        </div>
      </Modal>
      <Modal isOpen={isForgotOTPScreen} className="forgot-password-otp-modal">
        <OTPVerification
          expectedOtp={otpRepsonse}
          email={state.email}
          phone={mobileVal}
          OTPType={"f"}
          callBackFn={callBackForgotPass}
          calltoBackScreen={callBackCloseOTP}
          callBaclSendOTP={handleSendOTP}
        />
      </Modal>
      <Modal isOpen={isConfirmPasswordModal} className="forgot-password-modal">
        <h1>Create New Password</h1>
        <div className="mb-3">
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{
                borderWidth: 0.6,
                width: "100%",
              }}
              className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
              placeholder="New Password"
              required
            />
            <FaEye
              onClick={handleTogglePassword}
              style={{
                position: "absolute",
                zIndex: 1,
                top: 20,
                right: 12,
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="mb-3">
          <div style={{ position: "relative" }}>
            <input
              type={showNewPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{
                borderWidth: 0.6,
                width: "100%",
              }}
              className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
              placeholder="Confirm Password"
              required
            />
            <FaEye
              onClick={handleToggleNewPassword}
              style={{
                position: "absolute",
                zIndex: 1,
                top: 20,
                right: 12,
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 20,
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            style={{
              width: "50%",
              marginTop: "1%",
              backgroundColor: "#fff",
              border: "1px solid #00a990",
              color: "#00a990",
              fontWeight: "bold",
              height: 40,
              borderRadius: 10,
            }}
            onClick={closeConfirmModal}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleNewPassword}
            style={{
              width: "50%",
              marginTop: "1%",
              backgroundColor: "#00a990",
              color: "#fff",
              fontWeight: "bold",
              height: 40,
              borderRadius: 10,
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
      <div className="flex-1">
        <img
          style={{ height: "100%", width: "100%" }}
          src={BACK_IMAGE}
          alt=""
        />
      </div>
      <div className="flex-1">
        {!otpScreen ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "5%",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <img
                  style={{ marginLeft: 40 }}
                  src={BACK_BUTTON}
                  alt="Cross Icon"
                />
              </Link>
              <Link to="/">
                <img
                  style={{ marginRight: 40 }}
                  src={CROSS_ICON}
                  alt="Cross Icon"
                />
              </Link>
            </div>
            <div className="home-right-section">
              <img src={LOGO} width={300} />
            </div>
            <div>
              <div
                className="flex justify-center items-center"
                style={{ marginTop: "5%" }}
              >
                <p
                  style={{
                    color: "#00a990",
                    fontWeight: "bold",
                    fontSize: 35,
                  }}
                >
                  Login
                </p>
              </div>
              <div
                className="flex justify-center items-center"
                style={{ marginTop: "1%" }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "400",
                    fontSize: 15,
                  }}
                >
                  Hello, Welcome Back
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "3%",
            justifyContent: "center",
          }}
        >
          {otpScreen ? (
            <div style={{ width: "100%" }}>
              <OTPVerification
                expectedOtp={otpRepsonse}
                email={state.email}
                phone={state.mobile}
                OTPType={"login"}
                callBackFn={callBackLogin}
                calltoBackScreen={setCurrentScreen}
                callBaclSendOTP={handleSendOTP}
              />
            </div>
          ) : (
            <div style={{ width: "40%" }}>
              <form>
                <div className="tab-buttons" style={{ width: "100%" }}>
                  <button
                    onClick={() => handleTabClick("email")}
                    className={activeTab === "email" ? "active" : ""}
                  >
                    Email Id
                  </button>
                  <button
                    onClick={() => handleTabClick("mobile")}
                    className={activeTab === "mobile" ? "active" : ""}
                  >
                    Mobile
                  </button>
                </div>
                <div className="tab-content" style={{ marginTop: "1.5rem" }}>
                  {state.authErrorAlert && authError && authError.header ? (
                    <Alert
                      alert={{
                        header: authError.header,
                        message: authError.msg,
                        className: authError.color,
                      }}
                      clickHandler={handleAlerts}
                      width="100%"
                    />
                  ) : null}

                  {state.showAlert ? (
                    <Alert
                      alert={{
                        header: state.invalidMsg.header,
                        message: state.invalidMsg.msg,
                        className: "bg-yellow-400",
                      }}
                      clickHandler={handleAlerts}
                      width="100%"
                    />
                  ) : null}
                  {activeTab === "email" && (
                    <div>
                      <p
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Email Address
                      </p>
                      <input
                        type="email"
                        value={state.email}
                        style={{
                          borderWidth: 0.6,
                          width: "100%",
                        }}
                        className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        id="email"
                        required
                      />
                    </div>
                  )}

                  {activeTab === "mobile" && (
                    <div>
                      <p
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Mobile Number
                      </p>
                      <input
                        type="number"
                        value={state.mobile}
                        style={{
                          borderWidth: 0.6,
                          width: "100%",
                        }}
                        className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
                        placeholder="Enter your mobile no."
                        maxLength={10}
                        onChange={handleChange}
                        id="mobile"
                        required
                      />
                    </div>
                  )}
                  <div style={{ position: "relative" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      Password
                    </p>
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={state.password}
                      style={{
                        borderWidth: 0.6,
                        width: "100%",
                      }}
                      className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
                      placeholder="Enter your password"
                      onChange={handleChange}
                      required
                    />
                    <FaEye
                      onClick={handleTogglePassword}
                      style={{
                        position: "absolute",
                        top: 44,
                        right: 12,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <Link
                    style={{
                      color: "rgb(0, 169, 144)",
                      fontWeight: 600,
                      float: "right",
                    }}
                    onClick={forgotPasswordHandler}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </div>
          )}
          {/* <form>
            <div className="tab-buttons">
              <button
                onClick={() => handleTabClick("email")}
                className={activeTab === "email" ? "active" : ""}
              >
                Email
              </button>
              <button
                onClick={() => handleTabClick("mobile")}
                className={activeTab === "mobile" ? "active" : ""}
              >
                Mobile
              </button>
            </div>
            <div className="tab-content" style={{ marginTop: "1.5rem" }}>
              {activeTab === "email" && (
                <div>
                  <p style={{ color: "#000", fontWeight: "bold" }}>
                    Email Address
                  </p>
                  <input
                    type="email"
                    value={state.email}
                    style={{ borderWidth: 0.6, width: "130%" }}
                    className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    id="email"
                    required
                  />
                </div>
              )}

              {activeTab === "mobile" && (
                <div>
                  <p style={{ color: "#000", fontWeight: "bold" }}>
                    Mobile Number
                  </p>
                  <input
                    type="text"
                    value={state.mobile}
                    style={{ borderWidth: 0.6, width: "130%" }}
                    className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
                    placeholder="Enter your mobile no."
                    maxLength={10}
                    onChange={handleChange}
                    id="mobile"
                    required
                  />
                </div>
              )}

              <p style={{ color: "#000", fontWeight: "bold" }}>Password</p>
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={state.password}
                style={{ borderWidth: 0.6, width: "130%" }}
                className="my-2 w-full bg-white-200 rounded-md py-2 px-3"
                placeholder="Enter your password"
                onChange={handleChange}
                required
              />
              <Link
                className="underline decoration-solid p-3 text-blue-500 hover:text-black"
                style={{ marginTop: "2%", marginLeft: "auto" }}
              >
                Forgot Password ?
              </Link>
            </div>
          </form> */}
        </div>
        {!otpScreen ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "6%",
              }}
              className="flex justify-center items-center"
            >
              {/* {!otpScreen ?<div> */}
              <p
                style={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: "400",
                  marginRight: 5,
                }}
              >
                Don't have an Account ?{" "}
              </p>
              <Link
                to="/SelectTypeSignup"
                style={{
                  color: "green",
                  fontSize: 18,
                  fontWeight: "500",
                }}
              >
                Register
              </Link>
            </div>
            <div
              style={{ marginTop: "1%" }}
              className="flex justify-center items-center"
            >
              {state.showLoader ? (
                <div className="flex items-center justify-center">
                  <LoaderSmall type="ThreeDots" />
                </div>
              ) : (
                <button
                  onClick={handleLogin}
                  type="submit"
                  style={{
                    width: "40%",
                    marginTop: "1%",
                    backgroundColor: "#00a990",
                    color: "#fff",
                    fontWeight: "bold",
                    height: 40,
                    borderRadius: 10,
                  }}
                >
                  Login
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
