import { Link } from 'react-router-dom'
import './ForgotPassword.css'
const { BACK_IMAGE, CROSS_ICON, LOGO, EYE, SUCCESSFUL } =  require('../../utils/imagepath')

export default function ForgotPassword() { 
    return (
        <div className="flex flex-row bg-white-500">
            <div className="flex-1">
                <img
                    style={{ height: "100%", width: "100%" }}
                    src={BACK_IMAGE}
                    alt=""
                />
            </div>
            <div className="flex-1">
                <div style={{ display: 'flex', flexDirection: 'row', padding: '3rem 2.5rem 0 2.5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div></div>
                    <img src={LOGO} />
                    <Link to='/'>
                        <img 
                            src={CROSS_ICON}
                            alt="Cross Icon"
                        />
                    </Link>
                </div>
                <div className='forgot-password'>
                    <h2>Forgot Password?</h2>
                    <p>Oops, forgot your password? No worries we've got you covered. Just enter your registered phone number to continue.</p>
                    <div style={{ width: '80%', margin: '3.5rem auto 0 auto' }}>
                        <label>Registered Phone Number</label>
                        <input type='text' value='' className='form-control' placeholder='eg. 9999999999' />
                    </div>
                </div>
                {/* <div className='verification-code'>
                    <h2>Login</h2>
                    <p>Please enter the 6-digit verification code that was sent to +91 98XXXXXX90. The code is valid for 30 minutes.</p>
                    <div style={{ marginTop: '3.5rem' }}>
                        <p>Enter verification code</p>
                        <div className='otp-field'>
                            <input type='number' />
                            <input type='number' />
                            <input type='number' />
                            <input type='number' />
                            <input type='number' />
                        </div>
                        <p>Didn't get the code? Resend in <span>00.20 secs</span></p>
                    </div>
                </div> */}
                {/* <div className='reset-password'>
                    <h2>Reset Password</h2>
                    <p>Kindly create a new password</p>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem', gap: '1.25rem' }}>
                        <div className="flex-1" style={{ position: 'relative' }}>
                            <label>New Password</label>
                            <input type='password' className='form-control' />
                            <img src={EYE} alt="" className='eye-icon' />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem', gap: '1.25rem' }}>
                        <div className="flex-1" style={{ position: 'relative' }}>
                            <label>Confirm Password</label>
                            <input type='password' className='form-control' />
                            <img src={EYE} alt="" className='eye-icon' />
                        </div>
                    </div>
                </div> */}
                <div className='reset-successful'>
                    <h2>Reset Successful</h2>
                    <p style={{ marginBottom: '3.5rem' }}>Yaay! your password has been set successfully. Please go to login screen to continue</p>
                    <img src={SUCCESSFUL} alt="" />
                </div>
                <div style={{ marginTop: '8rem', gap: '1.5rem', justifyContent: 'center', display: 'flex' }}>
                    <Link className='green-btn'>Go to Login</Link>
                </div>
            </div>
        </div>
    )
}