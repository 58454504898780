// FooterBottom.js
import React from "react";
import { Link } from "react-router-dom";

const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <ul>
        <li>&copy; 2019 Bisleri All Rights Reserved.</li>
        <li>
          <Link to="/terms-condition">Terms & Conditions</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy</Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterBottom;
