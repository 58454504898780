export const storage = {
    // Set an item in local storage
    setItem: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },

    // Get an item from local storage
    getItem: (key) => {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },

    // Remove an item from local storage
    removeItem: (key) => {
        localStorage.removeItem(key);
    },

    // Clear all items from local storage
    clear: () => {
        localStorage.clear();
    },
};