import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
//import Ticket from '../../components/Ticket/Ticket'
import { Link, useLocation } from "react-router-dom";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import "./HardPlasticCollected.css";
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getCollectorDashboardViewDet } from "../../api/collectorApi/sfdx_collector_apis";
import { getRequestorDashboardViewDet } from "../../api/requestorApi/sfdx_requestor_apis";
const { BACK_BUTTON } = require("../../utils/imagepath");

const PlasticCollected = ({ auth }) => {
  const [filterCases, setFilterCases] = useState(null);
  const location = useLocation();
  const [userType, setUserType] = useState(null);
  const [pdfObject, setPdfObject] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [requestCollection, setRequestCollection] = useState([]);
  const {
    caseList,
    plastic_type,
    total,
    filter,
    startDate,
    endDate,
    duration,
    user_id,
  } = location.state || {};
  let start_date = "";
  let end_date = "";
  console.log("case list===>", caseList);
  useEffect(() => {
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        console.log("FB Data ", doc.data());
        //setFbUser(doc.data());
        setUserType(doc.data().user_type);
        var userType = doc.data().user_type;
        debugger;
        if (user_id) {
          callAPI(doc.data().user_type, currentPage);
        }
        //setSfId(doc.data().sf_id);
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });

    //console.log("Plastic collected-- ", caseList);
    // let casesWithHardType = caseList
    //   ? caseList.filter((caseItem) => {
    //       return caseItem.requestCollections.some(
    //         (collection) => collection.type === plastic_type
    //       );
    //     })
    //   : null;
    // setFilterCases(casesWithHardType);
    // console.log("Filtered data -  ", casesWithHardType);
  }, [currentPage]);
  const nextPage = () => {
    debugger;
    // setNextPaging((prevPage) => prevPage + 20);
    setCurrentPage((prevPage) => prevPage + 20);
    // getMyTicketsData(SFID, userType, currentPage);
    // handleTabClick("openTickets");
    // handleTabClick("resolvedTickets");
  };

  const prevPage = () => {
    debugger;
    // setCurrentPage((prevPage) => prevPage - 20);
    setCurrentPage((prevPage) => prevPage - 20);
    //getMyTicketsData(SFID, userType, currentPage);
  };
  const callAPI = async (user_type, offset) => {
    try {
      if (startDate && endDate) {
        start_date = startDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        end_date = endDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }
      let data = "";
      debugger;
      if (user_type === "requestor") {
        data = await getRequestorDashboardViewDet(
          user_id,
          duration,
          filter,
          start_date,
          end_date,
          offset
        );
      } else {
        data = await getCollectorDashboardViewDet(
          user_id,
          duration,
          filter,
          start_date,
          end_date,
          offset
        );
      }
      console.log("Ticket Raise API Calling Data-->", data);
      // setFilteredTickets(data.caseList);
      setFilterCases(data.caseList);
      setRequestCollection(data.requestCollection);
    } catch (error) {
      console.log("Exceprion in API Calling - ", error);
    }
  };
  const generatePdf = () => {
    const extractedData = filterCases.map((ticket) => ({
      "Ticket Number": ticket.CaseNumber,
      Subject: ticket.Subject,
      "Ticket Raised Date": ticket.Resolved_Time__c
        ? ticket.Resolved_Time__c.split("T")[0]
        : null,
      "Weight(kg)": ticket.Request_Collections__r.records.find(
        (itm) => itm.Type__c === plastic_type
      )?.Weight__c,
      // ticket.Request_Collections__r &&
      // ticket.Request_Collections__r.records
      //   .filter((collection) => collection.type === plastic_type)
      //   .map((collection) => collection.Weight__c),
      Type: plastic_type,
    }));
    // Create a new instance of jsPDF
    // {filterCases?filterCases.map((item,index)=>(

    // )):null}
    const pdf = new jsPDF();

    // Define the columns for the table
    const columns = Object.keys(extractedData[0]);

    // Extract the values from the tickets for each column
    const data = extractedData.map((ticket) => Object.values(ticket));
    // const data = filterCases.map((ticket) => selectedKeys.map((key) => ticket[key]));

    // Add a header to the table
    pdf.text(`${plastic_type} Plastic Collected`, 10, 10);

    // Generate the table using jspdf-autotable
    pdf.autoTable({
      head: [columns],
      body: data,
      startY: 20,
    });

    // Save the PDF
    pdf.save("Plastic_collected.pdf");
  };
  console.log("caseList -------------------->", caseList);
  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-wrap">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {userType === "requestor" ? (
              <h1>
                <Link to="/Dashboard">
                  <img src={BACK_BUTTON} alt="" />
                </Link>
                {plastic_type} Plastic Collected (Kg)
              </h1>
            ) : (
              <h1>
                <Link to="/collector_dashboard">
                  <img src={BACK_BUTTON} alt="" />
                </Link>
                {plastic_type} Plastic Collected (Kg)
              </h1>
            )}

            <button className="green-btn" onClick={generatePdf}>
              Download as PDF
            </button>
          </div>
          <div className="total-plastic-collected">
            <div>Total {plastic_type} Plastic Collected</div>
            <div>{total} Kg</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: 15,
              marginTop: 5,
            }}
          >
            <button
              onClick={prevPage}
              disabled={currentPage === 0}
              className="text-btn"
            >
              Previous
            </button>
            <button
              onClick={() => {
                nextPage();
              }}
              className="text-btn"
            >
              Next
            </button>
          </div>
          {filterCases ? (
            filterCases.map((item, index) => (
              <div className="plastic-collection">
                <div className="location">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M12.9497 11.955C15.6834 9.2201 15.6834 4.78601 12.9497 2.05115C10.2161 -0.683716 5.78392 -0.683716 3.05025 2.05115C0.316582 4.78601 0.316582 9.2201 3.05025 11.955L4.57128 13.4538L6.61408 15.4389L6.74691 15.5567C7.52168 16.1847 8.65623 16.1455 9.38611 15.4391L11.8223 13.0691L12.9497 11.955ZM8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7C11 8.65685 9.65685 10 8 10Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <div>
                    <h3>{item.Subject}</h3>
                    {/* <h4>Bandra, Mumbai</h4> */}
                    <div style={{ display: "inline-block" }}>
                      <label className="label">
                        {plastic_type} Plastic Collected:
                      </label>
                      <span>
                        {
                          item.Request_Collections__r.records.find(
                            (itm) => itm.Type__c === plastic_type
                          )?.Weight__c
                        }{" "}
                        Kg{" "}
                        {
                          item.Request_Collections__r.records.find(
                            (itm) => itm.Type__c === plastic_type
                          )?.Weight_in_Gram__c
                        }{" "}
                        Gms
                      </span>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <label className="label">Date:</label>
                      <span>
                        {item.Resolved_Time__c
                          ? item.Resolved_Time__c.split("T")[0]
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>Data Not Available</div>
          )}
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          {/* <LeaderboardScore />                     */}
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, null)(PlasticCollected);
