import { Link } from 'react-router-dom'
import '../UploadDocuments/UploadDocuments.css'
import { signOut } from '../../store/actions/authActions';
import { connect } from "react-redux";
import { DOCUMENT_SUBMIT } from '../../utils/imagepath';

const DocumentSubmitted = ({signOut, auth, history }) => { 
    const handleLogoutClick = (event) => {
        event.preventDefault();
        signOut();
    }
    
    return (
        <div className='upload-document'>
            <div className='header'>
                Document Submitted
                <Link className="logout" to='/' onClick={handleLogoutClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M13.9987 5.0798V12.2499L13.9993 12.839L22.681 12.8379L20.6715 10.8269C20.3609 10.5162 20.3328 10.0301 20.587 9.68757L20.6718 9.58945C20.9825 9.27886 21.4686 9.2507 21.8111 9.50492L21.9092 9.58966L25.4052 13.0869C25.7155 13.3973 25.744 13.8829 25.4904 14.2254L25.4058 14.3235L21.9098 17.8276C21.5685 18.1697 21.0145 18.1703 20.6724 17.829C20.3614 17.5187 20.3326 17.0326 20.5863 16.6898L20.6709 16.5915L22.6694 14.5879L13.9993 14.589L13.9987 22.4583C13.9987 23.0024 13.5073 23.4146 12.9715 23.3199L3.05484 21.5684C2.63678 21.4946 2.33203 21.1313 2.33203 20.7068V6.70827C2.33203 6.27975 2.64238 5.91428 3.06524 5.84484L12.9819 4.21637C13.5146 4.12888 13.9987 4.53995 13.9987 5.0798ZM9.91787 13.4166C9.27216 13.4166 8.7487 13.9401 8.7487 14.5858C8.7487 15.2315 9.27216 15.755 9.91787 15.755C10.5636 15.755 11.087 15.2315 11.087 14.5858C11.087 13.9401 10.5636 13.4166 9.91787 13.4166ZM15.1654 21.5847L16.058 21.585L16.1769 21.577C16.6045 21.5189 16.9338 21.152 16.933 20.7085L16.9247 15.7499H15.1654V21.5847ZM15.1677 11.6666L15.1654 10.1795V5.83327L16.0348 5.83327C16.4773 5.83327 16.8432 6.16171 16.9017 6.58824L16.9098 6.70682L16.9177 11.6666H15.1677Z" fill="white"/>
                    </svg>
                    Logout
                </Link>
            </div>
            <div className='white-block'>
            <img src={DOCUMENT_SUBMIT} alt="Pick" />
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    };
};
const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSubmitted);