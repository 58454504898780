import { Link } from "react-router-dom";
import { signOut } from "../../store/actions/authActions";
import { connect } from "react-redux";
import "./Sidebar.css";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { firebase } from "../../config/fb_config";

const {
  ELLIPSE_TOP,
  ELLIPSE_BOTTOM,
  LOGO_WHITE,
  LOGOUT,
  CROSS_ICON,
} = require("../../utils/imagepath");

const Sidebar = ({ signOut, auth }) => {
  const [userType, setUserType] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const openModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeModalPopup = () => {
    closeModal();
  };

  const closeModal = () => {
    setIsLogoutModalOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch sfId
        const sfIdData = await firebase
          .firestore()
          .collection("user")
          .doc(auth.uid)
          .get();
        console.log("sidebar --- >", sfIdData.data());
        setUserType(sfIdData.data().user_type);
        setOrgType(sfIdData.data().org_type);
      } catch (error) {
        console.error("Error fetching Tickets:", error);
      }
    };
    fetchData();
  }, [auth.uid]);
  // const handleLogoutClick = (event) => {
  //   event.preventDefault();
  //   signOut();
  // };
  const handleLogoutClick = (event) => {
    event.preventDefault();
    openModal();
    //signOut();
  };
  const handleLogout = () => {
    signOut();
  };
  return (
    <>
      <Modal isOpen={isLogoutModalOpen} className="logout-modal">
        <img
          src={CROSS_ICON}
          alt=""
          style={{ position: "absolute", top: "2.375rem", right: "1.5rem" }}
          onClick={closeModalPopup}
        />
        <h1>Are you sure you want to logout?</h1>
        <img src={LOGOUT} alt="" style={{ margin: "2.75rem auto" }} />
        <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
          <button class="ordered-btn" onClick={closeModalPopup}>
            Back
          </button>
          <button class="green-btn" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </Modal>
      <div className="sidebar">
        <div className="elipse-top">
          <img src={ELLIPSE_TOP} alt="Bottles for Change" />
        </div>
        <div className="logo">
          {userType === "requestor" ? (
            <Link to="/Dashboard">
              <img src={LOGO_WHITE} alt="Bottles for Change" />
            </Link>
          ) : (
            <Link to="/collector_dashboard">
              <img src={LOGO_WHITE} alt="Bottles for Change" />
            </Link>
          )}
        </div>
        <ul>
          <li>
            {userType === "requestor" ? (
              <Link to="/Dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M12.3299 2.322C11.5617 1.67392 10.4383 1.67392 9.67006 2.322L3.48256 7.54199C3.01804 7.93388 2.75 8.51069 2.75 9.11843V17.6481C2.75 18.5341 3.46821 19.2523 4.35417 19.2523H7.10417C7.99012 19.2523 8.70833 18.5341 8.70833 17.6481V13.9791C8.70833 13.3564 9.20502 12.8497 9.82381 12.8336H12.1762C12.795 12.8497 13.2917 13.3564 13.2917 13.9791V17.6481C13.2917 18.5341 14.0099 19.2523 14.8958 19.2523H17.6458C18.5318 19.2523 19.25 18.5341 19.25 17.6481V9.11843C19.25 8.51069 18.982 7.93388 18.5174 7.54199L12.3299 2.322Z"
                    fill="white"
                  />
                </svg>
                Home
              </Link>
            ) : (
              <Link to="/collector_dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M12.3299 2.322C11.5617 1.67392 10.4383 1.67392 9.67006 2.322L3.48256 7.54199C3.01804 7.93388 2.75 8.51069 2.75 9.11843V17.6481C2.75 18.5341 3.46821 19.2523 4.35417 19.2523H7.10417C7.99012 19.2523 8.70833 18.5341 8.70833 17.6481V13.9791C8.70833 13.3564 9.20502 12.8497 9.82381 12.8336H12.1762C12.795 12.8497 13.2917 13.3564 13.2917 13.9791V17.6481C13.2917 18.5341 14.0099 19.2523 14.8958 19.2523H17.6458C18.5318 19.2523 19.25 18.5341 19.25 17.6481V9.11843C19.25 8.51069 18.982 7.93388 18.5174 7.54199L12.3299 2.322Z"
                    fill="white"
                  />
                </svg>
                Home
              </Link>
            )}
          </li>
          <li>
            <Link to="/MyTickets">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.70001 3.84923C7.70001 4.54995 8.13681 5.14865 8.75298 5.38791C8.62535 5.94579 8.24227 6.35883 7.65555 6.96332L7.65465 6.96426L7.65388 6.96505C7.53911 7.0833 7.41731 7.20878 7.29353 7.341C6.20273 8.23651 5.5 9.53695 5.5 10.9993C5.5 11.1028 5.50353 11.2056 5.5105 11.3076C5.50354 11.3874 5.5 11.468 5.5 11.5493V17.0493C5.5 18.5681 6.73123 19.7993 8.25002 19.7993C8.75042 19.7993 9.22041 19.6653 9.62503 19.4313C10.0297 19.6653 10.4996 19.7993 11 19.7993C11.5004 19.7993 11.9704 19.6653 12.375 19.4313C12.7797 19.6653 13.2497 19.7993 13.7501 19.7993C15.2688 19.7993 16.5001 18.5681 16.5001 17.0493V11.5493C16.5001 11.468 16.4965 11.3874 16.4896 11.3076C16.4965 11.2056 16.5001 11.1028 16.5001 10.9993C16.5001 10.1022 16.2348 9.26253 15.7756 8.54087C15.7671 8.52496 15.7578 8.50937 15.7477 8.49418C15.3098 7.83737 14.8096 7.34467 14.3878 6.92928L14.3764 6.91805C13.7769 6.32756 13.3852 5.92175 13.2513 5.38626C13.8653 5.14591 14.3001 4.54835 14.3001 3.84923C14.3001 2.93795 13.5613 2.19922 12.65 2.19922H9.35003C8.43875 2.19922 7.70001 2.93795 7.70001 3.84923ZM6.60001 17.0493V16.4993H15.4001V17.0493C15.4001 17.9606 14.6613 18.6993 13.7501 18.6993C13.3593 18.6993 13.0016 18.5642 12.7191 18.3377C12.518 18.1765 12.2321 18.1765 12.031 18.3377C11.7485 18.5642 11.3908 18.6993 11 18.6993C10.6093 18.6993 10.2516 18.5642 9.96904 18.3377C9.76801 18.1765 9.48205 18.1765 9.28102 18.3377C8.99843 18.5642 8.64076 18.6993 8.25002 18.6993C7.33874 18.6993 6.60001 17.9606 6.60001 17.0493ZM9.8521 5.49924H12.1509C12.3324 6.45005 12.9695 7.0769 13.5559 7.65388L13.602 7.69926H8.47423L8.48819 7.68488C9.06644 7.0896 9.67909 6.45891 9.8521 5.49924ZM12.65 4.39923H9.35003C9.04627 4.39923 8.80002 4.15299 8.80002 3.84923C8.80002 3.54547 9.04627 3.29923 9.35003 3.29923H12.65C12.9538 3.29923 13.2001 3.54547 13.2001 3.84923C13.2001 4.15299 12.9538 4.39923 12.65 4.39923Z"
                  fill="white"
                />
              </svg>
              My Collections
              {/* {userType === "requestor" ? "My Collections" : "My Collections"} */}
            </Link>
          </li>
          {/* <li>
                    <Link to='/Feedback'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M11.5503 13.2003C12.4615 13.2003 13.2003 13.939 13.2003 14.8503V15.4003C13.2003 17.5689 11.1548 19.8003 7.70023 19.8003C4.24567 19.8003 2.2002 17.5689 2.2002 15.4003V14.8503C2.2002 13.939 2.93893 13.2003 3.85021 13.2003H11.5503ZM7.70023 6.05022C9.3709 6.05022 10.7253 7.40457 10.7253 9.07524C10.7253 10.7459 9.3709 12.1003 7.70023 12.1003C6.02956 12.1003 4.67521 10.7459 4.67521 9.07524C4.67521 7.40457 6.02956 6.05022 7.70023 6.05022ZM17.6003 2.2002C18.7601 2.2002 19.7103 3.09767 19.7943 4.23602L19.8003 4.40021V6.60022C19.8003 7.76003 18.9028 8.71022 17.7645 8.79421L17.6003 8.80024H15.9492L14.6313 10.56C14.049 11.3369 12.8664 11.0222 12.677 10.1469L12.6575 10.0242L12.6511 9.90025L12.6503 8.72874L12.5649 8.70709C11.7669 8.46677 11.16 7.78551 11.0274 6.94651L11.0063 6.76441L11.0003 6.60022V4.40021C11.0003 3.2404 11.8977 2.29021 13.0361 2.20623L13.2003 2.2002H17.6003Z" fill="white"/>
                        </svg>
                        Feedbacks
                    </Link>                
                </li> */}
          {
            userType === "requestor" &&
              (orgType
                ? orgType.toLowerCase() === "school" && (
                    <li>
                      <Link to="/AllSurvey">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.14983 10.9999C7.45359 10.9999 7.69983 10.7536 7.69983 10.4499C7.69983 10.1461 7.45359 9.89985 7.14983 9.89985C6.84607 9.89985 6.59983 10.1461 6.59983 10.4499C6.59983 10.7536 6.84607 10.9999 7.14983 10.9999ZM7.69983 14.8499C7.69983 15.1536 7.45359 15.3999 7.14983 15.3999C6.84607 15.3999 6.59983 15.1536 6.59983 14.8499C6.59983 14.5461 6.84607 14.2999 7.14983 14.2999C7.45359 14.2999 7.69983 14.5461 7.69983 14.8499ZM3.2998 6.59983C3.2998 4.77728 4.77728 3.2998 6.59983 3.2998H15.3999C17.2224 3.2998 18.6999 4.77728 18.6999 6.59983V15.3999C18.6999 17.2224 17.2224 18.6999 15.3999 18.6999H6.59983C4.77728 18.6999 3.2998 17.2224 3.2998 15.3999V6.59983ZM6.04982 5.49982C5.74606 5.49982 5.49982 5.74606 5.49982 6.04982C5.49982 6.35358 5.74606 6.59983 6.04982 6.59983H15.9499C16.2536 6.59983 16.4999 6.35358 16.4999 6.04982C16.4999 5.74606 16.2536 5.49982 15.9499 5.49982H6.04982ZM7.14983 12.0999C8.06111 12.0999 8.79984 11.3611 8.79984 10.4499C8.79984 9.53858 8.06111 8.79984 7.14983 8.79984C6.23855 8.79984 5.49982 9.53858 5.49982 10.4499C5.49982 11.3611 6.23855 12.0999 7.14983 12.0999ZM8.79984 14.8499C8.79984 13.9386 8.06111 13.1999 7.14983 13.1999C6.23855 13.1999 5.49982 13.9386 5.49982 14.8499C5.49982 15.7612 6.23855 16.4999 7.14983 16.4999C8.06111 16.4999 8.79984 15.7612 8.79984 14.8499ZM9.89985 10.4499C9.89985 10.7536 10.1461 10.9999 10.4499 10.9999H15.9499C16.2536 10.9999 16.4999 10.7536 16.4999 10.4499C16.4999 10.1461 16.2536 9.89985 15.9499 9.89985H10.4499C10.1461 9.89985 9.89985 10.1461 9.89985 10.4499ZM10.4499 14.2999C10.1461 14.2999 9.89985 14.5461 9.89985 14.8499C9.89985 15.1536 10.1461 15.3999 10.4499 15.3999H15.9499C16.2536 15.3999 16.4999 15.1536 16.4999 14.8499C16.4999 14.5461 16.2536 14.2999 15.9499 14.2999H10.4499Z"
                            fill="white"
                          />
                        </svg>
                        Surveys
                      </Link>
                    </li>
                  )
                : null)
            // ) : (
            //   <li>
            //     <Link to="/AllSurvey">
            //       <svg
            //         xmlns="http://www.w3.org/2000/svg"
            //         width="22"
            //         height="22"
            //         viewBox="0 0 22 22"
            //         fill="none"
            //       >
            //         <path
            //           d="M7.14983 10.9999C7.45359 10.9999 7.69983 10.7536 7.69983 10.4499C7.69983 10.1461 7.45359 9.89985 7.14983 9.89985C6.84607 9.89985 6.59983 10.1461 6.59983 10.4499C6.59983 10.7536 6.84607 10.9999 7.14983 10.9999ZM7.69983 14.8499C7.69983 15.1536 7.45359 15.3999 7.14983 15.3999C6.84607 15.3999 6.59983 15.1536 6.59983 14.8499C6.59983 14.5461 6.84607 14.2999 7.14983 14.2999C7.45359 14.2999 7.69983 14.5461 7.69983 14.8499ZM3.2998 6.59983C3.2998 4.77728 4.77728 3.2998 6.59983 3.2998H15.3999C17.2224 3.2998 18.6999 4.77728 18.6999 6.59983V15.3999C18.6999 17.2224 17.2224 18.6999 15.3999 18.6999H6.59983C4.77728 18.6999 3.2998 17.2224 3.2998 15.3999V6.59983ZM6.04982 5.49982C5.74606 5.49982 5.49982 5.74606 5.49982 6.04982C5.49982 6.35358 5.74606 6.59983 6.04982 6.59983H15.9499C16.2536 6.59983 16.4999 6.35358 16.4999 6.04982C16.4999 5.74606 16.2536 5.49982 15.9499 5.49982H6.04982ZM7.14983 12.0999C8.06111 12.0999 8.79984 11.3611 8.79984 10.4499C8.79984 9.53858 8.06111 8.79984 7.14983 8.79984C6.23855 8.79984 5.49982 9.53858 5.49982 10.4499C5.49982 11.3611 6.23855 12.0999 7.14983 12.0999ZM8.79984 14.8499C8.79984 13.9386 8.06111 13.1999 7.14983 13.1999C6.23855 13.1999 5.49982 13.9386 5.49982 14.8499C5.49982 15.7612 6.23855 16.4999 7.14983 16.4999C8.06111 16.4999 8.79984 15.7612 8.79984 14.8499ZM9.89985 10.4499C9.89985 10.7536 10.1461 10.9999 10.4499 10.9999H15.9499C16.2536 10.9999 16.4999 10.7536 16.4999 10.4499C16.4999 10.1461 16.2536 9.89985 15.9499 9.89985H10.4499C10.1461 9.89985 9.89985 10.1461 9.89985 10.4499ZM10.4499 14.2999C10.1461 14.2999 9.89985 14.5461 9.89985 14.8499C9.89985 15.1536 10.1461 15.3999 10.4499 15.3999H15.9499C16.2536 15.3999 16.4999 15.1536 16.4999 14.8499C16.4999 14.5461 16.2536 14.2999 15.9499 14.2999H10.4499Z"
            //           fill="white"
            //         />
            //       </svg>
            //       Surveys
            //     </Link>
            //   </li>
            // )
          }
          <li>
            <Link to="/AllNotification">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.6947 17.602C13.4393 18.8564 12.3299 19.8003 11.0001 19.8003C9.67023 19.8003 8.56087 18.8564 8.30541 17.602H13.6947ZM10.9975 2.2002C14.4613 2.2002 17.3158 4.76858 17.5804 8.09118L17.5952 8.33562L17.6001 8.58259L17.599 12.5491L18.6481 15.3949L18.6763 15.4879L18.6934 15.5833L18.6991 15.6803C18.6991 16.0269 18.4838 16.3298 18.1249 16.4583L18.0001 16.4944L17.8741 16.5053H4.12651C4.02916 16.5053 3.93258 16.4881 3.84124 16.4544C3.52565 16.3381 3.31801 16.0454 3.31055 15.6667L3.3156 15.5374L3.35239 15.395L4.39892 12.5524L4.40012 8.57232L4.40485 8.3253C4.53972 4.89648 7.44817 2.2002 10.9975 2.2002Z"
                  fill="white"
                />
              </svg>
              Notifications
            </Link>
          </li>
          {userType === "collector" ? (
            <li>
              <Link to="/Resource">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M7.14983 10.9999C7.45359 10.9999 7.69983 10.7536 7.69983 10.4499C7.69983 10.1461 7.45359 9.89985 7.14983 9.89985C6.84607 9.89985 6.59983 10.1461 6.59983 10.4499C6.59983 10.7536 6.84607 10.9999 7.14983 10.9999ZM7.69983 14.8499C7.69983 15.1536 7.45359 15.3999 7.14983 15.3999C6.84607 15.3999 6.59983 15.1536 6.59983 14.8499C6.59983 14.5461 6.84607 14.2999 7.14983 14.2999C7.45359 14.2999 7.69983 14.5461 7.69983 14.8499ZM3.2998 6.59983C3.2998 4.77728 4.77728 3.2998 6.59983 3.2998H15.3999C17.2224 3.2998 18.6999 4.77728 18.6999 6.59983V15.3999C18.6999 17.2224 17.2224 18.6999 15.3999 18.6999H6.59983C4.77728 18.6999 3.2998 17.2224 3.2998 15.3999V6.59983ZM6.04982 5.49982C5.74606 5.49982 5.49982 5.74606 5.49982 6.04982C5.49982 6.35358 5.74606 6.59983 6.04982 6.59983H15.9499C16.2536 6.59983 16.4999 6.35358 16.4999 6.04982C16.4999 5.74606 16.2536 5.49982 15.9499 5.49982H6.04982ZM7.14983 12.0999C8.06111 12.0999 8.79984 11.3611 8.79984 10.4499C8.79984 9.53858 8.06111 8.79984 7.14983 8.79984C6.23855 8.79984 5.49982 9.53858 5.49982 10.4499C5.49982 11.3611 6.23855 12.0999 7.14983 12.0999ZM8.79984 14.8499C8.79984 13.9386 8.06111 13.1999 7.14983 13.1999C6.23855 13.1999 5.49982 13.9386 5.49982 14.8499C5.49982 15.7612 6.23855 16.4999 7.14983 16.4999C8.06111 16.4999 8.79984 15.7612 8.79984 14.8499ZM9.89985 10.4499C9.89985 10.7536 10.1461 10.9999 10.4499 10.9999H15.9499C16.2536 10.9999 16.4999 10.7536 16.4999 10.4499C16.4999 10.1461 16.2536 9.89985 15.9499 9.89985H10.4499C10.1461 9.89985 9.89985 10.1461 9.89985 10.4499ZM10.4499 14.2999C10.1461 14.2999 9.89985 14.5461 9.89985 14.8499C9.89985 15.1536 10.1461 15.3999 10.4499 15.3999H15.9499C16.2536 15.3999 16.4999 15.1536 16.4999 14.8499C16.4999 14.5461 16.2536 14.2999 15.9499 14.2999H10.4499Z"
                    fill="white"
                  />
                </svg>
                My Resource
              </Link>
            </li>
          ) : null}

          <li>
            <Link to="/help_support">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M4.5835 8.24967C4.5835 4.70585 7.45634 1.83301 11.0002 1.83301C14.544 1.83301 17.4168 4.70585 17.4168 8.24967V12.833C17.4168 13.8455 16.596 14.6663 15.5835 14.6663H13.7502C13.2439 14.6663 12.8335 14.2559 12.8335 13.7497V10.083C12.8335 9.57675 13.2439 9.16634 13.7502 9.16634H16.0418V8.24967C16.0418 5.46524 13.7846 3.20801 11.0002 3.20801C8.21573 3.20801 5.9585 5.46524 5.9585 8.24967V9.16634H8.25016C8.75642 9.16634 9.16683 9.57675 9.16683 10.083V13.7497C9.16683 14.2559 8.75642 14.6663 8.25016 14.6663H6.41683C6.25856 14.6663 6.10498 14.6463 5.95848 14.6086L5.9585 14.8955C5.9585 15.9871 6.80656 16.8807 7.87978 16.9533L8.021 16.958L9.05478 16.9587C9.33771 16.1573 10.1019 15.583 11.0002 15.583C12.1393 15.583 13.0627 16.5064 13.0627 17.6455C13.0627 18.7846 12.1393 19.708 11.0002 19.708C10.1022 19.708 9.33831 19.1342 9.0551 18.3332L8.021 18.333C6.18376 18.333 4.68316 16.8917 4.58826 15.0781L4.5835 14.8955V8.24967Z"
                  fill="white"
                />
              </svg>
              Help & Support
            </Link>
          </li>
          <li>
            <Link to="/faq">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M9.35036 1.09961C5.40149 1.09961 2.20031 4.3008 2.20031 8.24966C2.20031 9.51577 2.52986 10.7064 3.10809 11.7388L2.23277 14.0293C1.91015 14.8735 2.68674 15.7249 3.55698 15.4812L6.29311 14.7148C7.22065 15.1542 8.25752 15.3997 9.35036 15.3997C13.2992 15.3997 16.5004 12.1985 16.5004 8.24966C16.5004 4.3008 13.2992 1.09961 9.35036 1.09961ZM9.34222 5.39089C8.94841 5.46785 8.51843 5.72335 8.17836 6.32148C8.02822 6.58554 7.69245 6.6779 7.42839 6.52777C7.16432 6.37763 7.07197 6.04186 7.2221 5.7778C7.70704 4.92487 8.39675 4.45485 9.13123 4.31131C9.84944 4.17094 10.5634 4.35197 11.1026 4.70902C11.6347 5.06129 12.0649 5.6363 12.0786 6.3267C12.0929 7.04627 11.6542 7.69604 10.8475 8.17306C10.299 8.49738 10.0857 8.7173 9.99503 8.8634C9.9187 8.98639 9.90025 9.09856 9.90025 9.34966C9.90025 9.65342 9.654 9.89967 9.35024 9.89967C9.04648 9.89967 8.80024 9.65342 8.80024 9.34966C8.80024 9.05076 8.81553 8.67788 9.0604 8.28333C9.29093 7.9119 9.68625 7.58181 10.2876 7.22621C10.8761 6.87823 10.9827 6.54649 10.9788 6.34855C10.9743 6.12145 10.8227 5.84293 10.4953 5.62619C10.1752 5.41422 9.7523 5.31074 9.34222 5.39089ZM9.62515 12.6497C9.16951 12.6497 8.80014 12.2803 8.80014 11.8247C8.80014 11.369 9.16951 10.9997 9.62515 10.9997C10.0808 10.9997 10.4502 11.369 10.4502 11.8247C10.4502 12.2803 10.0808 12.6497 9.62515 12.6497ZM7.18988 16.214C8.50109 17.736 10.4426 18.6997 12.6092 18.6997C13.7021 18.6997 14.7389 18.4542 15.6665 18.0149L18.4026 18.7812C19.2729 19.025 20.0494 18.1735 19.7268 17.3293L18.8515 15.0388C19.4297 14.0064 19.7593 12.8158 19.7593 11.5497C19.7593 9.41522 18.824 7.49922 17.3409 6.18912C17.5006 6.81015 17.5898 7.45947 17.5994 8.12803C18.268 9.10121 18.6593 10.2797 18.6593 11.5497C18.6593 12.7055 18.3357 13.7842 17.7744 14.7018L17.6333 14.9325L18.6993 17.722L15.5533 16.8408L15.3617 16.9389C14.5366 17.3612 13.6016 17.5997 12.6092 17.5997C11.3122 17.5997 10.1105 17.1916 9.12557 16.4967C8.4575 16.4788 7.80924 16.3816 7.18988 16.214Z"
                  fill="white"
                />
              </svg>
              FAQ
            </Link>
          </li>
          <li>
            <Link to="/" onClick={handleLogoutClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M12.0999 3.84981C12.0999 3.68943 12.0299 3.53704 11.9082 3.43255C11.7865 3.32806 11.6253 3.28189 11.4668 3.30612L3.7667 4.48313C3.49815 4.52418 3.2998 4.75515 3.2998 5.02682V16.9729C3.2998 17.2445 3.49811 17.4755 3.76662 17.5166L11.4667 18.6947C11.6252 18.719 11.7865 18.6728 11.9082 18.5683C12.0298 18.4638 12.0999 18.3114 12.0999 18.151V10.9999L17.7894 10.9999L16.6928 11.961C16.4645 12.161 16.4414 12.5085 16.6411 12.7371C16.8408 12.9657 17.1877 12.9888 17.416 12.7888L19.6124 10.8638C19.7316 10.7593 19.7999 10.6085 19.7999 10.4499C19.7999 10.2913 19.7316 10.1404 19.6124 10.0359L17.416 8.11093C17.1877 7.9109 16.8408 7.93407 16.6411 8.16267C16.4414 8.39127 16.4645 8.73874 16.6928 8.93877L17.7894 9.89987L12.0999 9.89986V3.84981ZM9.34985 12.3749C8.89421 12.3749 8.52484 12.0055 8.52484 11.5499C8.52484 11.0942 8.89421 10.7249 9.34985 10.7249C9.80548 10.7249 10.1749 11.0942 10.1749 11.5499C10.1749 12.0055 9.80548 12.3749 9.34985 12.3749ZM13.7499 17.5999H13.1999V12.0999H14.2999V17.0499C14.2999 17.3537 14.0536 17.5999 13.7499 17.5999ZM13.1999 8.79984V4.39981H13.7499C14.0536 4.39981 14.2999 4.64606 14.2999 4.94982V8.79984H13.1999Z"
                  fill="white"
                />
              </svg>
              Logout
            </Link>
          </li>
        </ul>
        <div className="elipse-bottom">
          <img src={ELLIPSE_BOTTOM} alt="Bottles for Change" />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
