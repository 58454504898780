import Sidebar from "../../components/Sidebar/Sidebar";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import { useState, useEffect } from "react";
import ResourceCard from "../../components/ResourceCard/ResourceCard";
import { getVolunteers } from "../../api/collectorApi/sfdx_collector_apis";
import { Link } from "react-router-dom";
import "./Resources.css";
import { firebase } from "../../config/fb_config";
import { connect } from "react-redux";

const Resources = ({ auth }) => {
  const [sfId, setSfId] = useState("");
  const [fbUser, setFbUser] = useState(null);
  const [myResources, setMyResources] = useState([]);
  const [response,setResponse]=useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        console.log("FB Data ", doc.data());
        setFbUser(doc.data());
        setSfId(doc.data().sf_id);
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });
  }, [auth.uid]);

  useEffect(() => {
    debugger
    if (sfId) {
      const fetchData = async () => {
        try {
          const data = await getVolunteers(sfId);
debugger
          if (data && data.volunteers && Array.isArray(data.volunteers)) {
            setResponse(data);
            setMyResources(data.volunteers);
          } else {
            console.error(
              "myResources data is not in the expected format:",
              data
            );
          }
        } catch (error) {
          console.error("Error fetching myResources:", error);
        }
      };
      fetchData();
    }
  }, [sfId]);

  console.log("resources------>", myResources);

  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-wrap">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Resources</h1>
            <Link to="/NewResource" className="green-btn">
              New Resource
            </Link>
          </div>
          <div className="total-resource">
            <div>Total Resources</div>
            <div>{response.present_volunteers}</div>
          </div>
          {myResources?myResources.map((item,key)=>(
            <ResourceCard resourceData={item} />
          )):null}          
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          {/* <LeaderboardScore /> */}
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, null)(Resources);
