import { Link, useNavigate } from "react-router-dom";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import { useEffect, useState } from "react";
import "./UploadDocuments.css";
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import { signOut } from "../../store/actions/authActions";
import { getCollectorDocument } from "../../api/collectorApi/sfdx_collector_apis";
import { postCollectorDocument } from "../../api/collectorApi/sfdx_collector_apis";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const UploadDocuments = ({ signOut, auth, history }) => {
  const navigate = useNavigate();
  const [sfId, setSfId] = useState("");
  const [fbUserData, setFbUserData] = useState("");
  const [documentsData, setDocumentsData] = useState([]);
  const [returnObject, setReturnObject] = useState([]);
  const [submittedDocuments, setSubmittedDocuments] = useState([]);

  const handleDocumentSubmit = (submittedDocument) => {
    setSubmittedDocuments((prevDocuments) => [
      ...prevDocuments,
      submittedDocument,
    ]);
  };

  const submitClick = async () => {
    debugger;
    // Now 'submittedDocuments' contains all the submitted documents
    if (!documentsData) {
      Swal.fire({
        title: "Uplaod Documents",
        text: "Something went wrong, unable to upload the documents",
        icon: "error",
      });
      return;
    }
    if (!submittedDocuments) {
      Swal.fire({
        title: "Uplaod Documents",
        text: "Something went wrong, unable to upload the documents",
        icon: "error",
      });
      return;
    }
    if (submittedDocuments.length <= 0) {
      Swal.fire({
        title: "Uplaod Documents",
        text: "Please first uplaod the documents",
        icon: "error",
      });
      return;
    }
    console.log("Submitted Documents:", submittedDocuments);

    // Additional logic for submitting the documents to the server
    // ...

    // For example, you can iterate over 'submittedDocuments' and send each document
    // for (const submittedDocument of submittedDocuments) {
    // const formData = {
    //   status: "Uploaded",
    //   sf_id: sfId,
    //   document_url: submittedDocument.docUrl,
    //   doc_name: submittedDocument.docName,
    // };
    console.log("API Payload", submittedDocuments);
    console.log("API Payload2", JSON.stringify(submittedDocuments));
    //return
    try {
      const response = await postCollectorDocument(sfId, {
        documents: submittedDocuments,
      });
      if (response) {
        Swal.fire({
          title: "Document Upload",
          text: "Documents submitted successfully!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            handleSuccessDocUplaod();
          } else if (result.isDismissed) {
            handleSuccessDocUplaod();
          }
        });
      } else {
        Swal.fire({
          title: "Upload Documents",
          text: "Oops! Something went wrong. Please try again later.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error submitting documents:", error);
    }
    // }

    // Display success alert

    // Navigate to '/underApproval' page
    // window.location.href = "/underApproval";
  };
  const handleSuccessDocUplaod = () => {
    const uploadedCountResponse = documentsData.filter(
      (obj) => obj.status === "Uploaded"
    ).length;
    const approvedCountResponse = documentsData.filter(
      (obj) => obj.status === "Approved"
    ).length;
    const uploadedCountSubmitted = submittedDocuments.filter(
      (obj) => obj.status === "Uploaded"
    ).length;
    if (
      uploadedCountResponse + uploadedCountSubmitted + approvedCountResponse >=
      documentsData.length
    ) {
      navigate("/underApproval");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const userDoc = await firebase
          .firestore()
          .collection("user")
          .doc(auth.uid)
          .get();
        console.log("FB Data ", userDoc.data());

        // Set Firebase user data and sfId
        setFbUserData(userDoc.data());
        setSfId(userDoc.data().sf_id);

        // Fetch documents data if sfId is truthy
        if (userDoc.data().sf_id) {
          const data = await getCollectorDocument(userDoc.data().sf_id);
          if (data && data.documents && Array.isArray(data.documents)) {
            debugger;
            setDocumentsData(data.documents);
            setReturnObject(data.documents);
            console.log("Documents data - -", data.documents);
            let approvedCount = 0;
            let uploadedCount = 0;
            let pendingCount = 0;
            let rejectedCount = 0;
            // Loop through the array
            for (const obj of data.documents) {
              // Check if the status is "Approved"
              if (obj.status === "Approved") {
                approvedCount++;
              } else if (obj.status === "Uploaded") {
                uploadedCount++;
              } else if (obj.status === "Pending") {
                pendingCount++;
              } else if (obj.status === "Rejected") {
                rejectedCount++;
              }
            }
            if (pendingCount <= 0 && rejectedCount <= 0) {
              if (approvedCount === data.documents.length) {
                navigate("/collector_dashboard");
              } else {
                navigate("/underApproval");
              }
            }
          } else {
            console.error(
              "Documents data is not in the expected format:",
              data
            );
          }
        }
      } catch (error) {
        console.error("Error fetching user profile or documents:", error);
      }
    };

    fetchData();
  }, []);
  async function updateDocURL(doc_namee, doc_url, doc_id) {
    debugger;
    const index = returnObject.findIndex((item) => item.doc_name === doc_namee);
    //documentsData[index].document_url=doc_url;
    // If the document is found, update the state
    if (index !== -1) {
      setReturnObject((prevState) => {
        return prevState.map((item, i) => {
          if (i === index) {
            // Update the document_url for the specified doc_name
            return { ...item, document_url: doc_url };
          }
          return item;
        });
      });
      console.log("Uplaoded FB URL -- ", doc_url);
      console.log("After the file upload:", returnObject);
    } else {
      console.log("Document not found:", doc_namee);
    }
    debugger;
    const formData = {
      status: "Uploaded",
      sf_id: doc_id,
      document_url: doc_url,
      doc_name: doc_namee,
    };
    setSubmittedDocuments((prevArray) => [...prevArray, formData]);
    // const formData = {
    //   documents: [
    //     {
    //       status: "Uploaded",
    //       sf_id: doc_id,
    //       document_url: doc_url,
    //       doc_name: doc_namee,
    //     },
    //   ],
    // };
    // const formData = [{
    //     status: 'Uploaded',
    //     sf_id: sfId,
    //     document_url: doc_url,
    //     doc_name: doc_namee,
    //   }];
    return;
    console.log("API Payload", formData);
    console.log("API Payload2", JSON.stringify(formData));
    try {
      const response = await postCollectorDocument(sfId, formData);
      console.log(response);
    } catch (error) {
      console.error("Error submitting documents:", error);
    }
  }
  //documentsData
  // debugger
  // documentsData.find(item => item.doc_name === doc_namee);
  // documentsData.map(item => {
  //     if (item.doc_name === doc_namee) {
  //       // Update the document_url for the specified doc_name
  //       return { ...item, document_url: doc_url };
  //     }
  //     return item;
  //   });
  //   console.log('after the file uplaod -- ',documentsData);
  //}
  console.log("documentsData---------------->", documentsData);
  const handleLogoutClick = (event) => {
    //event.preventDefault();
    signOut();
  };
  return (
    <div className="upload-document">
      <div className="header">
        Upload Documents
        <Link
          to="/home"
          onClick={() => {
            handleLogoutClick();
          }}
          className="logout"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M13.9987 5.0798V12.2499L13.9993 12.839L22.681 12.8379L20.6715 10.8269C20.3609 10.5162 20.3328 10.0301 20.587 9.68757L20.6718 9.58945C20.9825 9.27886 21.4686 9.2507 21.8111 9.50492L21.9092 9.58966L25.4052 13.0869C25.7155 13.3973 25.744 13.8829 25.4904 14.2254L25.4058 14.3235L21.9098 17.8276C21.5685 18.1697 21.0145 18.1703 20.6724 17.829C20.3614 17.5187 20.3326 17.0326 20.5863 16.6898L20.6709 16.5915L22.6694 14.5879L13.9993 14.589L13.9987 22.4583C13.9987 23.0024 13.5073 23.4146 12.9715 23.3199L3.05484 21.5684C2.63678 21.4946 2.33203 21.1313 2.33203 20.7068V6.70827C2.33203 6.27975 2.64238 5.91428 3.06524 5.84484L12.9819 4.21637C13.5146 4.12888 13.9987 4.53995 13.9987 5.0798ZM9.91787 13.4166C9.27216 13.4166 8.7487 13.9401 8.7487 14.5858C8.7487 15.2315 9.27216 15.755 9.91787 15.755C10.5636 15.755 11.087 15.2315 11.087 14.5858C11.087 13.9401 10.5636 13.4166 9.91787 13.4166ZM15.1654 21.5847L16.058 21.585L16.1769 21.577C16.6045 21.5189 16.9338 21.152 16.933 20.7085L16.9247 15.7499H15.1654V21.5847ZM15.1677 11.6666L15.1654 10.1795V5.83327L16.0348 5.83327C16.4773 5.83327 16.8432 6.16171 16.9017 6.58824L16.9098 6.70682L16.9177 11.6666H15.1677Z"
              fill="white"
            />
          </svg>
          Logout
        </Link>
      </div>
      <div className="white-block">
        {documentsData ? (
          documentsData.map((item, index) => (
            <DocumentCard
              callBackFn={updateDocURL}
              documentData={item}
              sf_id={sfId}
            />
          ))
        ) : (
          <div>Data Not Available</div>
        )}
        <div style={{ marginTop: "2.5rem", textAlign: "center" }}>
          <button onClick={submitClick} className="green-btn">
            Submit for approval
          </button>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);
