import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { firebase } from '../../config/fb_config';
import ProfileHeader from '../../atom/ProfileBlock/ProfileBlock'
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import './Profile.css'
import {STATE_OBJ} from "../../utils/constants";
const {BACK_BUTTON} =  require('../../utils/imagepath')


export default function Profile({uid, profile, handleChange, profileName, setProfileName}) { 
    //'{"phone":"9874566","country":"IN","state":"JK","pincode":"635355","acc_id":"0010T00000fXNtCQAW","status":"Active","email":"prithvi.shiroor@utilitarianlabs.com","user_type":"requestor","street":"Test","sf_id":"0030T00000SqznIQAR","first_name":"Prithviraj","last_name":"Shirke","city":"Test"}'
    
    const [isEdit, setIsEdit] = useState(true);
    const editClickHandler = () => {
        if(!isEdit) {
            debugger;
            if (!validatePhone(profile.phone)) {
                alert('Invalid phone number');
                return true;
            }
        
            if (!validateStreet(profile.street)) {
                alert('Enter a valid street address.');
                return true;
            }
        
            if (!validateState(profile.state)) {
                alert('Invalid state');
                return true;
            }
        
            if (!validateCountry(profile.country)) {
                alert('Invalid country');
                return true;
            }
            updateProfile();
        }
        setIsEdit(!isEdit);
    }

    const validatePhone = (phone) => {
        return validator.isMobilePhone(phone, 'any', { strictMode: false, len: 10 });
    };
      
    const validateStreet = (street) => {
        // Add your custom street validation logic
        if(!street || street.length <= 5) {
            return false;
        }
        return true; // Replace with your validation logic
    };
      
    const validateState = (state) => {
        // Add your custom state validation logic
        if(!state) {
            return false;
        }
        return true; // Replace with your validation logic
    };
      
    const validateCountry = (country) => {
        // Add your custom country validation logic
        return true; // Replace with your validation logic
    };


    

    const  updateProfile = async () => {
        try {
            
            // Reference to the user document
            const userRef = firebase.firestore().collection('user').doc(uid);
            // Update the document with the new data
            await userRef.update(profile);
            setProfileName(profile.first_name + ' ' + profile.last_name);
            toast("Profile successfully updated");
            console.log('Document successfully updated!');
          } catch (error) {
            console.error('Error updating document: ', error);
          }
    }
    const profileBlock = () => {
        if(profile) {
            return <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    />
                <ProfileHeader profileName={`${profileName}`} userType={profile.user_type} email={profile.email} editClickHandler={editClickHandler}/>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem', gap: '1.25rem' }}>
                    <div className="flex-1">
                        <label>First Name</label>
                        <input type='text' id="first_name" className='profile-form-control' value={profile.first_name} disabled={isEdit} onChange={handleChange} />
                    </div>
                    <div className="flex-1">
                        <label>Last Name</label>
                        <input type='text' id="last_name" className='profile-form-control' value={profile.last_name} disabled={isEdit} onChange={handleChange}/>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                    <div className="flex-1">
                        <label>Country</label>
                        <input type='text' id="country" className='profile-form-control' value={profile.country} disabled={true} onChange={handleChange}/>
                    </div>
                    <div className="flex-1">
                        <label>State</label>
                       {/*  <input type='text' className='profile-form-control' value={profile.state} disabled={isEdit}/> */}
                       <select
                                id="state"
                                className="block w-full px-4 py-2 mt-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                value={profile.state}
                                onChange={handleChange}
                                disabled={isEdit}
                            >
                                <option value="">--None--</option>
                                {Object.entries(STATE_OBJ).map(
                                    ([code, name]) => (
                                        <option key={code} value={code}>
                                            {name}
                                        </option>
                                    )
                                )}
                            </select>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }} >
                    <div className="flex-1">
                        <label>City</label>
                        <input type='text' id="city" className='profile-form-control' value={profile.city} disabled={isEdit} onChange={handleChange}/>
                    </div>
                    <div className="flex-1">
                        <label>Pincode</label>
                        <input type='number' id="pincode" className='profile-form-control' value={profile.pincode} disabled={isEdit} onChange={handleChange}/>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                    <div className="flex-1">
                        <label>Street Address</label>
                        <input type='text' id="street" className='profile-form-control' value={profile.street} disabled={isEdit} onChange={handleChange}/>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                    <div className="flex-1">
                        <label>Email</label>
                        <input type='text' className='profile-form-control' value={profile.email} disabled={true} />
                    </div>
                    <div className="flex-1">
                        <label>Phone Number</label>
                        <input type='number' className='profile-form-control' value={profile.phone} disabled={true}/>
                    </div>
                </div>
            </>
        }else {
            return <div className="flex flex-row"><h3>Profile not found</h3></div>
        }
        
    }


    
    return (
        <div className="profile mid-wrap">
            {/* <Link to='/Dashboard'>
                <img src={BACK_BUTTON} alt="" />
            </Link> */}
            <h1 style={{marginTop:'1rem', marginBottom: '1.5rem'}}>Profile</h1>
            
            {profileBlock()}
            
        </div>
    )
}