import Sidebar from "../../components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import "./Dashboard.css";
import { getDashboardData } from "../../api/requestorApi/sfdx_requestor_apis";
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import DownloadOurApp from "../../components/ui_utilities/DownloadOurApp";
import FAVICON from "../../asset/images/app_icon.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WEB_CONST } from "../../utils/constants";
import swal from "sweetalert";

const Dashboard = ({ auth, history }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("daily");
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const percentage = 66;
  const [yearlyData, setYearlyData] = useState([]);
  const [sfId, setSfId] = useState("");
  const [fbUser, setFbUser] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [userType, setUserType] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [duration, setDuration] = useState("daily");
  const [customShowResult, setCustomShowResult] = useState(false);

  useEffect(() => {
    /* if(auth){
            debugger;
            firebase.auth().currentUser.getIdToken().then(function(idToken) {
                // Send token to your backend via HTTPS
                // ...
                console.log("idtoken",idToken);
            }).catch(function(error) {
            // Handle error
            });

        } */
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        console.log("FB Data ", doc.data());
        setFbUser(doc.data());
        setSfId(doc.data().sf_id);
        setUserType(doc.data().user_type);
        setOrgType(doc.data().org_type);
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });
  }, [auth.uid]);

  useEffect(() => {
    if (sfId) {
      debugger;
      const fetchData = async () => {
        console.log("sfid----->", sfId);
        //if(!startDate || !endDate){
        // console.log(WEB_CONST.START_DATE);
        // console.log(WEB_CONST.END_DATE);
        // setStartDate(WEB_CONST.START_DATE);
        // setEndDate(WEB_CONST.END_DATE);
        // setStartDate('2023-12-12');
        // setEndDate('2023-12-31');
        //}
        debugger;
        try {
          let data = null;
          if (!startDate || !endDate) {
            data = await getDashboardData(
              sfId,
              duration,
              "2024-01-01",
              "2024-12-31"
            );
          } else {
            const start_date = startDate.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            const end_date = endDate.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            console.log("startdate -  ", start_date);
            console.log("endadate---", end_date);
            data = await getDashboardData(sfId, duration, start_date, end_date);
          }
          console.log("Dashboard Object --- ", data);
          debugger;
          // if (isCustom) {
          setDashboardData(data);
          //   console.log("custom data-->", dashboardData);
          // } else {
          //   setDashboardData(data.AllReports.daily_reports);
          // }
          // setDailyData(data.AllReports.daily_reports);
          // setMonthlyData(data.AllReports.monthly_reports);
          // setYearlyData(data.AllReports.yearly_reports);
          // setNotifications(data.notifications);
        } catch (error) {
          console.log("Exeption Dashboard--", error);
          // swal({
          //   title:'Dashboard',
          //   text:'Something went wrong, please try again.',
          //   icon:'error'
          // });
        }
      };

      fetchData();
    }
  }, [sfId, isCustom, duration]);

  console.log("dashboardData---------------->", dashboardData);

  const handleReportTypeChange = (event) => {
    const type = event.target.value.toLowerCase();
    setSelectedReportType(type);
    setIsCustom(false);
    debugger;
    switch (type) {
      case "daily":
        // setDashboardData(dailyData);
        setDuration("daily");
        break;
      case "monthly":
        // setDashboardData(monthlyData);
        setDuration("monthly");
        break;
      case "yearly":
        // setDashboardData(yearlyData);
        setDuration("yearly");
        break;
      default:
        setModalOpen(true);
        break;
    }
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  console.log("dashboardData---------------->", dashboardData);
  const handleDateChange = (date, field) => {
    if (field === "start") {
      setStartDate(date);
    } else if (field === "end") {
      setEndDate(date);
    }
  };
  const handleShowResult = async () => {
    debugger;
    // setIsCustom(true);
    setDuration("custom");
    setModalOpen(false);
    // const start_date = startDate.toLocaleDateString("en-CA", {
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    // });
    // const end_date = endDate.toLocaleDateString("en-CA", {
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    // });
    // console.log("startdate -  ", start_date);
    // console.log("endadate---", end_date);
    // const data = await getDashboardData(sfId, start_date, end_date);
    // if (isCustom) {
    //   setDashboardData(data.AllReports.customDate_reports);
    //   console.log("custom data-->", dashboardData);
    // }
    // setDailyData(data.AllReports.daily_reports);
    // setMonthlyData(data.AllReports.monthly_reports);
    // setYearlyData(data.AllReports.yearly_reports);
  };
  const usernameBlock = () => {
    if (fbUser)
      return (
        <>
          <h1>{`${fbUser.first_name}${" "}${fbUser.last_name}`}</h1>
        </>
      );
  };

  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-section">
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content custom-date">
                <span className="close" onClick={handleCloseModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                      fill="#242424"
                    />
                  </svg>
                </span>
                <h2>Custom Date</h2>
                <div style={{ gap: "1.5rem", display: "flex" }}>
                  <div>
                    <label>Start Date:</label>
                    <DatePicker
                      selected={startDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => handleDateChange(date, "start")}
                    />
                  </div>
                  <div>
                    <label>End Date:</label>
                    <DatePicker
                      selected={endDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => handleDateChange(date, "end")}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "3rem", textAlign: "center" }}>
                  <button
                    disabled={customShowResult}
                    onClick={handleShowResult}
                    className="green-btn"
                  >
                    Show Results
                  </button>
                </div>
              </div>
            </div>
          )}
          {usernameBlock()}

          <h2 style={{ marginBottom: "1.25rem" }}>Welcome Back</h2>
          <div className="columns-3">
            <select
              className="form-control"
              onChange={handleReportTypeChange}
              value={selectedReportType}
            >
              <option value="daily">Daily Report</option>
              <option value="monthly">Monthly</option>

              <option value="yearly">Yearly</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
              marginTop: "1.5rem",
            }}
          >
            <div className="grey-card">
              <h3>Toatal Plastic (Kg)</h3>
              <h4 className="text-blue">
                {dashboardData?.totalPlastic || "0"}
              </h4>
              {/* <Link
                to={"/HardPlasticCollected"}
                className="details-btn"
                state={{
                  caseList: "",
                  plastic_type: "",
                  total: dashboardData
                    ? dashboardData.totalPlastic
                      ? dashboardData.totalPlastic
                      : 0
                    : 0,
                  filter: "total",
                  duration: duration,
                  user_id: sfId,
                  startDate: startDate,
                  endDate: endDate,
                  user_id: sfId,
                }}
              >
                View Details
              </Link> */}
            </div>

            {/* <div className="grey-card">
                            <h3>Tickets In Progress</h3>
                            <h4 className="text-orange">
                                {dashboardData?.tickets_in_progress || "0"}
                            </h4>
                            <Link
                                to={"/TicketRaised"}
                                className="details-btn"
                                state={{
                                    caseList: dashboardData
                                        ? dashboardData.caseList
                                            ? dashboardData.caseList
                                            : null
                                        : null,
                                    ticketType: "progress",
                                    title:"Tickets In Progress"
                                }}
                            >
                                View Details
                            </Link>
                        </div> */}
            {/* <div className="grey-card">
              <h3>Feedbacks Given</h3>
              <h4 className="text-green">
                {dashboardData?.feedbacks_given || "0"}
              </h4>
              <Link
                to={"/FeedbackGiven"}
                state={{
                  feedbackList: dashboardData
                    ? dashboardData.feedbackList
                      ? dashboardData.feedbackList
                      : null
                    : null,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div> */}
            <div className="grey-card">
              <h3>Hard Plastic Collected (Kg)</h3>
              <h4 className="text-purple">
                {dashboardData?.hardPlasticData || "0"}
              </h4>
              <Link
                to={"/HardPlasticCollected"}
                state={{
                  caseList: "",
                  plastic_type: "Hard",
                  total: dashboardData
                    ? dashboardData.hardPlasticData
                      ? dashboardData.hardPlasticData
                      : 0
                    : 0,
                  filter: "hardplastic",
                  duration: duration,
                  user_id: sfId,
                  startDate: startDate,
                  endDate: endDate,
                  user_id: sfId,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div>
            <div className="grey-card">
              <h3>Soft Plastic Collected (Kg)</h3>
              <h4 className="text-pink">
                {dashboardData?.softPlasticData || "0"}
              </h4>
              <Link
                to={"/HardPlasticCollected"}
                state={{
                  caseList: "",
                  plastic_type: "Soft",
                  total: dashboardData
                    ? dashboardData.softPlasticData
                      ? dashboardData.softPlasticData
                      : 0
                    : 0,
                  filter: "softplastic",
                  duration: duration,
                  user_id: sfId,
                  startDate: startDate,
                  endDate: endDate,
                  user_id: sfId,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div>
            {orgType
              ? orgType.toLowerCase() === "school" && (
                  <div className="grey-card">
                    <h3>Surveys Taken</h3>
                    <h4 className="text-green">
                      {dashboardData
                        ? dashboardData.survey_Taken
                          ? dashboardData.survey_Taken
                          : 0
                        : 0}
                    </h4>
                    <Link
                      to={"/SurveyTaken"}
                      state={{
                        filter: "survey",
                        startDate: startDate,
                        endDate: endDate,
                        duration: duration,
                        user_id: sfId,
                      }}
                      className="details-btn"
                    >
                      View Details
                    </Link>
                  </div>
                )
              : null}
            <div className="lg-grey-card">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h4>Collection Completed</h4>
                  <Link
                    to={"/TicketRaised"}
                    className="details-btn"
                    state={{
                      caseList: "",
                      ticketType: "closed",
                      title: "Collection Completed",
                      filter: "completedcollection",
                      startDate: startDate,
                      endDate: endDate,
                      duration: duration,
                      user_id: sfId,
                    }}
                  >
                    View Details
                  </Link>
                </div>
                <div style={{ paddingTop: "0.5rem" }}>
                  <div style={{ width: 160, height: 160 }}>
                    {/* https://codesandbox.io/p/sandbox/progressbar-fontweight-forked-7t7qdp?file=%2Fsrc%2FApp.js%3A28%2C11-35%2C12 */}
                    <CircularProgressbar
                      styles={{
                        text: {
                          fontSize: "1rem",
                          fontWeight: 500,
                        },
                        path: {
                          stroke: "#009E8C",
                        },
                      }}
                      value={
                        dashboardData
                          ? dashboardData.solved_percentage
                            ? dashboardData.solved_percentage
                            : 0
                          : 0
                      }
                      text={`${
                        dashboardData
                          ? dashboardData.completedCollections
                            ? dashboardData.completedCollections
                            : 0
                          : 0
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grey-card">
              <h3>Collection Requests</h3>
              <h4 className="text-blue">
                {dashboardData?.collectionRequests || "0"}
              </h4>
              <Link
                to={"/TicketRaised"}
                className="details-btn"
                state={{
                  caseList: "",
                  ticketType: "raised",
                  title: "Collection Request",
                  filter: "request",
                  startDate: startDate,
                  endDate: endDate,
                  duration: duration,
                  user_id: sfId,
                }}
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          <LeaderboardScore />

          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            <ProfileCard profile={fbUser} />
          </div>
          {/* <Notification data={notifications}/> */}
          {/* {process.env.REACT_APP_DOWNLOAD_URL && (
            <DownloadOurApp
              downloadUrl={process.env.REACT_APP_DOWNLOAD_URL}
              appTitle={"BFC"}
              appDescription={"Android Application"}
              imageUrl={FAVICON}
            />
          )} */}
          <DownloadOurApp
            downloadUrl={process.env.REACT_APP_DOWNLOAD_URL}
            appTitle={"BFC"}
            appDescription={"Mobile Application"}
            imageUrl={FAVICON}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
