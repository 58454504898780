import React from "react";
import {CirclesWithBar} from "react-loader-spinner";

export const LoaderLarge = ({ type }) => (
    <div className="loader">
        <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
        />
    </div>
);

export const LoaderSmall = ({ type }) => (
    <div className="loader">
        <CirclesWithBar
            height="30"
            width="30"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
        />
    </div>
);

export const BannerLoader = ({ type }) => (
    <div className="loader">
        <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
        />
    </div>
);
