import axios from "axios";

export const updatePassword = async (userId, newPassword) => {
  try {
    const response = await axios.post(
      "https://updateuserpassword-padxowc6aq-uc.a.run.app",
      {
        userId: userId,
        newPassword: newPassword,
      }
    );
    console.log("Response:", response.data);
    return response.data; // Handling the response data as needed
  } catch (error) {
    console.error(
      "Error making POST request:",
      error.response ? error.response.data : error.message
    );
    return null; // Handling error scenario
  }
};
