import React from "react";
import { Link } from "react-router-dom";
import { FaBars, FaDownload, FaTimes, FaCaretDown } from "react-icons/fa";
import "react-multi-carousel/lib/styles.css";
import "./Partnership.css";
const {
  WHITE_LOGO,
  RECYCLE_PROCESS,
  PLAY_STORE,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  PARISAR_VIKAS,
  SAMPURNA_EARTH,
  DALMIA,
} = require("../../utils/imagepath");

export default function Partnership() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="partnership">
      <div className="menu-section">
        <ul>
          <li>
            <Link to="/about-pet">Truth about Plastic Recycling</Link>
          </li>
          <li>
            <Link to="/about-bfc">About BOTTLES FOR CHANGE</Link>
          </li>
          <li className="dropdown">
            <Link href="#">
              Join the Initiative
              <FaCaretDown
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
              />
            </Link>
            <ul>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/PartnershipPower">Partnership is Power</Link>
          </li>
          <li>
            <Link to="/milestone-initative">Milestones & Initiatives</Link>
          </li>
        </ul>
      </div>
      <div className="banner">
        <div className="main-header">
          <Link className="logo" to="/home">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn">Login</Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/home">
            <FaTimes className="hamburger" />
          </Link>
        </div>
        <h1>PARTNERSHIP IS POWER</h1>
      </div>
      <div className="common-description">
        <div className="wrapper">
          <h1>
            WE CAN WORK TOWARDS MAKING
            <br /> THIS ENVIRONMENT CLEANER, TOGETHER.
          </h1>
          <p>
            No programme can become successful until there are right partners on
            board. For this initiative, Bisleri has partnered with
            Non-Governmental Organizations who work across Mumbai towards
            changing behavior of the communities in valuing plastic as an
            effective resource that has an afterlife. Together with the NGOs and
            Recyclers, the programme conducts plastic collection and educational
            drives for Communities, Corporates, Schools, Colleges and other
            institutions pushing for a habitual change.
          </p>
          <h2 style={{ marginTop: "3.5rem" }}>OUR PARTNERS ON THIS MISSION</h2>
          <p>
            Bisleri International has partnered with a Non-Governmental
            Organization in Mumbai – Parisar Bhagini Vikas Sangh (PBVS) and a
            social enterprise Sampurna Earth. We have Dalmia Polypro Industries
            Ltd. as our official Recycling Partner.
          </p>
          <div className="flex flex-row" style={{ marginTop: "4rem" }}>
            <div className="flex-1">
              <img src={PARISAR_VIKAS} alt="" />
            </div>
            <div className="flex-1" style={{ textAlign: "center" }}>
              <img
                src={SAMPURNA_EARTH}
                alt=""
                style={{ display: "inline-block" }}
              />
            </div>
            <div className="flex-1" style={{ textAlign: "end" }}>
              <img src={DALMIA} alt="" style={{ display: "inline-block" }} />
            </div>
          </div>
          <h2 style={{ marginTop: "3.5rem" }}>BECOME A PATNER</h2>
          <p>
            NGO and other partners play a very important role in spreading the
            message on <strong>BOTTLES FOR CHANGE</strong>. From engaging and
            enrolling partners for the programme, they also help in spreading
            awareness about the advantages of disposing plastic correctly and
            recycling it for better use.
          </p>
          <p>
            They help in the collection of plastic from various partner sources
            like housing societies, schools, colleges, corporates and
            restaurants. By being part of this programme, they are helping us
            lead the nation towards a cleaner and greener tomorrow and providing
            a better channel and employment for plastic agents (kabadiwala).
          </p>
        </div>
      </div>
      <div className="recyle-process">
        <h3>HELP US RECYCLE PLASTIC RESPONSIBLY</h3>
        <div className="grey-block">
          <img src={RECYCLE_PROCESS} alt="" />
        </div>
      </div>
      <div className="join-initiative">
        <h4>JOIN THE INITIATIVE AS NGO / SOCIAL ENTERPRISE</h4>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Name of the Entity</label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Entity"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>How are you affiliated</label>
            <select className="form-control">
              <option>Select</option>
              <option>NGO</option>
              <option>Social Enterprise</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Name of the Key Contact Person</label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Key Contact Person"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>Mobile Number</label>
            <input
              type="text"
              value=""
              placeholder="Enter Mobile Number"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Alternate Number</label>
            <input
              type="text"
              value=""
              placeholder="Enter Alternate Number"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Pincode<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Pincode"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Email Id</label>
            <input
              type="text"
              value=""
              placeholder="Enter Email Id"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Address<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Address"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              State<span>*</span>
            </label>
            <select className="form-control">
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Delhi">Delhi</option>
              <option value="Dadra and Nagar Haveli and Daman &amp; Diu">
                Dadra and Nagar Haveli and Daman &amp; Diu
              </option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
            </select>
          </div>
          <div className="flex-1">
            <label>
              City<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter City"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              <input type="checkbox" style={{ marginRight: 5 }} />I agree to{" "}
              <Link className="link">Terms & Conditions</Link>
              <span>*</span>
            </label>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1" style={{ textAlign: "center" }}>
            <Link className="discover-btn">Submit</Link>
          </div>
        </div>
      </div>
      <div className="join-initiative">
        <h4>JOIN THE INITIATIVE AS RECYCLER / AGGREGATOR</h4>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Name of the Entity</label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Entity"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>How are you affiliated</label>
            <select className="form-control">
              <option>Select</option>
              <option>NGO</option>
              <option>Social Enterprise</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Name of the Key Contact Person</label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Key Contact Person"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>Mobile Number</label>
            <input
              type="text"
              value=""
              placeholder="Enter Mobile Number"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Alternate Number</label>
            <input
              type="text"
              value=""
              placeholder="Enter Alternate Number"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Pincode<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Pincode"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Email Id</label>
            <input
              type="text"
              value=""
              placeholder="Enter Email Id"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Address<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Address"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              State<span>*</span>
            </label>
            <select className="form-control">
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Delhi">Delhi</option>
              <option value="Dadra and Nagar Haveli and Daman &amp; Diu">
                Dadra and Nagar Haveli and Daman &amp; Diu
              </option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
            </select>
          </div>
          <div className="flex-1">
            <label>
              City<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter City"
              className="form-control"
            />
          </div>
        </div>

        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              <input type="checkbox" style={{ marginRight: 5 }} />I agree to{" "}
              <Link className="link">Terms & Conditions</Link>
              <span>*</span>
            </label>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1" style={{ textAlign: "center" }}>
            <Link className="discover-btn">Submit</Link>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="app-section">
          <div className="app-img">
            <img src={PLAY_STORE} alt="" />
          </div>
          <div className="app-text">
            <h5>DOWNLOAD BOTTLES FOR CHANGE APP NOW</h5>
            <a href="#" className="download-btn">
              <FaDownload className="ico" /> Download the App
            </a>
            <p>
              With this app you can locate the nearest plastic agent
              (kabadiwala) in your area and put segregated plastic in the right
              channel. Segregated plastic will be picked by our plastic agent
              and then sent for recycling.
            </p>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="flex flex-row">
          <div className="flex-1">
            <ul>
              <li>
                <Link>Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link>
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link>Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link>Housing Society/Residential Building</Link>
              </li>
              <li>
                <Link>Corporate/Hotel</Link>
              </li>
              <li>
                <Link>School/College</Link>
              </li>
              <li>
                <Link>Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div className="flex-1"></div>
          <div className="flex-1">
            <Link className="social-icon">
              <img src={FACEBOOK} alt="" className="ico" />
            </Link>
            <Link className="social-icon">
              <img src={TWITTER} alt="" className="ico" />
            </Link>
            <Link className="social-icon">
              <img src={INSTAGRAM} alt="" className="ico" />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link>Terms & Conditions</Link>
          </li>
          <li>
            <Link>Privacy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
