import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaCaretDown, FaDownload } from "react-icons/fa";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import "./AboutBFC.css";
import DownloadAppSection from "../../components/MobileApps/DownloadAppSection";
import Footer from "../Footer/Footer";
import FooterBottom from "../FooterBottom/FooterBottom";
const {
  WHITE_LOGO,
  CIRCLE1,
  CIRCLE2,
  CIRCLE3,
  CIRCLE4,
  LEAF_LET,
  INVOLVED_ICON,
  PLAY_STORE,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
} = require("../../utils/imagepath");

export default function AboutBfc() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="about-bfc">
      <div className="banner">
        <div className="main-header">
          <Link className="logo">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn" to="/login">
            Login/Signup
          </Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/Partnership">
            <FaBars className="hamburger" />
          </Link>
        </div>
        <h1>BOTTLES FOR CHANGE</h1>
      </div>
      <div className="common-description">
        <div className="wrapper">
          <h1>
            WHAT IS THE
            <br /> BOTTLES FOR CHANGE PROGRAMME?
          </h1>
          <p>
            Bottles for Change aims to create awareness among citizens about
            importance of plastic recycling. When plastic is thrown in waste, it
            ends up in sea, ocean, drains, river etc. and causes pollution, so
            don’t treat plastic as waste, it has value even after use, just
            clean it, collect it and send it directly for recycling.
          </p>
          <p>
            Through this initiative we ensure channelizing all kinds of used
            clean plastic for recycling thus ensuring the greener cleaner
            environment.
          </p>
          <p>
            Bisleri has engaged with a NGO called Parisar Bhagni Vikas Sangh
            (PBVS), a social enterprise Sampurna Earth in Mumbai and recycler
            Dalmia Polypro Industries Ltd., which are associated with the
            plastic agents/kabadiwala groups in Mumbai. The plastic collected by
            us are crushed into fine flakes which are then used to create
            non-edible products such as - Cloth fabric, Hand bags, Window blinds
            and other useful products.
          </p>
          <h2>WHAT DOES BOTTLES FOR CHANGE DO?</h2>
        </div>
      </div>
      <div
        className="step"
        data-aos="fade-left"
        data-aos-duration="700"
        data-aos-delay="100"
      >
        <div className="left-col order-2">
          <h3>01</h3>
          <p>
            Educating citizens & bringing awareness about a habitual change in
            the plastic disposing ways.
          </p>
          <div className="bfc-line"></div>
        </div>
        <div className="right-col order-1">
          <img src={CIRCLE1} alt="" className="w-1/2 m-auto md:w-auto" />
        </div>
      </div>
      <div
        className="step"
        data-aos="fade-right"
        data-aos-duration="700"
        data-aos-delay="200"
      >
        <div className="right-col">
          <img src={CIRCLE2} alt="" className="w-1/2 m-auto md:w-auto" />
        </div>
        <div className="left-col">
          <h3>02</h3>
          <p>
            Creating a channel and opportunity for plastic agents to collect
            used but clean plastic (hard as well as soft) through various
            stakeholders.
          </p>
          <div className="bfc-line"></div>
        </div>
      </div>
      <div
        className="step"
        data-aos="fade-left"
        data-aos-duration="700"
        data-aos-delay="200"
      >
        <div className="left-col order-2">
          <h3>03</h3>
          <p>
            Sorting clean plastic as per their types received from user, at the
            segregation center and directly sending it for recycling.
          </p>
          <div className="bfc-line"></div>
        </div>
        <div className="right-col order-1">
          <img src={CIRCLE3} alt="" className="w-1/2 m-auto md:w-auto" />
        </div>
      </div>
      <div
        className="step"
        data-aos="fade-right"
        data-aos-duration="700"
        data-aos-delay="200"
      >
        <div className="right-col">
          <img src={CIRCLE4} alt="" className="w-1/2 m-auto md:w-auto" />
        </div>
        <div className="left-col">
          <h3>04</h3>
          <p>
            Providing plastic agents hygienic working conditions, and a
            respectable life eventually uplifting their economic status.
          </p>
          <div className="bfc-line"></div>
        </div>
      </div>
      <div className="wyc-section">
        <h3>HERE'S WHAT YOU CAN DO</h3>
        <img src={LEAF_LET} alt="" />
      </div>
      <div className="video-section">
        <div data-aos="fade-right" data-aos-duration="700">
          <iframe
            className="video-iframe"
            src="https://www.youtube.com/embed/xuiDuYLyBms"
            allow="accelerometer; autoplay; encrypted-media; allowfullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <div className="vcx">
          <div className="ico">
            <img src={INVOLVED_ICON} alt="" />
          </div>
          <div className="content">
            <h2>IT BEGINS WITH YOU. GET INVOLVED.</h2>
            <p>
              Show your support & take a pledge to dispose of the plastic in the
              right manner.
            </p>
            <Link class="discover-btn">Take the Pledge!</Link>
          </div>
        </div>
      </div>
      <div className="common-description">
        <div className="wrapper">
          <h2>BE THE CHANGE YOU WANT TO SEE</h2>
          <p>
            Lead by example and Join the <strong>BOTTLES FOR CHANGE</strong>{" "}
            initiative
            <br /> to dispose and recycle plastic responsibly and contribute to
            a cleaner environment.
          </p>
          <p style={{ color: "#000", textDecoration: "underline" }}>
            JOIN THE INITIATIVE :
          </p>
          <div className="initiative-section">
            <Link class="discover-btn" to="/community">
              Housing Society
            </Link>
            <Link class="discover-btn" to="/corporate">
              Corporates & Hotel
            </Link>
            <Link class="discover-btn" to="/school-collage">
              School & College
            </Link>
          </div>
        </div>
      </div>
      <DownloadAppSection />
      {/* <div className="wrapper">
        <div className="app-section">
          <div className="app-img">
            <img src={PLAY_STORE} alt="" />
          </div>
          <div className="app-text">
            <h5>DOWNLOAD BOTTLES FOR CHANGE APP NOW</h5>
            <a
              href="https://play.google.com/store/apps/details?id=com.bisleriapp"
              target="_blank"
              className="download-btn"
            >
              <FaDownload className="ico" /> Download the App
            </a>
            <p>
              With this app you can locate the nearest plastic agent
              (kabadiwala) in your area and put segregated plastic in the right
              channel. Segregated plastic will be picked by our plastic agent
              and then sent for recycling.
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className="footer">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="mb-4 md:mb-0">
            <ul>
              <li>
                <Link to="/about-pet">Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link to="/about-bfc">
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link to="/milestone-initative">Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
              <li>
                <Link to="/PartnershipPower">Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <a
              className="social-icon"
              href="https://www.facebook.com/bottles4change/"
              target="_blank"
            >
              <img src={FACEBOOK} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
              target="_blank"
            >
              <img src={TWITTER} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/bottles_4_change/?hl=en"
              target="_blank"
            >
              <img src={INSTAGRAM} alt="" className="ico" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link>Terms & Conditions</Link>
          </li>
          <li>
            <Link>Privacy</Link>
          </li>
        </ul>
      </div> */}
      <Footer />
      <FooterBottom />
    </div>
  );
}
