import React from "react";
import { APPSTORE_BUTTON, PLAYSTORE_BUTTON } from "../../utils/imagepath";
const DownloadOurApp = ({
  downloadUrl,
  appTitle,
  appDescription,
  imageUrl,
}) => {
  return (
    <div className="mt-5 max-w-sm rounded overflow-hidden shadow-lg bg-white pb-4">
      <img className="w-full" src={imageUrl} alt={`Download ${appTitle}`} />
      <div className="px-6 py-2">
        <div className="font-bold text-l mb-2">Bottles For Change - BFC</div>
        <p className="text-gray-700 text-base">{appDescription}</p>
      </div>
      <div className="px-6 pt-1 pb-1 ">
        <a
          href="https://apps.apple.com/in/app/bottles-for-change/id6474071809"
          target="_blank"
        >
          <img src={APPSTORE_BUTTON} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.bisleriapp"
          target="_blank"
          style={{ marginTop: ".5rem", display: "block" }}
        >
          <img src={PLAYSTORE_BUTTON} />
        </a>
        {/* <a
                    href={downloadUrl}
                    className="green-btn hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
                    target="_blank"
                >
                    Download
                </a> */}
      </div>
    </div>
  );
};

export default DownloadOurApp;
