import { Link } from 'react-router-dom'
import './ProfileCard.css'
const { PERSON } = require("../../utils/imagepath");

export default function ProfileCard({profile}) {
    
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const profileBlock = () => {
        if(profile) {
            return (
                <div className="profile-card">
                    <img
                        src={PERSON}
                        alt="Bottles for Change"
                        className="avatar"
                    />
                    {`${profile.first_name}${" "}${profile.last_name}`}
                    <div
                        className="email"
                        title={profile.email}
                        style={{
                            marginTop: "10px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {profile.email}
                    </div>
                    <div style={{ marginBottom: "1rem", marginTop: "0.5rem" }}>
                        <span className="profile-type">
                            {capitalizeFirstLetter(profile.user_type)}
                        </span>
                    </div>
                    <Link to="/RequesterProfile" className="green-btn">
                        View Profile
                    </Link>
                </div>
            );
        }else {
            return null;
        }
    }
    return (
        profileBlock()
    )
}