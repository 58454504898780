import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBTBSuyaKc1RcEDdDxD66uSNFlomItWbkk",
    authDomain: "bisleri-320a2.firebaseapp.com",
    projectId: "bisleri-320a2",
    storageBucket: "bisleri-320a2.appspot.com",
    messagingSenderId: "207369623210",
    appId: "1:207369623210:web:0f3750042c2293d17862d3",
    measurementId: "G-8ZL56W5EWL"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });
//firebase.analytics();
const storage = firebase.storage();
export { storage, firebase };