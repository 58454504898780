import React from "react";
import { Navigate } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

/* const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useSelector((state) => state.firebase.auth);
    
    return (
        <Route
        {...rest}
        render={(props) =>
            isLoaded(auth) && !isEmpty(auth) ? (
            <Component {...props} />
            ) : (
            <Navigate
                to={{
                pathname: "/login",
                state: { from: props.location },
                }}
            />
            )
        }
        />
    );
}; */

const PrivateRoute = ({ children }) => {
    const auth = useSelector((state) => state.firebase.auth);

    if (!isLoaded(auth)) {
        // Loading screen or similar logic
        return <div>Loading...</div>;
    }

    if (isEmpty(auth)) {
        // Redirect to login if not authenticated
        return <Navigate to="/login" />;
    } else {
        // Render the passed children (component) if authenticated
        return children;
    }
};

export default PrivateRoute;