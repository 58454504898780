import { Link } from 'react-router-dom'
import './Notification.css'
import { useState } from 'react';

export default function Notification(props) {
    const {data}=props;
    const [Notifications,setNotifications]=useState([]);
    const [selectedItems,setSelectedItems]=useState([]);
    debugger
    const broadcast=data?data.FinalNotification?data.FinalNotification.broadcast?data.FinalNotification.broadcast:[]:[]:[];
    const selectedItemsA = broadcast.length>0?broadcast.slice(0, 2):null;
    if(selectedItemsA){
        setSelectedItems(selectedItemsA);
    }
    
    console.log('broadcast det --- ',broadcast);
    // if(broadcast){
    //     setNotifications(broadcast)
    // }
    console.log('notifictaions data',data); 
    return (
        <div className="notification-section">
            <h1>
                Notifications
                <div className='count'>
                    {broadcast.length>0?broadcast.length:0}
                    </div>
            </h1>
            <ul>
                {selectedItems?selectedItems.map((item,key)=>(
                <li>
                    <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M5.375 8.00098C5.58211 8.00098 5.75 7.83308 5.75 7.62598C5.75 7.41887 5.58211 7.25098 5.375 7.25098C5.16789 7.25098 5 7.41887 5 7.62598C5 7.83308 5.16789 8.00098 5.375 8.00098ZM5.75 10.626C5.75 10.8331 5.58211 11.001 5.375 11.001C5.16789 11.001 5 10.8331 5 10.626C5 10.4189 5.16789 10.251 5.375 10.251C5.58211 10.251 5.75 10.4189 5.75 10.626ZM2.75 5.00098C2.75 3.75834 3.75736 2.75098 5 2.75098H11C12.2426 2.75098 13.25 3.75834 13.25 5.00098V11.001C13.25 12.2436 12.2426 13.251 11 13.251H5C3.75736 13.251 2.75 12.2436 2.75 11.001V5.00098ZM4.625 4.25098C4.41789 4.25098 4.25 4.41887 4.25 4.62598C4.25 4.83308 4.41789 5.00098 4.625 5.00098H11.375C11.5821 5.00098 11.75 4.83308 11.75 4.62598C11.75 4.41887 11.5821 4.25098 11.375 4.25098H4.625ZM5.375 8.75098C5.99632 8.75098 6.5 8.2473 6.5 7.62598C6.5 7.00466 5.99632 6.50098 5.375 6.50098C4.75368 6.50098 4.25 7.00466 4.25 7.62598C4.25 8.2473 4.75368 8.75098 5.375 8.75098ZM6.5 10.626C6.5 10.0047 5.99632 9.50098 5.375 9.50098C4.75368 9.50098 4.25 10.0047 4.25 10.626C4.25 11.2473 4.75368 11.751 5.375 11.751C5.99632 11.751 6.5 11.2473 6.5 10.626ZM7.25 7.62598C7.25 7.83308 7.41789 8.00098 7.625 8.00098H11.375C11.5821 8.00098 11.75 7.83308 11.75 7.62598C11.75 7.41887 11.5821 7.25098 11.375 7.25098H7.625C7.41789 7.25098 7.25 7.41887 7.25 7.62598ZM7.625 10.251C7.41789 10.251 7.25 10.4189 7.25 10.626C7.25 10.8331 7.41789 11.001 7.625 11.001H11.375C11.5821 11.001 11.75 10.8331 11.75 10.626C11.75 10.4189 11.5821 10.251 11.375 10.251H7.625Z" fill="#009E8C"/>
                        </svg>      
                        Plastic Collection Survey
                    </h2>
                    <p>
                        We value your input! We've created a short survey to gather insights on plastic collection in Mumbai.
                    </p>
                    <Link to='/' className="green-btn">
                        Start Survey
                    </Link>
                </li>
                )):null}
                {/* <li>
                    <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M5.375 8.00098C5.58211 8.00098 5.75 7.83308 5.75 7.62598C5.75 7.41887 5.58211 7.25098 5.375 7.25098C5.16789 7.25098 5 7.41887 5 7.62598C5 7.83308 5.16789 8.00098 5.375 8.00098ZM5.75 10.626C5.75 10.8331 5.58211 11.001 5.375 11.001C5.16789 11.001 5 10.8331 5 10.626C5 10.4189 5.16789 10.251 5.375 10.251C5.58211 10.251 5.75 10.4189 5.75 10.626ZM2.75 5.00098C2.75 3.75834 3.75736 2.75098 5 2.75098H11C12.2426 2.75098 13.25 3.75834 13.25 5.00098V11.001C13.25 12.2436 12.2426 13.251 11 13.251H5C3.75736 13.251 2.75 12.2436 2.75 11.001V5.00098ZM4.625 4.25098C4.41789 4.25098 4.25 4.41887 4.25 4.62598C4.25 4.83308 4.41789 5.00098 4.625 5.00098H11.375C11.5821 5.00098 11.75 4.83308 11.75 4.62598C11.75 4.41887 11.5821 4.25098 11.375 4.25098H4.625ZM5.375 8.75098C5.99632 8.75098 6.5 8.2473 6.5 7.62598C6.5 7.00466 5.99632 6.50098 5.375 6.50098C4.75368 6.50098 4.25 7.00466 4.25 7.62598C4.25 8.2473 4.75368 8.75098 5.375 8.75098ZM6.5 10.626C6.5 10.0047 5.99632 9.50098 5.375 9.50098C4.75368 9.50098 4.25 10.0047 4.25 10.626C4.25 11.2473 4.75368 11.751 5.375 11.751C5.99632 11.751 6.5 11.2473 6.5 10.626ZM7.25 7.62598C7.25 7.83308 7.41789 8.00098 7.625 8.00098H11.375C11.5821 8.00098 11.75 7.83308 11.75 7.62598C11.75 7.41887 11.5821 7.25098 11.375 7.25098H7.625C7.41789 7.25098 7.25 7.41887 7.25 7.62598ZM7.625 10.251C7.41789 10.251 7.25 10.4189 7.25 10.626C7.25 10.8331 7.41789 11.001 7.625 11.001H11.375C11.5821 11.001 11.75 10.8331 11.75 10.626C11.75 10.4189 11.5821 10.251 11.375 10.251H7.625Z" fill="#009E8C"/>
                        </svg>      
                        Plastic Collection Survey
                    </h2>
                    <p>
                        We value your input! We've created a short survey to gather insights on plastic collection in Mumbai.
                    </p>
                    <Link to='/' className="green-btn">
                        Start Survey
                    </Link>
                </li> */}
                <Link to='/AllSurvey' className="ordered-btn" style={{ marginTop:'1rem'}}>
                    View all
                </Link>
            </ul>
        </div>
    )
}