import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {firebase} from './config/fb_config';
import { isLoaded } from "react-redux-firebase";
import { thunk, withExtraArgument } from "redux-thunk";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import rootReducer from "./store/reducers/rootReducer";
import { createStore, applyMiddleware, compose } from 'redux';
import { createFirestoreInstance, getFirestore } from "redux-firestore";
import { BannerLoader } from "./components/ui_utilities/Loaders";
import { useSelector } from "react-redux";
import { Provider } from 'react-redux';


const middlewares = [withExtraArgument({ getFirebase, getFirestore })];

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares))
);

const rrfProps = {
  firebase,
  config: {
    userProfile: "user",
    useFirestoreForProfile: true,
    attachAuthIsReady: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};


const AuthIsLoaded = ({ children }) => {
    const auth = useSelector((state) => state.firebase.auth);
    const profile = useSelector((state) => state.firebase.profile);
    const waitForSignup = useSelector((state) => state.auth.waitForSignup); // Add this line
    console.log(profile);
    console.log(isLoaded(profile));
    if ((!isLoaded(auth) || !isLoaded(profile)) && !waitForSignup) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-200">
                <div className="text-3xl">
                    {/* <h5>loading...</h5> */}
                    <BannerLoader type="BallTriangle" />
                </div>
            </div>
        );
    }
    return children;
}


/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <App />
        </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>
); */

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>
                <App />
            </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById("root")
);