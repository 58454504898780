// DownloadAppSection.js
import React from "react";
import { FaApple } from "react-icons/fa";
import { BiLogoPlayStore } from "react-icons/bi";
//import PropTypes from "prop-types";
const { PLAY_STORE } = require("../../utils/imagepath");

const DownloadAppSection = () => {
  return (
    <div className="wrapper">
      <div className="app-section">
        <div className="app-text">
          <h5>DOWNLOAD BOTTLES FOR CHANGE APP NOW</h5>
          <a
            href="https://play.google.com/store/apps/details?id=com.bisleriapp"
            target="_blank"
            rel="noopener noreferrer"
            className="download-btn"
          >
            <BiLogoPlayStore className="ico" /> Get It on Google play
          </a>
          <a
            href="https://apps.apple.com/in/app/bottles-for-change/id6474071809"
            target="_blank"
            rel="noopener noreferrer"
            className="download-btn"
          >
            <FaApple className="ico" /> Download on the App Store
          </a>
          <p>
            With this app you can locate the nearest plastic agent (kabadiwala)
            in your area and put segregated plastic in the right channel.
            Segregated plastic will be picked by our plastic agent and then sent
            for recycling.
          </p>
        </div>
      </div>
    </div>
  );
};

// DownloadAppSection.propTypes = {
//   imgSrc: PropTypes.string.isRequired,
//   downloadLink: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
// };

export default DownloadAppSection;
