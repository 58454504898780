import React from "react";
import { Link } from "react-router-dom";
import { FaBars, FaDownload, FaCaretDown } from "react-icons/fa";
import "./PrivacyPolicy.css";
const {
  WHITE_LOGO,
  RECYCLE_PROCESS,
  PLAY_STORE,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  PARISAR_VIKAS,
  SAMPURNA_EARTH,
  DALMIA,
} = require("../../utils/imagepath");

export default function PrivacyPolicy() {
  return (
    <>
      <div className="privacy-policy">
        <div className="banner">
          <div className="main-header">
            <Link className="logo">
              <img src={WHITE_LOGO} alt="" />
            </Link>
            <Link className="login-btn" to="/login">
              Login/Signup
            </Link>
            <span className="has-tag">#BottlesForChange</span>
            <Link to="/Partnership">
              <FaBars className="hamburger" />
            </Link>
          </div>
        </div>
        <h1 className="text-center">PRIVACY POLICY</h1>
        <p>
          Bisleri International is committed to protecting any personal
          information that you may provide to us. In particular, we believe it
          is important for you to know how we treat information about you that
          we may receive from this website “www.bottlesforchange.in” (“Site”).
          In general, you can visit this site without telling us who you are or
          revealing any information about yourself. Our web servers collect the
          domain names, not the e-mail addresses of visitors. There are portions
          of this site where we may need to collect personal information from
          you for a specific purpose. The information collected from you may
          include your name, address, telephone, fax number or e-mail address
          etc. This Privacy Policy is applicable to any personal information,
          which is given by you to us (“User Information”) via this site and is
          devised to help you feel more confident about the privacy and security
          of your personal details. When you leave your contact details please
          note that we are not bound to reply. This site is not intended for
          persons under 18 years of age. We do not knowingly solicit or collect
          personal information from or about children, and we do not knowingly
          market our products or services to children. “You” shall mean you, the
          user of the site and “Yourself” interpreted accordingly. “We” / “Us”
          means Bisleri International and “Our” interpreted accordingly. “Users”
          means the users of the site collectively and/or individually as the
          context allows.
        </p>
        <h2>DATA COLLECTION</h2>
        <p>
          When using the site you may be asked to enter User Information. Such
          User Information will only be used for the purposes for which it was
          collected, for any other purposes specified at the collection point
          and in accordance with this Privacy Policy.
        </p>
        <h2>HOW WE MAY USE YOUR DATA</h2>
        <p>
          By entering Your User Information, You accept that we may retain Your
          User Information and that it may be held by us or any third party
          company which processes it on our behalf. We shall be entitled to Use
          your User Information for the following purposes:
        </p>
        <ul>
          <li>Call the user for the enquiry submitted</li>
          <li>
            Market research, including statistical analysis of User behaviour
            which we may disclose to third parties in depersonalised, aggregated
            form.
          </li>
          <li>
            In order to enable us to comply with any requirements imposed on us
            by law.
          </li>
          <li>
            In order to send you periodic communications (this may include
            e-mail, SMS etc.), about features, products and services, events and
            special offers.
          </li>
        </ul>
        <p>
          Such communications from us may include advertising for organisations.
          For Your convenience you can withdraw consent by replying to the
          "unsubscribe" link in e-mails from us. Please also note that we do not
          disclose your personal information to third parties to enable them to
          send you direct marketing without your permission to do so.
        </p>
        <h2>COOKIES</h2>
        <p>
          You should be aware that information and data may be automatically
          collected through the Use of Cookies. "Cookies" are small text files
          that store basic information that a Web site can use to recognize
          repeat site visits and as an example, recall Your name if this has
          been previously supplied. We may use this to observe behaviour and
          compile aggregate data in order to improve the Site, target the
          advertising and assess general effectiveness of such advertising.
          Cookies do not attach to your system and damage your files. If you do
          not want information collected through the Use of Cookies, there is a
          simple procedure in most browsers that allows you to deny or accept
          the Cookie feature. Note, however, that "personalised" services may be
          affected if the cookie option is disabled.
        </p>
        <h2>SECURITY AND DATA RETENTION</h2>
        <p>
          Security is very important to us. All security procedures are in place
          to protect the confidentiality, integrity and availability of your
          User information. We maintain strict physical, electronic, and
          administrative safeguards to protect your personal information from
          unauthorized or inappropriate access or disclosure or unauthorised
          modification. To prevent unauthorised access, we have put in place the
          latest industry-standard security technology and procedures to
          safeguard the information we collect online. We restrict access to
          information about you to those of our employees who need to know the
          information to respond to your inquiry or request. Employees who
          misuse personal information are subject to disciplinary action. We
          will comply with all relevant Data Protection legislation in relation
          to the period for which we retain any information you disclose to us.
          Even though we have taken significant steps to protect your personally
          identifiable information, no company, including our Website, can fully
          eliminate security risks associated with Personal Information.
        </p>
        <h2>GRIEVANCE</h2>
        <p>
          If any Grievance, address details is mentioned below: Bisleri
          International, Western Express Highway, Andheri (East), Mumbai – 400
          099.
        </p>
        <h2>UPDATES</h2>
        <p>
          We may change and/or make updates to this privacy policy from time to
          time and you should check these regularly. Your use of the Bottles for
          Change website will be deemed as an acceptance of the privacy policy
          existing at that time. Any site that you may connect to from here is
          not covered by this Privacy Policy.
        </p>
      </div>
      <div className="footer">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="mb-4 md:mb-0">
            <ul>
              <li>
                <Link to="/about-pet">Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link to="/about-bfc">
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link to="/milestone-initative">Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
              <li>
                <Link to="/PartnershipPower">Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <a
              className="social-icon"
              href="https://www.facebook.com/bottles4change/"
              target="_blank"
            >
              <img src={FACEBOOK} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
              target="_blank"
            >
              <img src={TWITTER} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/bottles_4_change/?hl=en"
              target="_blank"
            >
              <img src={INSTAGRAM} alt="" className="ico" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div>
    </>
  );
}
