import './NotFoundComponent.css'

function NotFoundComponent() {
    return (
      <div className="not-found">
        <svg xmlns="http://www.w3.org/2000/svg" width="218" height="160" viewBox="0 0 218 160" fill="none">
          <path d="M133.288 46.5449H123.121V50.8409H133.288V46.5449Z" fill="#EBEBEB"/>
          <path d="M127.616 51.9629H117.449V56.2589H127.616V51.9629Z" fill="#EBEBEB"/>
          <path d="M138.96 51.9629H128.793V56.2589H138.96V51.9629Z" fill="#EBEBEB"/>
          <path d="M175.46 87.2441H165.293V91.5401H175.46V87.2441Z" fill="#EBEBEB"/>
          <path d="M175.46 5.83203H165.293V10.128H175.46V5.83203Z" fill="#EBEBEB"/>
          <path d="M181.132 11.25H170.965V15.546H181.132V11.25Z" fill="#EBEBEB"/>
          <path d="M164.817 31.668H96.6562V34.657H164.817V31.668Z" fill="#EBEBEB"/>
          <path d="M103.467 34.6582H100.477V44.5442H103.467V34.6582Z" fill="#EBEBEB"/>
          <path d="M103.467 34.6582H100.477V37.7142H103.467V34.6582Z" fill="#DBDBDB"/>
          <path d="M160.998 34.6582H158.008V44.5442H160.998V34.6582Z" fill="#EBEBEB"/>
          <path d="M160.998 34.6582H158.008V37.7142H160.998V34.6582Z" fill="#DBDBDB"/>
          <path d="M114.176 31.6691V12.6191H109.379V31.6691H114.176Z" fill="#C7C7C7"/>
          <path d="M114.176 13.6926V11.5996H109.379V13.6926H114.176Z" fill="#DBDBDB"/>
          <path d="M114.176 17.154V16.377H109.379V17.154H114.176Z" fill="#DBDBDB"/>
          <path d="M114.773 29.1711V27.0781H109.378V29.1711H114.773Z" fill="#DBDBDB"/>
          <path d="M117.527 31.6693V9.44727H114.174V31.6693H117.527Z" fill="#DBDBDB"/>
          <path d="M116.844 20.0841V11.5371H114.832V20.0841H116.844Z" fill="#EBEBEB"/>
          <path d="M141.379 29.2894L125.574 13.668L123.217 16.0527L139.022 31.6741L141.379 29.2894Z" fill="#C7C7C7"/>
          <path d="M137.206 26.0925L131.129 20.084L129.714 21.5145L135.791 27.5231L137.206 26.0925Z" fill="#DBDBDB"/>
          <path d="M123.223 31.672V14.793H117.529V31.672H123.223Z" fill="#A6A6A6"/>
          <path d="M123.223 29.8835V28.3105H117.529V29.8835H123.223Z" fill="#C7C7C7"/>
          <path d="M123.223 27.276V26.666H117.529V27.276H123.223Z" fill="#C7C7C7"/>
          <path d="M109.379 31.6735V15.8105H105.446V31.6735H109.379Z" fill="#A6A6A6"/>
          <path d="M109.379 29.9917V28.5137H105.446V29.9917H109.379Z" fill="#C7C7C7"/>
          <path d="M109.379 20.5581V19.0801H105.446V20.5581H109.379Z" fill="#C7C7C7"/>
          <path d="M109.379 22.1453V21.5723H105.446V22.1453H109.379Z" fill="#C7C7C7"/>
          <path d="M157.649 24.2782L157.22 25.4102L156.959 26.1002L156.53 27.2362L154.855 31.6722H145.798L144.119 27.2362L143.69 26.1002L143.429 25.4102L143 24.2782L146.342 19.4122L144.61 16.2012H156.048L154.308 19.4122L157.649 24.2782Z" fill="#DBDBDB"/>
          <path d="M157.649 24.2773L157.22 25.4093H143.429L143 24.2773H157.649Z" fill="#A6A6A6"/>
          <path d="M156.955 26.0996L156.526 27.2356H144.12L143.691 26.0996H156.955Z" fill="#A6A6A6"/>
          <path d="M78.0329 0H26.9609V78.31H78.0329V0Z" fill="#EBEBEB"/>
          <path d="M51.3082 3.13672H29.7422V37.9687H51.3082V3.13672Z" fill="white"/>
          <path d="M75.2457 3.13672H53.6797V37.9687H75.2457V3.13672Z" fill="white"/>
          <path d="M51.3082 40.3418H29.7422V75.1738H51.3082V40.3418Z" fill="white"/>
          <path d="M75.2457 40.3418H53.6797V75.1738H75.2457V40.3418Z" fill="white"/>
          <path d="M39.0569 0H26.9609V112.036H39.0569V0Z" fill="#DBDBDB"/>
          <path d="M36.7643 5.32617L36.8573 17.4402L36.8913 29.5532L36.9553 53.7802L36.8913 78.0072L36.8573 90.1212L36.7633 102.235L36.6693 90.1212L36.6343 78.0082L36.5703 53.7812L36.6343 29.5532L36.6683 17.4392L36.7643 5.32617Z" fill="#EBEBEB"/>
          <path d="M31.2076 8.13672L31.3006 18.9527L31.3546 29.7687L31.3996 51.4017L31.3556 73.0347L31.3016 83.8477L31.2076 94.6637L31.1136 83.8477L31.0596 73.0317L31.0156 51.3987L31.0606 29.7657L31.1146 18.9497L31.2076 8.13672Z" fill="#EBEBEB"/>
          <path d="M33.9919 10.6895L34.0849 23.3545L34.1189 36.0195L34.1829 61.3495L34.1189 86.6805L34.0829 99.3495L33.9919 112.011L33.8919 99.3495L33.8579 86.6844L33.7969 61.3495L33.8609 36.0195L33.8919 23.3545L33.9919 10.6895Z" fill="#EBEBEB"/>
          <path d="M29.1422 5.96484L29.2352 19.2208L29.2692 32.4768L29.3332 58.9888L29.2692 85.5008L29.2352 98.7569L29.1412 112.013L29.0472 98.7569L29.0132 85.5008L28.9492 58.9888L29.0132 32.4768L29.0472 19.2208L29.1422 5.96484Z" fill="#EBEBEB"/>
          <path d="M65.9392 112.035H78.0352L78.0352 -0.000846863H65.9392L65.9392 112.035Z" fill="#DBDBDB"/>
          <path d="M68.2321 5.32617L68.3251 17.4402L68.3591 29.5532L68.423 53.7802L68.3591 78.0072L68.3251 90.1212L68.2311 102.235L68.1371 90.1212L68.1031 78.0072L68.0391 53.7802L68.1031 29.5532L68.1371 17.4392L68.2321 5.32617Z" fill="#EBEBEB"/>
          <path d="M73.7868 8.13672L73.8798 18.9527L73.9338 29.7687L73.9788 51.4017L73.9348 73.0347L73.8808 83.8507L73.7868 94.6667L73.6927 83.8507L73.6378 73.0357L73.5938 51.4027L73.6378 29.7697L73.6918 18.9537L73.7868 8.13672Z" fill="#EBEBEB"/>
          <path d="M71.0084 10.6895L71.1014 23.3545L71.1354 36.0195L71.1994 61.3495L71.1354 86.6805L71.1024 99.3495L71.0084 112.014L70.9144 99.3495L70.8804 86.6844L70.8164 61.3535L70.8804 36.0235L70.9144 23.3585L71.0084 10.6895Z" fill="#EBEBEB"/>
          <path d="M75.8531 5.96484L75.9462 19.2208L75.9801 32.4768L76.0441 58.9888L75.9801 85.5008L75.9462 98.7569L75.8522 112.013L75.7581 98.7569L75.7242 85.5008L75.6602 58.9888L75.7242 32.4768L75.7581 19.2208L75.8531 5.96484Z" fill="#EBEBEB"/>
          <path d="M0.632812 112.121H217.372" stroke="#263238" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M106.899 152.355C158.101 152.355 199.607 147.188 199.607 140.813C199.607 134.439 158.101 129.271 106.899 129.271C55.6982 129.271 14.1914 134.439 14.1914 140.813C14.1914 147.188 55.6982 152.355 106.899 152.355Z" fill="#EBEBEB"/>
          <path d="M154.316 119.46H63.3164L91.8894 52.7715H182.889L154.316 119.46Z" fill="#263238"/>
          <path d="M92.5605 54.1953H182.277L154.314 119.462H64.9375L92.5605 54.1953Z" fill="#EBEBEB"/>
          <path d="M92.5605 54.1944C92.9247 60.4823 92.2038 66.786 90.4295 72.8294C87.5015 82.4064 72.9945 101.583 70.1725 114.518L64.9375 119.46H154.314L182.277 54.1934L92.5605 54.1944Z" fill="#DBDBDB"/>
          <path d="M92.5604 54.1953C92.4774 55.1623 91.5344 67.9703 87.7834 76.2443C83.6014 85.4693 73.5114 100.229 69.7734 113.758" stroke="#DBDBDB" stroke-miterlimit="10"/>
          <path d="M91.6478 58.0664C90.6562 64.7538 88.6714 71.2555 85.7588 77.3564C80.9588 87.3434 72.6918 99.9144 68.8438 115.778" stroke="#DBDBDB" stroke-miterlimit="10"/>
          <path d="M120.952 123.16H208.342L178.762 54.1934H92.5624C92.5624 54.1934 92.2234 64.6344 97.9424 79.7424C103.329 93.9754 113.294 102.531 120.952 123.16Z" fill="white"/>
          <path d="M120.44 123.16C113.133 104.985 102.482 95.4384 97.0035 82.3024C92.7535 72.1104 92.5625 54.1934 92.5625 54.1934" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M93.418 56.1836C93.418 56.1836 95.488 72.9126 103.244 86.8256C111 100.739 114.816 105.945 118.844 115.463" stroke="#EBEBEB" stroke-miterlimit="10"/>
          <path d="M122.753 124.583H213.753L182.953 52.7715H91.9531L122.753 124.583Z" fill="#009E8C"/>
          <path d="M181.488 124.583H201.509L170.708 52.7715H150.688L181.488 124.583Z" fill="#009E8C"/>
          <path d="M132.214 124.583H139.464L108.664 52.7715H101.414L132.214 124.583Z" fill="#009E8C"/>
          <path d="M92.5643 54.194H183.564L184.53 51.918H90.9453L92.5643 54.194Z" fill="#009E8C"/>
          <path d="M52.4141 148.229L17.6071 139.064L1.41406 147.744L35.4611 159.09L52.4141 148.229Z" fill="#E7F9FC"/>
          <path d="M50.2552 148.072L34.7332 158.05L2.82422 147.442L18.4802 139.127C18.4802 139.127 28.0292 140.384 28.4742 140.527C28.9192 140.67 33.1302 142.414 33.1302 142.414C33.1302 142.414 39.0382 143.442 39.4392 143.576C39.8402 143.71 50.2552 148.072 50.2552 148.072Z" fill="white"/>
          <path d="M33.1268 142.41C31.9041 143.289 30.6744 144.158 29.4378 145.016L25.7148 147.573L21.9628 150.086C20.7081 150.919 19.4458 151.739 18.1758 152.548C19.3978 151.669 20.6274 150.8 21.8648 149.942L25.5878 147.386L29.3408 144.874C30.5948 144.041 31.8568 143.219 33.1268 142.41Z" fill="#DBDBDB"/>
          <path d="M46.8345 147.981C45.7012 147.536 44.5718 147.082 43.4465 146.617L40.0765 145.217L39.2355 144.864C39.0955 144.808 38.9525 144.747 38.8185 144.701C38.6845 144.655 38.5245 144.63 38.3775 144.594L36.6025 144.177C35.4205 143.895 34.2405 143.599 33.0625 143.289C34.2592 143.516 35.4538 143.755 36.6465 144.008L38.4335 144.395C38.5825 144.43 38.7335 144.455 38.8805 144.495C39.0275 144.535 39.1725 144.604 39.3165 144.66L40.1625 145.004C41.2825 145.484 42.4015 145.961 43.5135 146.458C44.6255 146.955 45.7335 147.46 46.8345 147.981Z" fill="#DBDBDB"/>
          <path d="M45.7554 148.618C44.622 148.173 43.4927 147.718 42.3674 147.254L38.9974 145.854L38.1564 145.501C38.0164 145.445 37.8734 145.384 37.7404 145.338C37.6074 145.292 37.4454 145.267 37.2994 145.231L35.5244 144.814C34.3424 144.532 33.1624 144.236 31.9844 143.926C33.181 144.152 34.3757 144.392 35.5684 144.645L37.3554 145.032C37.5044 145.067 37.6554 145.092 37.8024 145.132C37.9494 145.172 38.0944 145.241 38.2384 145.297L39.0844 145.642C40.2044 146.121 41.3234 146.599 42.4354 147.095C43.5474 147.591 44.6544 148.097 45.7554 148.618Z" fill="#DBDBDB"/>
          <path d="M44.6707 149.256C43.5627 148.821 42.4587 148.377 41.3587 147.923L38.0647 146.551L37.2437 146.206C36.9782 146.083 36.7029 145.983 36.4207 145.906L34.6877 145.491C33.5303 145.215 32.3753 144.928 31.2227 144.631C32.3933 144.845 33.56 145.074 34.7227 145.319C35.3067 145.437 35.8857 145.57 36.4677 145.705C36.7608 145.781 37.0471 145.881 37.3237 146.005L38.1497 146.344C39.2427 146.814 40.3377 147.28 41.4237 147.766C42.5097 148.252 43.5927 148.746 44.6707 149.256Z" fill="#DBDBDB"/>
          <path d="M43.5907 149.892C42.4987 149.463 41.4107 149.025 40.3267 148.579L37.0807 147.226C36.5417 147.009 35.9977 146.751 35.4637 146.573L33.7577 146.162C32.6177 145.888 31.4798 145.604 30.3438 145.311C31.4984 145.52 32.6494 145.745 33.7967 145.987C34.3717 146.106 34.9417 146.234 35.5167 146.371C36.0967 146.559 36.6167 146.796 37.1667 147.019C38.2437 147.483 39.3237 147.941 40.3927 148.419C41.4617 148.897 42.5297 149.391 43.5907 149.892Z" fill="#DBDBDB"/>
          <path d="M42.5105 150.532C41.4225 150.105 40.3382 149.668 39.2575 149.223L36.0225 147.875C35.4855 147.657 34.9425 147.405 34.4125 147.221C33.8545 147.075 33.2795 146.951 32.7125 146.81C31.5792 146.537 30.4458 146.254 29.3125 145.961C30.4632 146.168 31.6105 146.393 32.7545 146.634C33.3275 146.754 33.8945 146.88 34.4695 147.018C35.0445 147.213 35.5695 147.444 36.1115 147.668C37.1845 148.13 38.2605 148.587 39.3265 149.068C40.3925 149.549 41.4535 150.032 42.5105 150.532Z" fill="#DBDBDB"/>
          <path d="M41.4283 151.166C40.3437 150.74 39.263 150.307 38.1863 149.866L34.9613 148.522C34.4263 148.304 33.8843 148.056 33.3613 147.866C32.8063 147.72 32.2324 147.595 31.6684 147.456C30.535 147.184 29.4047 146.901 28.2773 146.609C29.424 146.816 30.5677 147.039 31.7083 147.279C32.2793 147.399 32.8444 147.523 33.4184 147.663C33.9884 147.863 34.5113 148.09 35.0523 148.315C36.1223 148.777 37.1953 149.231 38.2573 149.709C39.3193 150.187 40.3753 150.667 41.4283 151.166Z" fill="#DBDBDB"/>
          <path d="M40.3461 151.806C39.2751 151.385 38.2071 150.959 37.1461 150.519L33.9641 149.193C33.4341 148.978 32.9031 148.738 32.3781 148.538C31.8461 148.377 31.2691 148.263 30.7161 148.124C29.5991 147.853 28.4831 147.577 27.3711 147.285C28.5044 147.485 29.6331 147.705 30.7571 147.944C31.3221 148.069 31.8761 148.175 32.4461 148.338C32.9961 148.544 33.5201 148.762 34.0511 148.988C35.1071 149.444 36.1661 149.888 37.2131 150.365C38.2601 150.842 39.3061 151.314 40.3461 151.806Z" fill="#DBDBDB"/>
          <path d="M39.2661 152.442C38.1951 152.021 37.1271 151.595 36.0661 151.155L32.8831 149.832C32.3531 149.617 31.8221 149.377 31.2971 149.177C30.7641 149.016 30.1881 148.902 29.6351 148.763C28.5181 148.492 27.4021 148.216 26.2891 147.924C27.4224 148.124 28.5511 148.343 29.6751 148.583C30.2401 148.708 30.7941 148.814 31.3641 148.976C31.9151 149.182 32.4381 149.4 32.9701 149.626C34.0261 150.082 35.0841 150.526 36.1321 151.003C37.1801 151.48 38.2261 151.95 39.2661 152.442Z" fill="#DBDBDB"/>
          <path d="M38.3671 153.174C37.2821 152.744 36.1991 152.309 35.1221 151.86L31.8951 150.508L30.2841 149.835C29.7551 149.659 29.1631 149.551 28.6031 149.408C27.4691 149.133 26.3361 148.853 25.2031 148.557C26.3525 148.762 27.4981 148.985 28.6401 149.226C29.2131 149.358 29.7751 149.454 30.3551 149.637C30.9041 149.851 31.4421 150.071 31.9781 150.303C33.0481 150.768 34.1221 151.224 35.1851 151.703C36.2481 152.182 37.3131 152.674 38.3671 153.174Z" fill="#DBDBDB"/>
          <path d="M37.2841 153.835C36.2021 153.403 35.1231 152.967 34.0501 152.516L30.8331 151.159L29.2271 150.483C28.7031 150.301 28.1101 150.197 27.5521 150.053C26.4211 149.778 25.2911 149.499 24.1641 149.203C25.3107 149.407 26.4537 149.63 27.5931 149.871C28.1641 150.005 28.7251 150.095 29.3041 150.285C29.8481 150.501 30.3861 150.72 30.9201 150.954C31.9871 151.421 33.0581 151.878 34.1201 152.362C35.1821 152.846 36.2331 153.333 37.2841 153.835Z" fill="#DBDBDB"/>
          <path d="M36.1983 154.549C35.1513 154.12 34.1043 153.688 33.0653 153.241C32.0263 152.794 30.9893 152.341 29.9483 151.896L28.3923 151.224C28.1403 151.107 27.8759 151.019 27.6043 150.961L26.7803 150.761C25.6803 150.492 24.5803 150.22 23.4883 149.928C24.6036 150.123 25.7153 150.338 26.8233 150.573L27.6523 150.759C27.7903 150.793 27.9283 150.82 28.0703 150.865C28.2123 150.91 28.3423 150.965 28.4703 151.022C28.9956 151.238 29.5163 151.461 30.0323 151.691C31.0653 152.154 32.1033 152.606 33.1323 153.091C34.1613 153.576 35.1803 154.053 36.1983 154.549Z" fill="#DBDBDB"/>
          <path d="M35.1197 155.139C34.0537 154.751 32.9897 154.36 31.9317 153.953C30.8737 153.546 29.8177 153.132 28.7597 152.728C28.2317 152.528 27.6997 152.308 27.1787 152.128L25.5477 151.648C24.4637 151.317 23.3807 150.978 22.3047 150.619C23.4047 150.885 24.5047 151.171 25.6047 151.465L27.2427 151.922C27.7967 152.104 28.3147 152.314 28.8427 152.522C29.8947 152.945 30.9507 153.357 31.9947 153.8C33.0387 154.243 34.0807 154.685 35.1197 155.139Z" fill="#DBDBDB"/>
          <path d="M19.4874 149.973C18.4734 149.62 17.4724 149.236 16.4774 148.841L15.7284 148.555C15.4827 148.457 15.2304 148.377 14.9734 148.315L13.4224 147.89L10.3224 147.021C9.28937 146.732 8.26338 146.421 7.23438 146.121C8.27538 146.379 9.31937 146.621 10.3564 146.894L13.4694 147.694L15.0234 148.106C15.2879 148.169 15.5483 148.247 15.8034 148.341C16.0564 148.434 16.3034 148.541 16.5524 148.641C17.549 149.048 18.5274 149.492 19.4874 149.973Z" fill="#DBDBDB"/>
          <path d="M20.536 149.152C19.536 148.86 18.547 148.545 17.569 148.208C17.077 148.048 16.586 147.868 16.098 147.726L14.598 147.303L11.607 146.439C10.607 146.154 9.619 145.839 8.625 145.539C9.63 145.795 10.64 146.039 11.642 146.306L14.65 147.106C15.6604 147.354 16.6571 147.654 17.636 148.006C18.616 148.356 19.581 148.741 20.536 149.152Z" fill="#DBDBDB"/>
          <path d="M21.549 148.42C20.559 148.131 19.575 147.82 18.601 147.49L17.14 147.004L15.645 146.626L12.653 145.857C11.653 145.602 10.663 145.321 9.66797 145.057C10.674 145.281 11.683 145.491 12.686 145.728L15.696 146.434L17.196 146.8C17.696 146.947 18.176 147.129 18.664 147.3C19.637 147.638 20.599 148.019 21.549 148.42Z" fill="#DBDBDB"/>
          <path d="M22.4155 147.88C21.4355 147.536 20.4845 147.123 19.5265 146.741C19.295 146.638 19.0547 146.555 18.8085 146.495L18.0515 146.324L16.5355 145.988C14.5141 145.536 12.4958 145.069 10.4805 144.588C12.5171 144.97 14.5505 145.372 16.5805 145.794L18.1005 146.118L18.8605 146.283C19.1192 146.34 19.3714 146.422 19.6135 146.53C20.5708 146.93 21.5061 147.38 22.4155 147.88Z" fill="#DBDBDB"/>
          <path d="M23.3647 147.22C22.3847 146.875 21.4337 146.462 20.4757 146.081C20.2442 145.978 20.0039 145.896 19.7577 145.836L19.0007 145.664L17.4847 145.332C15.4634 144.88 13.445 144.413 11.4297 143.932C13.4664 144.314 15.4997 144.716 17.5297 145.138L19.0497 145.462L19.8097 145.628C20.0683 145.685 20.3206 145.767 20.5627 145.875C21.5198 146.273 22.4551 146.722 23.3647 147.22Z" fill="#DBDBDB"/>
          <path d="M24.311 146.561C23.331 146.217 22.38 145.803 21.422 145.422C21.1904 145.318 20.9502 145.236 20.704 145.176L19.947 145.005L18.431 144.67C16.4097 144.217 14.391 143.75 12.375 143.27C14.4117 143.652 16.445 144.054 18.475 144.476L19.995 144.8L20.755 144.965C21.0137 145.021 21.2659 145.104 21.508 145.212C22.4657 145.611 23.4014 146.061 24.311 146.561Z" fill="#DBDBDB"/>
          <path d="M25.2602 145.9C24.2802 145.555 23.3292 145.142 22.3712 144.761C22.1396 144.659 21.8994 144.577 21.6532 144.516L20.8962 144.345L19.3792 144.009C17.3586 143.557 15.3402 143.091 13.3242 142.609C15.3609 142.991 17.3942 143.393 19.4242 143.815L20.9442 144.138L21.7042 144.304C21.9628 144.361 22.2151 144.444 22.4572 144.551C23.4149 144.951 24.3505 145.401 25.2602 145.9Z" fill="#DBDBDB"/>
          <path d="M26.4848 145.371C25.5048 145.027 24.5548 144.613 23.5958 144.232C23.3645 144.129 23.1246 144.047 22.8788 143.987L22.1218 143.816L20.6058 143.48C18.5851 143.028 16.5668 142.561 14.5508 142.08C16.5874 142.462 18.6208 142.864 20.6508 143.286L22.1698 143.609L22.9298 143.775C23.1884 143.832 23.4407 143.915 23.6828 144.022C24.6401 144.422 25.5755 144.872 26.4848 145.371Z" fill="#DBDBDB"/>
          <path d="M27.6743 144.846C26.6943 144.502 25.7433 144.089 24.7853 143.707C24.5537 143.604 24.3135 143.521 24.0673 143.461L23.3103 143.29L21.7943 142.955C19.773 142.502 17.7543 142.035 15.7383 141.555C17.7749 141.937 19.8083 142.339 21.8383 142.761L23.3583 143.085L24.1183 143.25C24.3769 143.306 24.6292 143.389 24.8713 143.497C25.8289 143.896 26.7646 144.347 27.6743 144.846Z" fill="#DBDBDB"/>
          <path d="M28.6257 144.187C27.6477 143.843 26.6987 143.43 25.7407 143.05C25.5104 142.947 25.271 142.865 25.0257 142.807L24.2687 142.648L22.7497 142.339C20.7244 141.923 18.701 141.495 16.6797 141.055C18.719 141.398 20.7547 141.762 22.7867 142.147L24.3097 142.447L25.0717 142.6C25.3307 142.655 25.5831 142.737 25.8247 142.846C26.7815 143.243 27.7165 143.69 28.6257 144.187Z" fill="#DBDBDB"/>
          <path d="M29.5733 143.525C28.5963 143.181 27.6483 142.77 26.6933 142.39C26.4639 142.287 26.2252 142.206 25.9803 142.148L25.2223 142.002L23.6993 141.723C21.6693 141.349 19.6406 140.964 17.6133 140.568C19.6573 140.867 21.6973 141.188 23.7333 141.531L25.2593 141.797L26.0223 141.937C26.2819 141.991 26.5348 142.073 26.7763 142.182C27.7317 142.58 28.6653 143.029 29.5733 143.525Z" fill="#DBDBDB"/>
          <path d="M30.524 142.867C29.549 142.524 28.603 142.114 27.649 141.734C27.4214 141.631 27.1839 141.55 26.94 141.495L26.18 141.371L24.652 141.142C22.615 140.836 20.578 140.526 18.543 140.197C20.5916 140.424 22.6373 140.675 24.68 140.949L26.211 141.165L26.978 141.284C27.2383 141.335 27.4917 141.417 27.733 141.527C28.6863 141.924 29.618 142.372 30.524 142.867Z" fill="#DBDBDB"/>
          <path d="M34.0176 156.057C32.9296 155.696 31.8436 155.33 30.7626 154.948L27.5256 153.794C26.9866 153.607 26.4466 153.394 25.9136 153.217L24.2746 152.698C23.1836 152.343 22.0966 151.979 21.0156 151.598C22.1246 151.891 23.2276 152.198 24.3266 152.52L25.9726 153.013C26.5266 153.197 27.0616 153.385 27.6006 153.585C28.6746 153.985 29.7526 154.374 30.8206 154.79C31.8886 155.206 32.9566 155.625 34.0176 156.057Z" fill="#DBDBDB"/>
          <path d="M18.7054 150.778C17.6054 150.439 16.5054 150.095 15.4164 149.736L12.1434 148.649L10.5144 148.105L8.85941 147.62C7.75941 147.288 6.65941 146.946 5.56641 146.586C6.68541 146.856 7.79841 147.144 8.90741 147.441L10.5694 147.901C11.1294 148.073 11.6694 148.25 12.2144 148.439C13.3004 148.817 14.3894 149.184 15.4694 149.578C16.5494 149.972 17.6314 150.367 18.7054 150.778Z" fill="#DBDBDB"/>
          <path d="M187.476 88.257C186.542 87.585 156.734 78.336 155.376 78.702C155.228 78.744 154.914 80.084 153.568 83.411C153.503 83.569 139.32 100.763 139.32 100.763L158.731 111.008L169.809 95.389C169.809 95.389 183.666 92.761 185.117 92.202C186.568 91.643 188.41 88.928 187.476 88.257Z" fill="#FFC3BD"/>
          <path d="M187.475 88.257C186.541 87.585 156.733 78.336 155.375 78.702C155.227 78.744 154.913 80.084 153.567 83.411C153.502 83.569 151.561 85.821 150.609 87.048C151.844 88.109 163.754 96.517 167.026 99.311L169.809 95.388C169.809 95.388 183.666 92.76 185.117 92.201C186.568 91.642 188.409 88.928 187.475 88.257Z" fill="#FEED42"/>
          <path d="M161.054 81.5993C160.902 81.9377 160.709 82.2564 160.481 82.5484C160.029 83.1377 159.444 83.6117 158.774 83.9313C158.093 84.2485 157.343 84.3862 156.594 84.3313C155.852 84.2579 155.135 84.0289 154.488 83.6593C154.452 83.6403 154.488 83.5844 154.522 83.6044C155.166 83.9164 155.875 84.0723 156.59 84.0594C157.306 84.0465 158.009 83.8653 158.642 83.5303C159.244 83.2155 159.766 82.7674 160.17 82.2203C160.365 81.9529 160.533 81.6661 160.67 81.3643C160.737 81.2139 160.796 81.0597 160.846 80.9024C160.897 80.7424 160.91 80.6084 161.064 80.5184C161.1 80.4991 161.143 80.4948 161.183 80.5064C161.223 80.5179 161.257 80.5444 161.278 80.5803C161.443 80.8353 161.164 81.3513 161.054 81.5993Z" fill="white"/>
          <path d="M181.201 93.0985C180.546 92.8526 179.983 92.4096 179.59 91.8305C179.153 91.2445 178.791 90.6062 178.511 89.9305C178.231 89.2677 178.034 88.5723 177.926 87.8605C177.875 87.5139 177.843 87.1647 177.831 86.8145C177.799 86.4581 177.82 86.0988 177.893 85.7485C177.897 85.7354 177.905 85.7239 177.916 85.7157C177.927 85.7075 177.94 85.7031 177.954 85.7031C177.968 85.7031 177.981 85.7075 177.992 85.7157C178.003 85.7239 178.011 85.7354 178.014 85.7485C178.06 86.0604 178.088 86.3746 178.1 86.6895C178.138 87.0385 178.193 87.3853 178.265 87.7285C178.405 88.3972 178.606 89.0515 178.865 89.6835C179.338 90.9617 180.194 92.0626 181.316 92.8355C181.345 92.8547 181.365 92.8831 181.375 92.9158C181.384 92.9486 181.382 92.9836 181.368 93.0148C181.354 93.046 181.33 93.0715 181.3 93.0868C181.269 93.1021 181.235 93.1062 181.201 93.0985Z" fill="white"/>
          <path d="M167.038 95.7007C163.414 93.0847 159.807 90.4437 156.157 87.8647C155.123 87.1337 154.095 86.3907 153.049 85.6767C153.014 85.6527 153.054 85.6047 153.089 85.6267C156.925 87.9327 160.634 90.4443 164.2 93.1507C165.205 93.9267 166.182 94.7397 167.154 95.5567C167.173 95.5721 167.185 95.5944 167.188 95.6188C167.19 95.6432 167.183 95.6676 167.168 95.6867C167.152 95.7058 167.13 95.718 167.106 95.7206C167.081 95.7232 167.057 95.7161 167.038 95.7007Z" fill="white"/>
          <path d="M153.387 86.2617C153.374 86.2892 153.356 86.3137 153.333 86.3337C153.307 86.3537 153.278 86.3717 153.251 86.3907L153.095 86.4907C152.984 86.5647 152.872 86.6347 152.756 86.7007C152.531 86.8287 152.301 86.9457 152.068 87.0587C152.035 87.0747 152.005 87.0227 152.037 87.0037C152.255 86.8687 152.469 86.7267 152.68 86.5807C152.78 86.5097 152.886 86.4397 152.99 86.3697L153.154 86.2567C153.202 86.2152 153.259 86.1857 153.321 86.1707C153.333 86.1689 153.344 86.1702 153.355 86.1747C153.366 86.1791 153.375 86.1865 153.382 86.1959C153.389 86.2054 153.393 86.2165 153.394 86.2281C153.395 86.2397 153.392 86.2514 153.387 86.2617Z" fill="white"/>
          <path d="M155.078 87.508C155.065 87.5354 155.047 87.5599 155.024 87.58C154.998 87.6 154.97 87.618 154.942 87.637L154.786 87.737C154.675 87.811 154.563 87.881 154.447 87.947C154.222 88.075 153.992 88.192 153.759 88.305C153.727 88.321 153.696 88.269 153.728 88.25C153.946 88.115 154.16 87.972 154.371 87.827C154.471 87.756 154.578 87.686 154.681 87.616L154.844 87.503C154.892 87.4615 154.95 87.432 155.011 87.417C155.023 87.4149 155.035 87.4161 155.046 87.4205C155.057 87.4249 155.066 87.4322 155.073 87.4417C155.08 87.4512 155.084 87.4624 155.085 87.4741C155.086 87.4858 155.084 87.4976 155.078 87.508Z" fill="white"/>
          <path d="M156.77 88.7552C156.757 88.7826 156.739 88.8071 156.716 88.8272C156.69 88.8472 156.662 88.8652 156.634 88.8842L156.478 88.9842C156.367 89.0582 156.255 89.1282 156.139 89.1942C155.914 89.3222 155.684 89.4392 155.451 89.5522C155.418 89.5682 155.388 89.5162 155.42 89.4972C155.638 89.3622 155.852 89.2192 156.062 89.0732L156.372 88.8622L156.536 88.7492C156.584 88.7077 156.641 88.6782 156.703 88.6632C156.715 88.661 156.727 88.6622 156.738 88.6666C156.749 88.6709 156.758 88.6784 156.765 88.688C156.772 88.6977 156.777 88.7091 156.777 88.721C156.778 88.7329 156.776 88.7447 156.77 88.7552Z" fill="white"/>
          <path d="M158.46 90.0011C158.447 90.0285 158.429 90.053 158.406 90.0731C158.38 90.0931 158.352 90.1111 158.324 90.1301L158.168 90.2301C158.057 90.3041 157.946 90.3741 157.83 90.4401C157.604 90.5681 157.374 90.6851 157.142 90.7981C157.109 90.8141 157.079 90.7621 157.111 90.7421C157.329 90.6081 157.543 90.4651 157.754 90.3191C157.854 90.2481 157.961 90.1791 158.064 90.1081L158.227 89.9951C158.276 89.9538 158.333 89.9243 158.394 89.9091C158.406 89.9071 158.418 89.9084 158.429 89.9129C158.44 89.9174 158.449 89.9249 158.456 89.9345C158.463 89.9441 158.467 89.9554 158.468 89.9672C158.468 89.9789 158.466 89.9907 158.46 90.0011Z" fill="white"/>
          <path d="M160.149 91.2476C160.136 91.275 160.118 91.2995 160.095 91.3196C160.069 91.3396 160.041 91.3576 160.013 91.3766L159.857 91.4766C159.746 91.5506 159.634 91.6206 159.518 91.6866C159.293 91.8146 159.063 91.9316 158.829 92.0446C158.797 92.0606 158.767 92.0086 158.798 91.9886C159.017 91.8546 159.23 91.7116 159.441 91.5656C159.541 91.4946 159.648 91.4256 159.751 91.3546L159.914 91.2416C159.963 91.2005 160.02 91.171 160.081 91.1556C160.093 91.1532 160.105 91.1541 160.116 91.1584C160.128 91.1627 160.137 91.1702 160.145 91.1798C160.152 91.1895 160.156 91.201 160.157 91.213C160.158 91.225 160.155 91.237 160.149 91.2476Z" fill="white"/>
          <path d="M161.84 92.4954C161.827 92.5228 161.809 92.5473 161.786 92.5674C161.76 92.5874 161.731 92.6054 161.704 92.6244L161.548 92.7244C161.437 92.7974 161.325 92.8684 161.209 92.9344C160.984 93.0624 160.754 93.1794 160.521 93.2924C160.488 93.3074 160.458 93.2564 160.49 93.2364C160.708 93.1024 160.922 92.9594 161.132 92.8134C161.232 92.7424 161.339 92.6734 161.442 92.6024L161.606 92.4894C161.654 92.4482 161.712 92.4188 161.773 92.4034C161.785 92.4012 161.797 92.4024 161.808 92.4068C161.819 92.4112 161.829 92.4186 161.836 92.4283C161.843 92.4379 161.847 92.4493 161.848 92.4612C161.849 92.4731 161.846 92.485 161.84 92.4954Z" fill="white"/>
          <path d="M163.532 93.7407C163.519 93.768 163.501 93.7925 163.478 93.8127C163.452 93.8327 163.424 93.8507 163.396 93.8687L163.24 93.9687C163.129 94.0427 163.017 94.1127 162.901 94.1787C162.676 94.3067 162.446 94.4237 162.212 94.5367C162.18 94.5517 162.149 94.5007 162.181 94.4807C162.399 94.3467 162.613 94.2037 162.824 94.0577C162.924 93.9867 163.031 93.9177 163.134 93.8467L163.297 93.7337C163.346 93.6924 163.404 93.663 163.465 93.6477C163.477 93.6453 163.49 93.6464 163.501 93.6509C163.512 93.6553 163.522 93.6629 163.529 93.6727C163.536 93.6825 163.54 93.6941 163.541 93.7061C163.541 93.7182 163.538 93.7302 163.532 93.7407Z" fill="white"/>
          <path d="M165.223 94.9873C165.21 95.0152 165.192 95.0401 165.169 95.0603C165.143 95.0803 165.115 95.0983 165.087 95.1163L164.931 95.2213C164.82 95.2943 164.708 95.3653 164.592 95.4303C164.367 95.5583 164.137 95.6753 163.904 95.7883C163.871 95.8033 163.841 95.7523 163.873 95.7323C164.091 95.5983 164.305 95.4553 164.515 95.3093C164.615 95.2383 164.722 95.1693 164.825 95.0983L164.988 94.9853C165.036 94.9438 165.093 94.9143 165.155 94.8993C165.166 94.8974 165.178 94.8986 165.189 94.9027C165.199 94.9069 165.208 94.9139 165.215 94.9229C165.222 94.932 165.227 94.9427 165.228 94.9541C165.229 94.9654 165.228 94.9769 165.223 94.9873Z" fill="white"/>
          <path d="M166.913 96.2392C166.901 96.2665 166.883 96.2909 166.86 96.3112C166.834 96.3312 166.805 96.3492 166.778 96.3672L166.622 96.4672C166.511 96.5412 166.399 96.6112 166.283 96.6772C166.058 96.8052 165.828 96.9222 165.595 97.0352C165.562 97.0512 165.532 96.9992 165.564 96.9802C165.782 96.8462 165.996 96.7032 166.207 96.5572C166.307 96.4862 166.414 96.4172 166.517 96.3462L166.681 96.2332C166.729 96.1917 166.787 96.1622 166.848 96.1472C166.86 96.1454 166.872 96.1469 166.883 96.1515C166.893 96.1561 166.903 96.1636 166.909 96.1731C166.916 96.1827 166.92 96.1939 166.921 96.2056C166.921 96.2172 166.919 96.2289 166.913 96.2392Z" fill="white"/>
          <path d="M164.447 103.386L147.325 89.9121C147.325 89.9121 129.746 112.378 129.447 112.53C121.621 112.697 113.82 113.488 106.12 114.895C104.13 115.295 93.526 110.115 81.668 120.095L83.452 138.671C102.101 141.339 128.117 137.324 139.204 133.695C149.673 130.266 164.447 103.386 164.447 103.386Z" fill="#74D0C6"/>
          <path d="M162.051 105.144C158.2 101.961 154.313 98.8268 150.266 95.8948C149.114 95.0598 147.927 94.2738 146.794 93.4138C146.756 93.3848 146.716 93.4468 146.753 93.4758C147.741 94.2458 148.676 95.0758 149.646 95.8698C150.616 96.6638 151.585 97.4478 152.565 98.2238C154.52 99.7708 156.539 101.233 158.548 102.709C159.694 103.552 160.848 104.38 162.009 105.209C162.05 105.235 162.087 105.174 162.051 105.144Z" fill="#455A64"/>
          <path d="M148.906 97.4038C145.824 101.504 142.727 105.59 139.661 109.704C138.128 111.76 136.591 113.813 135.05 115.864C134.482 116.814 133.605 117.54 132.566 117.923C132.005 118.06 131.433 118.155 130.857 118.206L128.951 118.441C123.89 119.065 118.83 119.698 113.767 120.31C112.506 120.487 111.234 120.581 109.959 120.591C108.687 120.536 107.421 120.38 106.173 120.126C103.907 119.738 101.602 119.616 99.3074 119.762C94.7574 120.112 90.3147 121.319 86.2135 123.321C85.6925 123.568 85.1754 123.821 84.6624 124.086C84.6194 124.108 84.6624 124.17 84.7014 124.15C88.9299 122.08 93.4912 120.775 98.1745 120.295C100.479 120.068 102.803 120.114 105.097 120.432C106.324 120.61 107.536 120.883 108.771 121C110.038 121.097 111.311 121.067 112.571 120.911C115.114 120.642 117.651 120.292 120.19 119.979L127.814 119.039C129.078 118.883 130.349 118.752 131.609 118.566C132.639 118.481 133.61 118.051 134.365 117.346C135.131 116.482 135.833 115.564 136.465 114.599C137.239 113.541 138.017 112.486 138.798 111.434C141.84 107.314 144.857 103.176 147.884 99.0448L149.021 97.4938C149.066 97.4228 148.955 97.3398 148.906 97.4038Z" fill="#455A64"/>
          <path d="M139.059 55.8155C138.103 56.4545 118.996 81.1326 118.859 82.5306C118.846 82.6846 119.992 83.4476 122.636 85.8746C122.878 86.6938 123.005 87.5425 123.013 88.3966C123.466 93.8186 123.839 106.072 123.839 106.072L143.854 101.149L140.433 80.2866L139.545 74.8605C139.545 74.8605 141.94 60.9605 141.925 59.4065C141.91 57.8525 140.014 55.1765 139.059 55.8155Z" fill="#FFC3BD"/>
          <path d="M139.059 55.8156C138.103 56.4546 118.996 81.1326 118.859 82.5306C118.846 82.6846 119.992 83.4476 122.636 85.8746C122.878 86.6938 123.005 87.5426 123.013 88.3966C126.288 86.8166 136.348 82.1686 140.433 80.2846L139.545 74.8586C139.545 74.8586 141.94 60.9586 141.925 59.4046C141.91 57.8506 140.014 55.1766 139.059 55.8156Z" fill="#FEED42"/>
          <path d="M123.566 78.2309C123.83 78.4918 124.062 78.7839 124.255 79.1009C124.65 79.7304 124.89 80.4446 124.955 81.1848C125.012 81.9335 124.877 82.6842 124.562 83.3659C124.233 84.0345 123.768 84.6264 123.195 85.1029C123.164 85.1299 123.124 85.0789 123.156 85.0519C123.674 84.5577 124.068 83.9485 124.307 83.2735C124.546 82.5985 124.622 81.8768 124.53 81.1669C124.446 80.4924 124.209 79.846 123.838 79.2769C123.656 79.0003 123.446 78.7433 123.211 78.5099C123.094 78.3936 122.969 78.2844 122.839 78.1829C122.707 78.0829 122.586 78.0209 122.556 77.8439C122.551 77.8026 122.562 77.761 122.586 77.7276C122.611 77.6942 122.648 77.6718 122.689 77.6649C122.986 77.5989 123.373 78.0409 123.566 78.2309Z" fill="white"/>
          <path d="M141.392 63.3894C140.933 63.917 140.32 64.2891 139.64 64.4544C138.937 64.6583 138.211 64.7739 137.479 64.7984C136.76 64.8292 136.04 64.7697 135.335 64.6214C134.993 64.5479 134.655 64.4551 134.322 64.3434C133.977 64.2492 133.648 64.1036 133.345 63.9114C133.334 63.9032 133.326 63.8916 133.323 63.8783C133.319 63.8651 133.32 63.8511 133.325 63.8383C133.33 63.8255 133.339 63.8147 133.35 63.8075C133.362 63.8003 133.376 63.7971 133.389 63.7984C133.698 63.8653 134.002 63.9484 134.301 64.0474C134.641 64.1345 134.985 64.2043 135.332 64.2564C136.007 64.3599 136.691 64.4011 137.373 64.3794C138.737 64.3856 140.068 63.9696 141.185 63.1884C141.315 63.0934 141.495 63.2574 141.392 63.3894Z" fill="white"/>
          <path d="M139.111 78.2142C135.087 80.1592 131.049 82.0762 127.044 84.0622C125.909 84.6242 124.767 85.1752 123.644 85.7562C123.607 85.7762 123.584 85.7172 123.619 85.6972C127.471 83.4176 131.443 81.3479 135.519 79.4972C136.679 78.9812 137.859 78.5082 139.04 78.0422C139.051 78.0375 139.063 78.0351 139.075 78.0352C139.088 78.0352 139.1 78.0376 139.111 78.0423C139.122 78.047 139.133 78.0538 139.141 78.0625C139.15 78.0711 139.157 78.0814 139.161 78.0927C139.166 78.104 139.168 78.1161 139.168 78.1283C139.168 78.1405 139.166 78.1526 139.161 78.1639C139.157 78.1752 139.15 78.1854 139.141 78.1941C139.132 78.2027 139.122 78.2095 139.111 78.2142Z" fill="white"/>
          <path d="M124.312 85.7333C124.33 85.7578 124.343 85.7857 124.35 85.8153C124.355 85.8473 124.357 85.8813 124.361 85.9153C124.367 85.9783 124.374 86.0404 124.379 86.1024C124.391 86.2354 124.4 86.3673 124.403 86.5023C124.403 86.7613 124.403 87.0193 124.39 87.2783C124.39 87.3143 124.328 87.3163 124.326 87.2783C124.311 87.0223 124.287 86.7663 124.259 86.5113C124.245 86.3873 124.234 86.2623 124.221 86.1383C124.214 86.0717 124.207 86.005 124.199 85.9384C124.184 85.8765 124.184 85.8121 124.199 85.7503C124.202 85.7388 124.209 85.7285 124.218 85.7206C124.227 85.7127 124.238 85.7076 124.25 85.7058C124.261 85.704 124.274 85.7057 124.285 85.7105C124.296 85.7154 124.305 85.7233 124.312 85.7333Z" fill="white"/>
          <path d="M126.213 84.8404C126.231 84.8645 126.244 84.8921 126.251 84.9214C126.256 84.9544 126.258 84.9874 126.262 85.0214C126.268 85.0834 126.274 85.1454 126.28 85.2084C126.292 85.3414 126.301 85.4724 126.304 85.6084C126.309 85.8674 126.304 86.1254 126.291 86.3834C126.291 86.4194 126.229 86.4214 126.227 86.3834C126.212 86.1274 126.188 85.8714 126.16 85.6164C126.146 85.4924 126.135 85.3674 126.122 85.2434C126.116 85.1774 126.108 85.1114 126.101 85.0434C126.086 84.9816 126.086 84.9172 126.101 84.8554C126.105 84.8442 126.112 84.8344 126.121 84.8269C126.13 84.8194 126.141 84.8146 126.152 84.8131C126.164 84.8115 126.176 84.8132 126.187 84.8181C126.197 84.8229 126.207 84.8306 126.213 84.8404Z" fill="white"/>
          <path d="M128.111 83.9452C128.13 83.9696 128.143 83.9975 128.149 84.0272C128.155 84.0592 128.157 84.0932 128.16 84.1272C128.166 84.1902 128.172 84.2522 128.178 84.3142C128.19 84.4472 128.198 84.5792 128.201 84.7142C128.206 84.9732 128.201 85.2312 128.189 85.4902C128.189 85.5262 128.127 85.5282 128.125 85.4902C128.11 85.2342 128.086 84.9792 128.058 84.7232C128.044 84.5992 128.033 84.4742 128.02 84.3502C128.013 84.2842 128.006 84.2182 127.999 84.1502C127.985 84.0884 127.985 84.024 127.999 83.9622C128.003 83.9509 128.01 83.9409 128.019 83.9332C128.028 83.9255 128.038 83.9205 128.05 83.9187C128.062 83.9169 128.074 83.9185 128.085 83.9231C128.095 83.9278 128.105 83.9355 128.111 83.9452Z" fill="white"/>
          <path d="M130.013 83.05C130.031 83.0741 130.044 83.1017 130.051 83.131C130.057 83.164 130.059 83.197 130.062 83.231C130.069 83.293 130.075 83.355 130.08 83.418C130.092 83.551 130.101 83.683 130.104 83.818C130.109 84.077 130.104 84.335 130.092 84.594C130.092 84.63 130.03 84.632 130.028 84.594C130.013 84.338 129.989 84.083 129.961 83.827C129.947 83.703 129.936 83.578 129.923 83.454C129.916 83.388 129.909 83.322 129.902 83.254C129.887 83.1922 129.887 83.1278 129.902 83.066C129.906 83.055 129.912 83.0453 129.921 83.0379C129.93 83.0305 129.941 83.0257 129.952 83.0241C129.964 83.0224 129.976 83.024 129.986 83.0285C129.997 83.0331 130.006 83.0405 130.013 83.05Z" fill="white"/>
          <path d="M131.913 82.1552C131.931 82.1797 131.944 82.2076 131.951 82.2372C131.956 82.2692 131.958 82.3032 131.962 82.3372C131.968 82.3992 131.975 82.4612 131.98 82.5242C131.992 82.6562 132.001 82.7882 132.004 82.9242C132.009 83.1832 132.004 83.4412 131.991 83.7002C131.991 83.7362 131.929 83.7382 131.927 83.7002C131.912 83.4442 131.888 83.1882 131.86 82.9332C131.846 82.8092 131.835 82.6852 131.822 82.5602C131.816 82.4935 131.808 82.4269 131.8 82.3602C131.785 82.2984 131.785 82.234 131.8 82.1722C131.804 82.1607 131.81 82.1504 131.819 82.1425C131.828 82.1346 131.839 82.1294 131.851 82.1277C131.863 82.1259 131.875 82.1275 131.886 82.1324C131.897 82.1373 131.907 82.1452 131.913 82.1552Z" fill="white"/>
          <path d="M133.814 81.26C133.832 81.2841 133.845 81.3117 133.852 81.341C133.857 81.374 133.859 81.407 133.863 81.441C133.869 81.503 133.876 81.566 133.881 81.628C133.893 81.761 133.902 81.893 133.905 82.028C133.91 82.287 133.905 82.545 133.892 82.804C133.892 82.84 133.83 82.842 133.828 82.804C133.813 82.548 133.789 82.292 133.761 82.037C133.747 81.913 133.736 81.788 133.723 81.664C133.717 81.598 133.709 81.532 133.702 81.464C133.688 81.4022 133.688 81.3378 133.702 81.276C133.706 81.2648 133.713 81.2548 133.722 81.2472C133.731 81.2396 133.742 81.2347 133.753 81.2331C133.765 81.2314 133.777 81.233 133.788 81.2378C133.799 81.2425 133.808 81.2502 133.814 81.26Z" fill="white"/>
          <path d="M135.717 80.3635C135.735 80.3878 135.748 80.4158 135.755 80.4455C135.761 80.4785 135.763 80.5115 135.766 80.5455C135.772 80.6075 135.778 80.6695 135.784 80.7325C135.796 80.8655 135.805 80.9965 135.808 81.1325C135.813 81.3915 135.808 81.6495 135.795 81.9085C135.795 81.9445 135.733 81.9465 135.731 81.9085C135.716 81.6525 135.692 81.3965 135.664 81.1415C135.65 81.0175 135.639 80.8925 135.626 80.7675C135.62 80.7015 135.612 80.6355 135.605 80.5675C135.59 80.5057 135.59 80.4413 135.605 80.3795C135.609 80.3683 135.615 80.3583 135.624 80.3507C135.633 80.3431 135.644 80.3382 135.656 80.3366C135.668 80.3349 135.68 80.3365 135.69 80.3413C135.701 80.3461 135.71 80.3537 135.717 80.3635Z" fill="white"/>
          <path d="M137.61 79.4696C137.629 79.494 137.642 79.522 137.648 79.5516C137.654 79.5836 137.656 79.6176 137.659 79.6516C137.666 79.7146 137.672 79.7766 137.677 79.8386C137.689 79.9716 137.698 80.1036 137.701 80.2386C137.706 80.4976 137.701 80.7556 137.689 81.0146C137.689 81.0506 137.627 81.0526 137.625 81.0146C137.61 80.7586 137.586 80.5036 137.558 80.2476C137.544 80.1236 137.533 79.9986 137.52 79.8746C137.513 79.8086 137.506 79.7426 137.499 79.6746C137.485 79.6128 137.485 79.5484 137.499 79.4866C137.503 79.4756 137.51 79.4658 137.519 79.4583C137.527 79.4508 137.538 79.4458 137.55 79.4441C137.561 79.4423 137.573 79.4438 137.584 79.4483C137.594 79.4528 137.604 79.4602 137.61 79.4696Z" fill="white"/>
          <path d="M139.515 78.5758C139.533 78.5999 139.546 78.6274 139.553 78.6568C139.558 78.6898 139.561 78.7228 139.564 78.7568C139.57 78.8188 139.577 78.8808 139.582 78.9438C139.594 79.0768 139.603 79.2088 139.606 79.3438C139.611 79.6028 139.606 79.8608 139.593 80.1188C139.593 80.1548 139.531 80.1568 139.529 80.1188C139.514 79.8628 139.49 79.6068 139.462 79.3518C139.448 79.2278 139.437 79.1028 139.424 78.9788C139.417 78.9128 139.41 78.8468 139.402 78.7788C139.387 78.717 139.387 78.6526 139.402 78.5908C139.405 78.5794 139.412 78.5693 139.421 78.5616C139.43 78.5539 139.441 78.549 139.453 78.5474C139.465 78.5458 139.477 78.5477 139.488 78.5527C139.499 78.5577 139.508 78.5657 139.515 78.5758Z" fill="white"/>
          <path d="M141.456 86.1328L121.263 90.4218C121.263 90.4218 123.685 118.702 123.597 119.022C117.44 119.798 111.232 120.099 105.028 119.922C105.028 119.922 91.1434 113.887 81.6644 124.301L80.3164 142.572C106.727 145.772 142.087 144.29 146.775 135.296C151.868 125.528 141.456 86.1328 141.456 86.1328Z" fill="#74D0C6"/>
          <path d="M123.547 118.896C121.307 118.896 117.547 119.319 115.31 119.406C113.088 119.492 110.867 119.623 108.646 119.738C107.368 119.804 106.299 119.725 105.025 119.847C105.015 119.847 105.006 119.851 104.999 119.858C104.992 119.865 104.988 119.875 104.988 119.884C104.988 119.894 104.992 119.904 104.999 119.911C105.006 119.918 105.015 119.921 105.025 119.921C109.419 120.158 113.825 120.071 118.206 119.661C119.485 119.546 122.331 119.243 123.6 119.053C123.662 119.046 123.767 118.896 123.547 118.896Z" fill="#455A64"/>
          <path d="M103.766 117.993C102.937 117.075 102.005 116.256 100.989 115.551C100.954 115.526 100.914 115.583 100.945 115.61C101.83 116.444 102.662 117.332 103.437 118.269C104.276 119.173 105.047 120.139 105.743 121.157C105.746 121.161 105.751 121.163 105.755 121.164C105.76 121.164 105.765 121.163 105.769 121.161C105.774 121.158 105.777 121.154 105.778 121.149C105.78 121.145 105.78 121.14 105.778 121.135C105.22 120.02 104.545 118.967 103.766 117.993Z" fill="#455A64"/>
          <path d="M148.179 121.69C148.155 120.69 148.067 119.69 147.99 118.698C147.838 116.723 147.642 114.753 147.379 112.79C146.848 108.836 146.162 104.909 145.279 101.018C145.032 99.931 144.779 98.845 144.5 97.764C144.248 96.657 143.934 95.5652 143.559 94.494C143.527 94.411 143.376 94.419 143.392 94.521C143.535 95.478 143.792 96.428 144.003 97.372C144.214 98.316 144.433 99.289 144.636 100.249C145.05 102.208 145.43 104.175 145.775 106.149C146.463 110.07 147.02 114.008 147.485 117.961C147.616 119.071 147.726 120.184 147.846 121.295C147.906 121.851 148.13 126.295 148.141 126.87C148.141 126.919 148.22 126.916 148.215 126.87C148.169 126.375 148.214 123.177 148.179 121.69Z" fill="#455A64"/>
          <path d="M123.545 115.826C123.489 114.793 123.34 113.766 123.102 112.759C123.099 112.755 123.095 112.751 123.09 112.749C123.085 112.746 123.079 112.746 123.074 112.746C123.068 112.747 123.063 112.75 123.059 112.753C123.055 112.757 123.052 112.762 123.051 112.767C122.986 113.83 123.022 114.897 123.159 115.953C123.256 116.984 123.406 118.009 123.607 119.024C123.611 119.03 123.617 119.035 123.623 119.038C123.63 119.041 123.637 119.042 123.644 119.041C123.651 119.039 123.658 119.036 123.664 119.031C123.669 119.027 123.673 119.02 123.675 119.013C123.688 117.949 123.645 116.886 123.545 115.826Z" fill="#455A64"/>
          <path d="M142.16 124.849C142.084 121.291 141.84 117.738 141.481 114.198C140.773 107.214 139.619 100.279 138.381 93.3714C138.229 92.5234 138.077 91.676 137.925 90.8293C137.916 90.7813 137.846 90.8043 137.854 90.8503C139.008 97.7603 140.192 104.671 140.976 111.636C141.365 115.094 141.663 118.564 141.811 122.041C141.883 123.728 141.925 125.418 141.911 127.107C141.934 128.524 141.804 129.94 141.521 131.328C141.246 132.705 140.603 133.981 139.662 135.022C138.651 136.011 137.422 136.748 136.074 137.173C134.613 137.653 133.12 138.027 131.606 138.293C129.939 138.623 128.26 138.893 126.568 139.102C119.797 139.834 112.983 140.074 106.179 139.821C99.2085 139.681 92.2395 139.421 85.2685 139.345C84.3945 139.335 83.5215 139.324 82.6485 139.303C82.6005 139.303 82.6025 139.376 82.6485 139.377C86.2435 139.488 89.8365 139.646 93.4315 139.756C96.9929 139.866 100.555 139.971 104.117 140.072C110.999 140.382 117.894 140.233 124.757 139.627C128.078 139.31 131.371 138.742 134.608 137.927C135.971 137.608 137.277 137.078 138.478 136.356C139.621 135.632 140.536 134.599 141.118 133.377C142.369 130.76 142.22 127.67 142.16 124.849Z" fill="#455A64"/>
          <path d="M141.483 89.2938C136.546 90.0588 131.621 90.8827 126.742 91.9607C125.353 92.2677 123.977 92.6327 122.581 92.9087C122.534 92.9187 122.554 92.9897 122.601 92.9807C123.833 92.7537 125.076 92.6067 126.312 92.4077C127.548 92.2087 128.779 92.0078 130.012 91.7888C132.467 91.3548 134.901 90.8157 137.339 90.2947C138.731 89.9947 140.118 89.6817 141.505 89.3647C141.553 89.3557 141.532 89.2848 141.483 89.2938Z" fill="#455A64"/>
          <path d="M38.2656 115.072C38.9984 111.881 40.2173 108.821 41.8796 106C42.8346 104.558 46.0146 100.706 47.1796 101.354C48.3446 102.002 47.5126 103.21 47.5126 103.21C47.5126 103.21 49.0996 101.784 50.2126 102.542C51.3256 103.3 49.9716 105.142 49.9716 105.142C49.9716 105.142 51.7076 103.796 52.7626 104.633C53.8176 105.47 52.1476 107.088 52.1476 107.088C52.1476 107.088 53.6786 105.788 54.2566 106.838C54.9566 108.106 51.8566 110.193 50.8836 111.838C50.6626 112.212 46.9336 119.743 46.9336 119.743L38.2656 115.072Z" fill="#FFC3BD"/>
          <path d="M52.1398 107.126C50.231 108.295 48.7885 110.091 48.0598 112.207C48.0388 112.255 47.9598 112.235 47.9798 112.182C48.8178 109.661 49.5378 108.192 52.0708 107.011C52.1948 106.954 52.2478 107.052 52.1398 107.126Z" fill="#263238"/>
          <path d="M50.0385 105.049C48.167 106.489 46.6763 108.364 45.6965 110.513C45.6775 110.549 45.6155 110.521 45.6315 110.483C46.7235 107.913 47.4755 106.463 49.9315 104.951C50.0405 104.883 50.1395 104.966 50.0385 105.049Z" fill="#263238"/>
          <path d="M47.3358 103.37C45.5317 104.887 44.1749 106.866 43.4108 109.096C43.4068 109.109 43.3977 109.12 43.3855 109.127C43.3734 109.133 43.3591 109.135 43.3458 109.131C43.3325 109.127 43.3214 109.118 43.3148 109.106C43.3083 109.093 43.3068 109.079 43.3108 109.066C43.824 106.703 45.2456 104.637 47.2688 103.314C47.4128 103.214 47.4628 103.25 47.3358 103.37Z" fill="#263238"/>
          <path d="M46.7173 121.689C48.0235 120.671 49.2529 119.559 50.3953 118.361C51.3743 117.161 53.3803 111.361 52.1513 110.854C50.5643 110.203 48.7453 113.326 48.0513 114.972C48.0513 114.972 44.3813 113.462 42.5913 115.421C40.8013 117.38 46.7173 121.689 46.7173 121.689Z" fill="#FFC3BD"/>
          <path d="M51.6529 110.817C49.7769 111.267 49.0099 113.486 48.2209 115.033C48.2103 115.056 48.1942 115.077 48.1739 115.092C48.1537 115.108 48.1299 115.119 48.1047 115.123C48.0794 115.128 48.0534 115.127 48.0289 115.119C48.0044 115.111 47.9821 115.098 47.9639 115.08C46.3839 114.55 43.9999 114.262 42.5869 115.311C42.5789 115.318 42.5685 115.322 42.5578 115.321C42.5471 115.32 42.5371 115.316 42.5299 115.308C42.5227 115.3 42.519 115.289 42.5196 115.279C42.5201 115.268 42.5249 115.258 42.5329 115.251C43.8949 113.92 46.2599 114.012 47.9549 114.703C48.7399 113.103 49.6069 110.912 51.6489 110.803C51.6502 110.803 51.6514 110.804 51.6524 110.805C51.6534 110.806 51.6541 110.807 51.6545 110.809C51.6549 110.81 51.6549 110.812 51.6546 110.813C51.6544 110.814 51.6538 110.816 51.6529 110.817Z" fill="#263238"/>
          <path d="M59.6374 121.915C55.2917 128.447 49.4161 133.818 42.5214 137.561C38.0994 139.985 33.4634 137.161 32.7004 135.37C31.0684 131.542 36.7584 116.684 38.8124 113.689C39.4214 112.8 40.3374 114.582 43.1964 117.075C44.5504 118.256 49.7424 117.952 49.3754 119.416C49.1134 120.459 47.4154 123.422 47.6534 123.403C48.2404 123.354 52.5364 120.231 57.0844 117.303C63.3434 113.275 61.0984 119.771 59.6374 121.915Z" fill="#FFC3BD"/>
          <path d="M47.6627 123.282C47.3907 123.645 43.4627 131.664 42.5287 133.316C42.5047 133.358 42.5687 133.393 42.5937 133.352C43.5937 131.739 44.6287 130.145 45.5107 128.464C45.9467 127.634 46.3957 126.802 46.7537 125.936C46.9247 125.522 47.0827 125.104 47.2447 124.686C47.45 124.248 47.6131 123.791 47.7317 123.321C47.7387 123.283 47.6887 123.246 47.6627 123.282Z" fill="#263238"/>
          <path d="M48.0312 122.643L58.0362 126.472C59.6339 124.18 61.1109 121.807 62.4612 119.362C64.2532 115.806 63.3522 114.307 58.8292 116.143C55.8022 117.37 48.0312 122.643 48.0312 122.643Z" fill="#009E8C"/>
          <path d="M49.4101 122.082C50.9051 122.539 52.3891 123.016 53.8551 123.559C54.5885 123.83 55.3151 124.112 56.0351 124.405C56.7571 124.698 57.4501 125.066 58.1741 125.348C58.2141 125.364 58.1861 125.427 58.1471 125.409C57.4391 125.089 56.6801 124.863 55.9561 124.583C55.2321 124.303 54.5001 124.024 53.7701 123.751C52.3121 123.205 50.8541 122.651 49.3841 122.143C49.3421 122.132 49.3701 122.07 49.4101 122.082Z" fill="#263238"/>
          <path d="M67.7284 122.89H65.8374C65.7234 121.776 65.4359 120.687 64.9854 119.662C65.2101 120.722 65.2945 121.808 65.2364 122.89H63.5754C63.4164 121.047 62.8889 119.255 62.0244 117.62C62.499 119.336 62.7293 121.11 62.7084 122.89H48.0744C47.4254 119.803 47.9284 116.443 47.6744 117.176C47.1013 119.028 46.7922 120.952 46.7564 122.89H45.1274C45.0818 122.111 45.1261 121.328 45.2594 120.559C45.4734 119.534 44.6324 121.559 44.5254 122.89H43.4884C43.3932 120.306 43.8714 117.733 44.8884 115.356C47.0254 111.184 45.7744 109.64 46.6514 105.072C47.6514 99.8721 54.4794 93.5901 61.3044 100.16C62.4718 101.174 63.4104 102.425 64.0581 103.829C64.7058 105.233 65.0478 106.759 65.0614 108.305C65.0934 112.021 64.2484 113.984 65.5234 115.893C66.9485 117.949 67.7172 120.389 67.7284 122.89Z" fill="#263238"/>
          <path d="M42.8208 122.27C42.6464 120.687 42.795 119.085 43.2578 117.562C43.4786 116.796 43.7612 116.049 44.1028 115.329C44.2668 114.969 44.4698 114.614 44.6448 114.271C44.8228 113.932 44.9762 113.581 45.1038 113.22C45.5619 111.731 45.8567 110.197 45.9828 108.645C46.0828 107.863 46.1828 107.079 46.3458 106.303C46.5003 105.526 46.7411 104.768 47.0638 104.045C46.6417 105.557 46.3977 107.113 46.3368 108.682C46.2778 109.467 46.2018 110.254 46.0908 111.041C45.9778 111.834 45.791 112.615 45.5328 113.374C45.3945 113.754 45.2291 114.124 45.0378 114.481C44.8498 114.836 44.6638 115.166 44.4898 115.517C44.1419 116.212 43.8432 116.931 43.5958 117.668C43.3462 118.406 43.154 119.163 43.0208 119.931C42.9478 120.315 42.9008 120.703 42.8638 121.094C42.8268 121.485 42.8158 121.876 42.8208 122.27Z" fill="#263238"/>
          <path d="M65.066 108.307C65.098 108.939 65.053 109.57 65.066 110.197C65.079 110.824 65.08 111.449 65.135 112.067C65.1877 112.682 65.2917 113.292 65.446 113.891C65.48 114.041 65.533 114.185 65.574 114.332L65.607 114.442L65.643 114.533C65.671 114.596 65.704 114.661 65.743 114.727C65.89 114.991 66.059 115.257 66.224 115.527C66.549 116.068 66.868 116.621 67.145 117.197C67.4242 117.772 67.6582 118.369 67.845 118.981C68.0295 119.592 68.1564 120.22 68.224 120.855C68.2856 121.487 68.2829 122.123 68.216 122.755C68.1713 121.505 67.9321 120.269 67.507 119.093C67.0697 117.928 66.5109 116.812 65.84 115.765C65.679 115.495 65.513 115.229 65.357 114.94C65.318 114.866 65.281 114.791 65.246 114.71C65.229 114.669 65.212 114.622 65.197 114.581L65.163 114.464C65.121 114.308 65.063 114.155 65.037 113.997C64.8923 113.371 64.8092 112.733 64.789 112.092C64.7682 111.458 64.7893 110.823 64.852 110.192C64.906 109.556 64.996 108.933 65.066 108.307Z" fill="#263238"/>
          <path d="M80.0252 143.25C83.1913 140.071 85.2366 135.949 85.8518 131.505C86.467 127.062 85.6187 122.538 83.4352 118.62C83.4352 118.62 78.6802 119.376 77.4352 119.132C73.6502 118.395 70.8132 116.299 66.4552 114.675C62.0972 113.051 59.0862 114.326 57.3552 117.286C56.9232 118.025 54.7982 128.817 56.3552 132.645C57.5762 135.656 62.5222 136.529 62.5222 136.529C62.5222 136.529 71.7022 144.169 80.0252 143.25Z" fill="#009E8C"/>
          <path d="M62.9631 116.788C62.9311 117.28 61.1801 120.731 58.9981 120.988C58.2871 121.071 57.6621 117.211 57.6621 117.211L57.5501 116.936L55.9961 113.094L60.1841 109.308L60.7951 108.797C60.7951 108.797 61.1511 109.876 61.5811 111.225C61.6011 111.287 61.6211 111.35 61.6421 111.417C61.6631 111.484 61.6941 111.564 61.7151 111.635C61.7821 111.835 61.8491 112.041 61.9151 112.246C61.9641 112.403 62.0151 112.555 62.0571 112.72C62.0991 112.885 62.1501 113.047 62.2001 113.212C62.5359 114.385 62.7909 115.58 62.9631 116.788Z" fill="#FFC3BD"/>
          <path d="M61.5811 111.227C61.3641 112.414 60.8911 113.54 60.195 114.527C59.4989 115.513 58.5963 116.336 57.5501 116.938L55.9961 113.096L60.1841 109.31L60.7951 108.799C60.7951 108.799 61.1511 109.878 61.5811 111.227Z" fill="#263238"/>
          <path d="M49.1018 108.827C50.7278 115.917 55.1588 115.744 56.6868 115.392C58.0738 115.072 62.7588 113.807 61.8738 106.586C60.9888 99.3649 57.1738 97.8809 53.9668 98.4439C50.7598 99.0069 47.4758 101.736 49.1018 108.827Z" fill="#FFC3BD"/>
          <path d="M50.1634 107.446C50.3143 107.374 50.4611 107.293 50.6034 107.204C50.7695 107.14 50.91 107.023 51.0034 106.871C51.0231 106.818 51.0246 106.76 51.0078 106.706C50.991 106.651 50.9567 106.604 50.9104 106.571C50.8085 106.52 50.6945 106.496 50.5805 106.503C50.4664 106.51 50.3562 106.548 50.2614 106.611C50.0494 106.711 49.8822 106.887 49.7924 107.103C49.7749 107.154 49.7726 107.209 49.7857 107.261C49.7987 107.313 49.8267 107.36 49.866 107.396C49.9053 107.432 49.9544 107.456 50.0072 107.465C50.06 107.474 50.1143 107.468 50.1634 107.446Z" fill="#263238"/>
          <path d="M55.0293 106.13C54.8635 106.153 54.6966 106.168 54.5293 106.174C54.3552 106.214 54.1728 106.195 54.0103 106.121C53.9643 106.088 53.9303 106.041 53.9137 105.987C53.8971 105.932 53.8987 105.874 53.9183 105.821C53.9741 105.721 54.0556 105.638 54.1542 105.581C54.2528 105.523 54.3651 105.493 54.4793 105.493C54.7112 105.458 54.9479 105.51 55.1433 105.64C55.1855 105.672 55.2175 105.716 55.2355 105.766C55.2534 105.816 55.2566 105.87 55.2445 105.922C55.2325 105.974 55.2058 106.021 55.1676 106.058C55.1294 106.095 55.0814 106.12 55.0293 106.13Z" fill="#263238"/>
          <path d="M54.6328 108.168C54.6328 108.149 54.6768 108.18 54.6818 108.2C54.7998 108.681 55.0158 109.213 55.4918 109.251C55.5068 109.251 55.5118 109.276 55.4918 109.279C54.9428 109.351 54.6668 108.643 54.6328 108.168Z" fill="#263238"/>
          <path d="M54.9183 107.552C55.6693 107.279 56.1582 108.802 55.4632 109.052C54.7682 109.302 54.2883 107.779 54.9183 107.552Z" fill="#263238"/>
          <path d="M55.2414 107.53C55.4024 107.574 55.5804 107.683 55.7464 107.648C55.9374 107.607 56.0264 107.402 56.0984 107.21C56.1064 107.19 56.1234 107.199 56.1304 107.219C56.1991 107.351 56.215 107.504 56.1749 107.647C56.1347 107.79 56.0416 107.913 55.9144 107.989C55.6424 108.12 55.3544 107.928 55.1864 107.658C55.1544 107.613 55.1764 107.517 55.2414 107.53Z" fill="#263238"/>
          <path d="M51.6072 108.904C51.5972 108.887 51.5782 108.938 51.5842 108.958C51.7542 109.424 51.8712 109.985 51.4972 110.282C51.4852 110.291 51.4972 110.314 51.5092 110.307C52.0052 110.062 51.8432 109.318 51.6072 108.904Z" fill="#263238"/>
          <path d="M51.026 108.551C50.25 108.743 50.692 110.28 51.41 110.102C52.128 109.924 51.672 108.39 51.026 108.551Z" fill="#263238"/>
          <path d="M50.7347 108.744C50.6347 108.86 50.5587 109.044 50.4167 109.094C50.2517 109.153 50.0797 109.023 49.9267 108.894C49.9107 108.881 49.9027 108.894 49.9077 108.918C49.9837 109.218 50.1687 109.492 50.4677 109.468C50.7397 109.445 50.8557 109.139 50.8417 108.826C50.8387 108.768 50.7737 108.697 50.7347 108.744Z" fill="#263238"/>
          <path d="M55.5614 111.861C55.4914 112.011 55.4354 112.202 55.2724 112.279C55.0982 112.34 54.9132 112.363 54.7294 112.348C54.7256 112.347 54.7219 112.348 54.7187 112.35C54.7156 112.352 54.7132 112.355 54.7119 112.359C54.7107 112.363 54.7106 112.366 54.7118 112.37C54.713 112.374 54.7153 112.377 54.7184 112.379C54.8186 112.445 54.9351 112.484 55.0553 112.489C55.1756 112.495 55.2952 112.468 55.4014 112.412C55.4868 112.352 55.5525 112.268 55.5902 112.171C55.628 112.074 55.636 111.968 55.6134 111.866C55.6114 111.836 55.5724 111.839 55.5614 111.861Z" fill="#263238"/>
          <path d="M53.0547 110.758C53.0547 110.758 53.1747 111.495 53.2777 111.829C53.2877 111.86 53.3777 111.85 53.4887 111.822C53.4947 111.822 53.4987 111.822 53.5017 111.822C53.8046 111.786 54.0917 111.667 54.3313 111.478C54.5709 111.289 54.7537 111.037 54.8597 110.751C54.8727 110.711 54.8197 110.69 54.7957 110.72C54.4603 111.142 53.9976 111.444 53.4767 111.581C53.4247 111.496 53.3127 110.312 53.2587 110.324C53.0085 110.426 52.7731 110.56 52.5587 110.724C52.2587 109.196 52.2147 107.633 51.8987 106.116C51.8959 106.105 51.8895 106.096 51.8806 106.089C51.8717 106.083 51.8607 106.08 51.8496 106.08C51.8386 106.081 51.828 106.085 51.8198 106.093C51.8116 106.1 51.8063 106.11 51.8047 106.121C51.8051 107.798 51.9726 109.471 52.3047 111.114C52.3297 111.318 52.9387 110.856 53.0547 110.758Z" fill="#263238"/>
          <path d="M61.9077 107.05C60.1017 107.556 57.8327 102.233 57.5607 101.576C57.9235 102.481 58.188 103.422 58.3497 104.383C58.1568 104.44 57.9557 104.465 57.7547 104.456C57.6671 104.186 57.5592 103.923 57.4317 103.669C57.3777 103.548 57.4707 103.956 57.5587 104.469C57.4487 104.469 57.3287 104.475 57.1997 104.482C57.0353 103.898 56.7939 103.339 56.4817 102.818C56.5077 103.384 56.5719 103.947 56.6737 104.504C55.0082 104.544 53.3521 104.768 51.7357 105.171C51.2847 105.296 50.9027 105.424 50.5677 105.549C50.3527 104.883 50.2027 104.149 50.2027 104.316C50.1979 104.762 50.2371 105.207 50.3197 105.645C50.1387 105.714 49.9717 105.781 49.8197 105.845C49.4737 104.917 49.4417 103.27 49.3647 103.545C49.1437 104.397 49.086 105.283 49.1947 106.156C48.5947 106.485 48.4537 106.668 48.3837 106.507C47.9849 104.589 48.2816 102.592 49.2207 100.873C50.7827 98.0602 55.0667 96.1122 58.6087 98.5492C60.0881 98.9904 61.3448 99.9769 62.1247 101.309C63.1427 103.376 62.9507 106.756 61.9077 107.05Z" fill="#263238"/>
          <path d="M60.8711 107.386C60.8711 107.386 61.6851 104.738 62.8391 104.96C63.9931 105.182 63.8671 108.682 62.8541 109.481C62.7313 109.593 62.5871 109.679 62.4303 109.733C62.2736 109.787 62.1074 109.81 61.9418 109.798C61.7763 109.787 61.6148 109.742 61.467 109.666C61.3193 109.591 61.1883 109.486 61.0821 109.358L60.8711 107.386Z" fill="#FFC3BD"/>
          <path d="M62.7779 105.962C62.7959 105.954 62.8079 105.979 62.7939 105.991C62.483 106.295 62.2394 106.661 62.0787 107.065C61.918 107.469 61.8438 107.902 61.8609 108.336C61.8817 108.24 61.9231 108.15 61.9821 108.072C62.0412 107.993 62.1166 107.929 62.2031 107.882C62.2896 107.836 62.3852 107.808 62.4832 107.802C62.5812 107.796 62.6794 107.812 62.7709 107.847C62.7969 107.855 62.7869 107.895 62.7629 107.897C62.6065 107.898 62.4542 107.947 62.3269 108.038C62.1996 108.129 62.1035 108.257 62.0519 108.404C61.9672 108.652 61.9092 108.908 61.8789 109.167C61.8639 109.251 61.7219 109.255 61.7219 109.167V109.146C61.3659 108.121 61.7059 106.421 62.7779 105.962Z" fill="#263238"/>
          <path d="M69.1826 118.067C75.1246 125.444 83.2406 137.978 79.8476 140.902C74.2696 145.709 57.7116 148.802 53.9086 149.355C52.2666 149.593 48.7086 138.915 49.7736 137.496C50.8386 136.077 68.3916 134.667 68.4886 133.585C68.5436 132.972 66.9886 127.759 65.1116 121.272C62.9486 113.792 67.4826 115.956 69.1826 118.067Z" fill="#FFC3BD"/>
          <path d="M52.6315 136.539C52.6315 136.539 47.5865 136.623 44.2905 136.834C42.1393 136.93 40.0173 137.369 38.0045 138.134C36.0425 139.187 28.5045 144.846 29.8415 146.701C31.0475 148.381 36.9025 142.701 36.9025 142.701C36.9025 142.701 30.4875 147.913 32.3125 149.669C34.1375 151.425 39.8975 144.124 39.8975 144.124C39.8975 144.124 33.4265 149.624 35.5975 151.14C37.2355 152.284 42.8255 145.966 42.8255 145.966C42.8255 145.966 37.2965 150.735 38.9305 151.983C40.7365 153.363 46.2625 147.854 46.2625 147.854C50.9505 150.329 54.1945 149.589 56.4395 148.789C56.9805 148.596 52.6315 136.539 52.6315 136.539Z" fill="#FFC3BD"/>
          <path d="M39.0056 140.757C38.2296 141.342 37.4406 141.929 36.7196 142.581C35.9986 143.233 35.2996 143.911 34.6426 144.626C33.7654 145.545 32.9967 146.561 32.3516 147.656C33.7139 145.889 35.2385 144.254 36.9056 142.772C37.6246 142.122 38.3116 141.435 39.0316 140.783C39.0476 140.762 39.0246 140.743 39.0056 140.757Z" fill="#263238"/>
          <path d="M41.4065 143.319C40.4485 144.109 39.4685 144.858 38.5445 145.69C37.6205 146.522 36.8265 147.385 35.9665 148.274C35.9005 148.343 35.9585 148.356 36.0285 148.289C36.9235 147.434 37.8155 146.714 38.7195 145.87C39.6235 145.026 40.4955 144.147 41.4265 143.34C41.4415 143.327 41.4205 143.306 41.4065 143.319Z" fill="#263238"/>
          <path d="M43.8615 145.145C42.3626 146.321 40.9887 147.648 39.7615 149.105C39.6985 149.176 39.7765 149.184 39.8445 149.115C41.1815 147.773 42.2215 146.715 43.8995 145.197C43.9275 145.174 43.8935 145.121 43.8615 145.145Z" fill="#263238"/>
          <path d="M56.3851 135.896C55.6771 135.908 54.1921 136.236 52.5371 136.345C48.8121 136.589 43.5371 136.699 41.6601 136.845C36.5901 137.226 33.5911 141.045 32.1981 142.385C31.3651 143.199 30.6141 144.093 29.9561 145.053C29.6141 145.574 29.2931 146.313 29.7791 146.87C30.3701 147.548 32.5081 146.761 34.6971 144.682C34.7761 144.607 34.7241 144.563 34.6431 144.632C31.5641 147.254 30.3051 146.991 29.9671 146.563C29.7481 146.286 29.9411 145.794 30.1221 145.416C30.4153 144.87 30.7772 144.364 31.1991 143.909C32.6005 142.328 34.1592 140.893 35.8511 139.627C37.668 138.201 39.8528 137.32 42.1511 137.086C43.7831 136.912 48.8091 136.836 52.5631 136.559C54.2071 136.437 55.6831 136.075 56.3961 135.989C56.4451 135.977 56.4391 135.895 56.3851 135.896Z" fill="#263238"/>
          <path d="M76.7716 127.425L66.0606 127.631C65.3356 124.933 64.7518 122.199 64.3116 119.441C63.8326 115.488 65.1886 114.383 68.8206 117.641C71.2506 119.828 76.7716 127.425 76.7716 127.425Z" fill="#009E8C"/>
          <path d="M67.3684 127.608C67.2534 127.589 66.1524 127.574 66.0684 127.596C65.9204 126.98 65.1924 124.108 65.1064 123.811C65.1055 123.807 65.1038 123.803 65.1013 123.8C65.0988 123.796 65.0956 123.793 65.092 123.79C65.0883 123.788 65.0842 123.786 65.0799 123.786C65.0756 123.785 65.0712 123.785 65.0669 123.786C65.0627 123.787 65.0586 123.789 65.055 123.791C65.0514 123.794 65.0484 123.797 65.046 123.8C65.0436 123.804 65.042 123.808 65.0412 123.812C65.0404 123.817 65.0405 123.821 65.0414 123.825C65.0734 123.999 65.1924 124.662 65.3314 125.404L65.3144 125.375C65.2404 125.245 65.1564 125.119 65.0744 124.993C64.9924 124.867 64.9234 124.72 64.8504 124.584C64.8284 124.542 64.7624 124.584 64.7864 124.623C64.8614 124.752 64.9444 124.88 65.0084 125.016C65.0724 125.152 65.1254 125.273 65.1914 125.398C65.2574 125.523 65.3244 125.645 65.3914 125.769L65.4014 125.787C65.5604 126.624 65.7304 127.474 65.8184 127.787C66.3357 127.775 66.8517 127.73 67.3634 127.653C67.3904 127.642 67.3864 127.611 67.3684 127.608Z" fill="#263238"/>
          <path d="M75.6676 126.43C74.1066 126.352 72.5486 126.297 70.9856 126.311C70.2056 126.317 69.4262 126.336 68.6476 126.367C67.8696 126.398 67.0926 126.509 66.3156 126.528C66.2726 126.528 66.2776 126.598 66.3156 126.595C67.0896 126.535 67.8796 126.58 68.6566 126.562C69.4336 126.544 70.2156 126.53 70.9956 126.521C72.5526 126.502 74.1106 126.48 75.6676 126.497C75.7166 126.497 75.7086 126.432 75.6676 126.43Z" fill="#263238"/>
          <path d="M72.7741 122.074C72.7711 122.072 72.7673 122.07 72.7633 122.07C72.7594 122.07 72.7555 122.072 72.7524 122.074C72.7493 122.077 72.7472 122.08 72.7464 122.084C72.7456 122.088 72.7463 122.092 72.7481 122.095C72.9811 122.56 75.7681 126.368 76.6571 127.355C76.3791 127.369 76.1022 127.401 75.8281 127.449C76.192 127.482 76.5578 127.485 76.9221 127.458C76.9421 127.45 73.1761 122.419 72.7741 122.074Z" fill="#263238"/>
          <path d="M60.7791 85.1792C59.7603 87.1804 58.2014 88.8564 56.2791 90.0172C54.2296 89.0994 52.4787 87.6252 51.2251 85.7622C49.9021 83.4542 50.4921 80.8372 52.3161 80.1622C54.9321 79.1932 55.9001 83.7942 55.9001 83.7942C55.9001 83.7942 56.3001 79.1102 59.0181 79.7542C60.9091 80.2012 61.8121 82.7282 60.7791 85.1792Z" fill="#FEED42"/>
          <path d="M47.7197 77.1598C47.7946 78.9935 47.3543 80.8116 46.4487 82.4078C44.6226 82.5741 42.7865 82.2253 41.1487 81.4008C39.2827 80.2868 38.6547 78.1858 39.6847 76.9748C41.1607 75.2378 43.6917 78.1268 43.6917 78.1268C43.6917 78.1268 42.1017 74.6268 44.2917 74.0008C45.8197 73.5618 47.4747 74.9998 47.7197 77.1598Z" fill="#FEED42"/>
          <path d="M64.4429 52.9095C64.5179 54.7436 64.0776 56.562 63.1719 58.1585C61.3458 58.3247 59.5097 57.9758 57.8719 57.1515C56.0049 56.0375 55.3779 53.9365 56.4069 52.7255C57.8829 50.9875 60.4139 53.8775 60.4139 53.8775C60.4139 53.8775 58.8249 50.3775 61.0139 49.7505C62.5419 49.3125 64.1969 50.7505 64.4429 52.9095Z" fill="#FEED42"/>
          <path d="M57.4587 66.3346C56.6701 67.4087 55.5961 68.2402 54.3587 68.7346C53.2471 68.0025 52.3632 66.9731 51.8077 65.7636C51.2537 64.2876 51.8477 62.8126 52.9797 62.5916C54.6027 62.2746 54.7297 65.0576 54.7297 65.0576C54.7297 65.0576 55.4107 62.3576 56.9387 62.9916C58.0097 63.4346 58.2967 64.9986 57.4587 66.3346Z" fill="#FEED42"/>
          <path d="M50.0788 45.1567C49.9257 45.9428 49.5553 46.6703 49.0098 47.2567C48.2175 47.1429 47.473 46.8093 46.8608 46.2937C46.7055 46.1799 46.5763 46.0341 46.4819 45.8662C46.3876 45.6984 46.3302 45.5123 46.3136 45.3204C46.297 45.1286 46.3217 44.9354 46.3859 44.7538C46.45 44.5723 46.5523 44.4065 46.6858 44.2677C47.4858 43.6797 48.2698 45.1617 48.2698 45.1617C48.2698 45.1617 47.9478 43.5177 48.9428 43.4717C49.6358 43.4387 50.1918 44.2157 50.0788 45.1567Z" fill="#FEED42"/>
        </svg>
        <h1>404 Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        {/* You can add a link to go back to home or other navigation options */}
      </div>
    );
  }
  
  export default NotFoundComponent;