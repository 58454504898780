import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LeaderboardScore from "../../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import Notification from "../../../components/Notification/Notification";
import "../../Dashboard/Dashboard.css";
import { getDashboardDataColl } from "../../../api/collectorApi/sfdx_collector_apis";
import { connect } from "react-redux";
import { firebase } from "../../../config/fb_config";
import DownloadOurApp from "../../../components/ui_utilities/DownloadOurApp";
import FAVICON from "../../../asset/images/app_icon.png";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { CircularProgressbar } from "react-circular-progressbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";

const CollectorDashboard = ({ auth, history }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("daily");
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [sfId, setSfId] = useState("");
  const [fbUser, setFbUser] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [duration, setDuration] = useState("daily");
  const [customShowResult, setCustomShowResult] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        console.log("FB Data ", doc.data());
        setFbUser(doc.data());
        setSfId(doc.data().sf_id);
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });
  }, [auth.uid]);

  useEffect(() => {
    debugger;
    try {
      if (sfId) {
        const fetchData = async () => {
          console.log("sfid----->", sfId);
          let data = null;
          if (!startDate || !endDate) {
            data = await getDashboardDataColl(
              sfId,
              duration,
              "2024-01-01",
              "2024-12-31"
            );
          } else {
            const start_date = startDate.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            const end_date = endDate.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            console.log("startdate -  ", start_date);
            console.log("endadate---", end_date);
            data = await getDashboardDataColl(
              sfId,
              duration,
              start_date,
              end_date
            );
          }
          console.log("API data-->", data);
          // if (isCustom) setDashboardData(data.AllReports.customDate_reports);
          setDashboardData(data);
          //setDailyData(data.AllReports.daily_reports);
          // setMonthlyData(data);
          // setYearlyData(data);
        };

        fetchData();
      }
    } catch (error) {
      console.log("Exceprion in API Collector Dashboard Calling - ", error);
      // swal({
      //   title:'Exception',
      //   text:'Something went wrong, please try agian',
      //   icon:'error'
      // });
    }
  }, [sfId, isCustom, duration]);

  console.log("dashboardData---------------->", dashboardData);

  const handleReportTypeChange = (event) => {
    debugger;

    const type = event.target.value.toLowerCase();
    setIsCustom(false);
    setSelectedReportType(type);
    debugger;
    switch (type) {
      case "daily":
        //setDashboardData(dailyData);
        setDuration("daily");
        break;
      case "monthly":
        //setDashboardData(monthlyData);
        setDuration("monthly");
        break;
      case "yearly":
        //setDashboardData(yearlyData);
        setDuration("yearly");
        break;
      default:
        setModalOpen(true);
        break;
    }
  };

  console.log("dashboardData---------------->", dashboardData);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleDateChange = (date, field) => {
    if (field === "start") {
      setStartDate(date);
    } else if (field === "end") {
      setEndDate(date);
    }
  };
  const handleShowResult = () => {
    setDuration("custom");
    //setIsCustom(true);
    setModalOpen(false);
  };
  const usernameBlock = () => {
    if (fbUser)
      return (
        <>
          <h1>{`${fbUser.first_name}${" "}${fbUser.last_name}`}</h1>
        </>
      );
  };

  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-section">
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content custom-date">
                <span className="close" onClick={handleCloseModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                      fill="#242424"
                    />
                  </svg>
                </span>
                <h2>Custom Date</h2>
                <div style={{ gap: "1.5rem", display: "flex" }}>
                  <div>
                    <label>Start Date:</label>
                    <DatePicker
                      selected={startDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => handleDateChange(date, "start")}
                    />
                  </div>
                  <div>
                    <label>End Date:</label>
                    <DatePicker
                      selected={endDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => handleDateChange(date, "end")}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "3rem", textAlign: "center" }}>
                  <button
                    disabled={customShowResult}
                    onClick={() => {
                      handleShowResult();
                    }}
                    className="green-btn"
                  >
                    Show Results
                  </button>
                </div>
              </div>
            </div>
          )}
          {usernameBlock()}

          <h2 style={{ marginBottom: "1.25rem" }}>Welcome Back</h2>
          <div className="columns-3">
            <select
              className="form-control"
              onChange={handleReportTypeChange}
              value={selectedReportType}
            >
              <option value="daily">Daily Report</option>
              <option value="monthly">Monthly</option>

              <option value="yearly">Yearly</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
              marginTop: "1.5rem",
            }}
          >
            <div className="grey-card">
              <h3>Total Plastic (Kg)</h3>
              <h4 className="text-blue">
                {dashboardData
                  ? dashboardData.totalPlastic
                    ? dashboardData.totalPlastic
                    : 0
                  : 0}
              </h4>
              {/* <Link
                to={"/HardPlasticCollected"}
                className="details-btn"
                state={{
                  caseList: "",
                  plastic_type: "",
                  total: dashboardData
                    ? dashboardData.totalPlastic
                      ? dashboardData.totalPlastic
                      : 0
                    : 0,
                  filter: "total",
                  duration: duration,
                  user_id: sfId,
                  startDate: startDate,
                  endDate: endDate,
                  user_id: sfId,
                }}
              >
                View Details
              </Link> */}
            </div>

            {/* <div className='grey-card'>
                            <h3>Tickets In Progress</h3>
                            <h4 className='text-orange'>{dashboardData?.tickets_in_progress || '0'}</h4>
                            <Link
                                to={ '/TicketRaised'}
                                className="details-btn"
                                state={{ caseList: dashboardData?dashboardData.caseList?dashboardData.caseList:null:null,ticketType:'progress',title:'Tickets In Progress' }}
                                >
                                View Details
                            </Link>
                        </div> */}
            {/* <div className="grey-card">
              <h3>New Requests</h3>
              <h4 className="text-orange">
                {dashboardData?.ticket_raised || "0"}
              </h4>
              <Link
                to={"/TicketRaised"}
                className="details-btn"
                state={{
                  caseList: dashboardData
                    ? dashboardData.caseList
                      ? dashboardData.caseList
                      : null
                    : null,
                  ticketType: "New",
                  title: "New Requests",
                }}
              >
                View Details
              </Link>
            </div> */}
            <div className="grey-card">
              <h3>Hard Plastic Collected (Kg)</h3>
              <h4 className="text-purple">
                {dashboardData
                  ? dashboardData.hardPlasticData
                    ? dashboardData.hardPlasticData
                    : 0
                  : 0}
              </h4>
              <Link
                to={"/HardPlasticCollected"}
                state={{
                  caseList: "",
                  plastic_type: "Hard",
                  total: dashboardData
                    ? dashboardData.hardPlasticData
                      ? dashboardData.hardPlasticData
                      : 0
                    : 0,
                  filter: "hardplastic",
                  duration: duration,
                  user_id: sfId,
                  startDate: startDate,
                  endDate: endDate,
                  user_id: sfId,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div>
            <div className="grey-card">
              <h3>Soft Plastic Collected (Kg)</h3>
              <h4 className="text-pink">
                {dashboardData
                  ? dashboardData.softPlasticData
                    ? dashboardData.softPlasticData
                    : 0
                  : 0}
              </h4>
              <Link
                to={"/HardPlasticCollected"}
                state={{
                  caseList: "",
                  plastic_type: "Soft",
                  total: dashboardData
                    ? dashboardData.softPlasticData
                      ? dashboardData.softPlasticData
                      : 0
                    : 0,
                  filter: "softplastic",
                  duration: duration,
                  user_id: sfId,
                  startDate: startDate,
                  endDate: endDate,
                  user_id: sfId,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div>
            {/* <div className="grey-card">
              <h3>Surveys Taken</h3>
              <h4 className="text-green">
                {dashboardData
                  ? dashboardData.survey_taken
                    ? dashboardData.survey_taken
                    : 0
                  : 0}
              </h4>
              <Link
                to={"/SurveyTaken"}
                state={{
                  surveyList: dashboardData
                    ? dashboardData.surveyList
                      ? dashboardData.surveyList
                      : null
                    : null,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div> */}
            <div className="lg-grey-card">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h4>Collections Completed</h4>
                  <Link
                    to={"/TicketRaised"}
                    className="details-btn"
                    state={{
                      caseList: "",
                      ticketType: "closed",
                      title: "Collection Request",
                      filter: "completedcollection",
                      startDate: startDate,
                      endDate: endDate,
                      duration: duration,
                      user_id: sfId,
                    }}
                  >
                    View Details
                  </Link>
                </div>
                <div style={{ paddingTop: "0.5rem" }}>
                  <div style={{ width: 160, height: 160 }}>
                    {/* https://codesandbox.io/p/sandbox/progressbar-fontweight-forked-7t7qdp?file=%2Fsrc%2FApp.js%3A28%2C11-35%2C12 */}
                    <CircularProgressbar
                      // value={
                      //   dashboardData
                      //     ? dashboardData.solved_percentage
                      //       ? dashboardData.solved_percentage
                      //       : 0
                      //     : 0
                      // }
                      styles={{
                        text: {
                          fontSize: "1rem",
                          fontWeight: 500,
                        },
                        path: {
                          stroke: "#009E8C",
                        },
                      }}
                      value={
                        dashboardData
                          ? dashboardData.solved_percentage
                            ? dashboardData.solved_percentage
                            : 0
                          : 0
                      }
                      text={`${
                        dashboardData
                          ? dashboardData.completedCollections
                            ? dashboardData.completedCollections
                            : 0
                          : 0
                      }`}
                    />
                  </div>
                  {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="120"
                                        height="120"
                                        viewBox="0 0 120 120"
                                        fill="none"
                                    >
                                        <path
                                            d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM12 60C12 86.5097 33.4903 108 60 108C86.5097 108 108 86.5097 108 60C108 33.4903 86.5097 12 60 12C33.4903 12 12 33.4903 12 60Z"
                                            fill="#C8C8C8"
                                        />
                                        <path
                                            d="M60 114C60 117.314 62.6931 120.031 65.9902 119.7C79.7032 118.325 92.5951 112.258 102.426 102.426C113.679 91.1742 120 75.913 120 60C120 44.087 113.679 28.8258 102.426 17.5736C92.5951 7.74227 79.7032 1.67507 65.9902 0.299585C62.6931 -0.0311387 60 2.68629 60 6C60 9.31371 62.6967 11.9613 65.9846 12.3744C76.5084 13.6965 86.3617 18.4794 93.9411 26.0589C102.943 35.0606 108 47.2696 108 60C108 72.7304 102.943 84.9394 93.9411 93.9411C86.3617 101.521 76.5084 106.303 65.9846 107.626C62.6967 108.039 60 110.686 60 114Z"
                                            fill="#009E8C"
                                        />
                                    </svg> */}
                  {/* <div style={{marginTop:'2rem'}}>
                                    <h4 className='text-orange' style={{fontSize:'2.5rem'}}>{dashboardData?dashboardData.tickets_solved:0}</h4>
                                        </div>                                     */}
                </div>
              </div>
            </div>
            <div className="grey-card">
              <h3>Overdue Collections</h3>
              <h4 className="text-blue">
                {dashboardData
                  ? dashboardData.overdueData
                    ? dashboardData.overdueData
                    : 0
                  : 0}
              </h4>
              <Link
                to={"/TicketRaised"}
                className="details-btn"
                state={{
                  caseList: "",
                  ticketType: "overdue",
                  title: "Overdue Tickets",
                  filter: "overdue",
                  startDate: startDate,
                  endDate: endDate,
                  duration: duration,
                  user_id: sfId,
                }}
              >
                View Details
              </Link>
            </div>
            {/* <div className="grey-card">
              <h3>Feedbacks Given</h3>
              <h4 className="text-green">
                {dashboardData?.feedbacks_given || "0"}
              </h4>
              <Link
                to={"/FeedbackGiven"}
                state={{
                  feedbackList: dashboardData
                    ? dashboardData.feedbackList
                      ? dashboardData.feedbackList
                      : null
                    : null,
                }}
                className="details-btn"
              >
                View Details
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          <LeaderboardScore />
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            <ProfileCard profile={fbUser} />
          </div>
          {/* <Notification /> */}
          {process.env.REACT_APP_DOWNLOAD_URL && (
            <DownloadOurApp
              downloadUrl={process.env.REACT_APP_DOWNLOAD_URL}
              appTitle={"BFC"}
              appDescription={"Android Application"}
              imageUrl={FAVICON}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(CollectorDashboard);
