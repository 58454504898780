import Sidebar from "../../components/Sidebar/Sidebar";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { firebase } from "../../config/fb_config";
import { connect } from "react-redux";
import { newVolunteer } from "../../api/collectorApi/sfdx_collector_apis";
import { STATE_OBJ } from "../../utils/constants";
import swal from "sweetalert";
const { BACK_BUTTON } = require("../../utils/imagepath");

const NewResource = ({ auth }) => {
  const [sfId, setSfId] = useState("");
  const [fbUser, setFbUser] = useState(null);
  const navigate = useNavigate();
  const [newResource, setNewResource] = useState({
    firsName: "",
    lastName: "",
    phone: "",
    emailId: "",
    country: "IN",
    state: "KA",
    city: "",
    streetAddress: "",
    pincode: "",
  });
  const handleChange = (e) => {
    debugger;
    if (e.target.id === "phone") {
      const inputValue = e.target.value;
      if (!isNaN(inputValue) && inputValue.length <= 10) {
        setNewResource({
          ...newResource,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setNewResource({
        ...newResource,
        [e.target.id]: e.target.value,
      });
    }
    console.log(e.target.value);
  };
  useEffect(() => {
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        console.log("FB Data ", doc.data());
        setFbUser(doc.data());
        console.log("Firebase user new resource-->", doc.data());
        setSfId(doc.data().sf_id);
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });
  }, [auth.uid]);
  const handleOnclick = () => {
    const resouce = {
      user_type: "volunteer",
      first_name: newResource.firstName,
      last_name: newResource.lastName,
      email_id: newResource.emailId,
      add_city: newResource.city,
      add_street: newResource.streetAddress,
      add_state: newResource.state,
      add_country: "IN",
      add_pincode: newResource.pincode,
      phone: newResource.phone,
    };
    if (!resouce.first_name) {
      swal({
        title: "New Resource",
        text: "Please enter first name",
        icon: "error",
      });
      return;
    }
    if (!resouce.last_name) {
      swal({
        title: "New Resource",
        text: "Please enter last name",
        icon: "error",
      });
      return;
    }
    if (!resouce.phone) {
      swal({
        title: "New Resource",
        text: "Please enter phone number",
        icon: "error",
      });
      return;
    }
    if (resouce.phone.length !== 10) {
      swal({
        title: "Invalid phone number",
        text: "Phone number length must be of 10 digit.",
        icon: "error",
      });
      return;
    }
    if (!resouce.email_id) {
      swal({
        title: "New Resource",
        text: "Please enter email id",
        icon: "error",
      });
      return;
    }
    if (!resouce.add_state) {
      swal({
        title: "New Resource",
        text: "Please choose a state",
        icon: "error",
      });
      return;
    }
    if (!resouce.add_city) {
      swal({
        title: "New Resource",
        text: "Please enter the city name",
        icon: "error",
      });
      return;
    }
    if (!resouce.add_street) {
      swal({
        title: "New Resource",
        text: "Please enter street address",
        icon: "error",
      });
      return;
    }
    if (!resouce.add_pincode) {
      swal({
        title: "New Resource",
        text: "Please enter pincode",
        icon: "error",
      });
      return;
    }

    if (sfId) {
      newVolunteer(resouce, sfId, handleSuccess).catch((error) => {
        console.error("Error submitting feedback:", error);
      });
    } else {
      console.error("sfId is not available.");
    }
  };
  const handleSuccess = (Response) => {
    debugger;
    console.log("Response received==>", Response);
    swal({
      title: "New Resource",
      text: "New resource added successfully",
      icon: "success",
    });
    navigate("/resource");
  };
  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-wrap">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>
              <Link to="/Dashboard">
                <img src={BACK_BUTTON} alt="" />
              </Link>
              New Resource
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "2rem",
              gap: "1.25rem",
            }}
          >
            <div className="flex-1">
              <label>
                First Name <span>*</span>
              </label>
              <input
                type="text"
                id="firstName"
                value={newResource.firstName}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. John"
              />
            </div>
            <div className="flex-1">
              <label>
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                id="lastName"
                value={newResource.lastName}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. Don"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5rem",
              gap: "1.25rem",
            }}
          >
            <div className="flex-1">
              <label>
                Phone Number <span>*</span>
              </label>
              <input
                type="number"
                maxLength={10}
                id="phone"
                value={newResource.phone}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. 9999999999"
              />
            </div>
            <div className="flex-1">
              <label>
                Email ID <span>*</span>
              </label>
              <input
                type="email"
                id="emailId"
                value={newResource.emailId}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. john.doe@example.com"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5rem",
              gap: "1.25rem",
            }}
          >
            <div className="flex-1">
              <label>
                Country <span>*</span>
              </label>
              <input
                type="text"
                disabled="disabled"
                value="India"
                className="form-control"
              />
            </div>
            <div className="flex-1">
              <label>
                State <span>*</span>
              </label>
              {/* <input type='text' className='form-control' placeholder='eg. Karnataka' /> */}
              <select
                id="state"
                className="block w-full px-4 py-2 mt-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                value={newResource.state}
                onChange={handleChange}
              >
                <option value="">--None--</option>
                {Object.entries(STATE_OBJ).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5rem",
              gap: "1.25rem",
            }}
          >
            <div className="flex-1">
              <label>City</label>
              <input
                type="text"
                id="city"
                value={newResource.city}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. Bengaluru"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5rem",
              gap: "1.25rem",
            }}
          >
            <div className="flex-1">
              <label>Street Address</label>
              <input
                type="text"
                id="streetAddress"
                value={newResource.streetAddress}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. Andheri East"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5rem",
              gap: "1.25rem",
            }}
          >
            <div className="flex-1">
              <label>Pincode</label>
              <input
                type="text"
                id="pincode"
                value={newResource.pincode}
                onChange={handleChange}
                className="form-control"
                placeholder="eg. 560078"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5rem",
              gap: "1.25rem",
            }}
          >
            <Link className="ordered-btn" to="/Resource">
              Cancel
            </Link>
            <Link className="green-btn" onClick={handleOnclick}>
              Create
            </Link>
          </div>
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          {/* <LeaderboardScore />                     */}
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, null)(NewResource);
