import React, { useState ,useEffect, useRef} from 'react';
import { Link } from 'react-router-dom'
import { storage } from '../../config/fb_config'; 
import './DocumentCard.css'
import { PICK_IMAGE, UPLOAD_IMAGE, UPLOAD_SUCCESS } from '../../utils/imagepath';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { AiFillPropertySafety } from 'react-icons/ai';

export default function DocumentCard({callBackFn,documentData,sf_id}) {
  debugger
  // const {callBackFn,documents}=props; 
  // const [documentData,setDocumentData]=useState(null);
  // setDocumentData(documents);
  console.log('sf_id-- ',sf_id);
  console.log('document card data -- -',documentData);
  const [file, setFile] = useState('');
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');
  const [docUrl, setDocUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);


  
  useEffect(() => {
    if (uploadProgress === 100) {
      setIsSubmitting(false);
      // Enable the submit button or perform any other action here
      console.log('All documents uploaded');
    }
  }, [uploadProgress]);

  function handleChange(event) {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  }

  const handlePickImage = () => {
      fileInput.current.click();
   
  };

  const handleUpload = () => {
    if (!file) {
      alert('Please upload an image first!');
      return;
    }

    setIsSubmitting(true);

    const storageRef = ref(storage, `/${sf_id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(percent);
      },
      (err) => {
        console.log(err);
        setIsSubmitting(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          setDocUrl(url);
          callBackFn(documentData.doc_name,url,documentData.sf_id);
          alert('Image uploaded successfully!');
        });
      }
    );
  };


    return (
        <div>
    {documentData? 
    // (documentData.map((documentData,key)=>(
        // Object.entries(documentData).map(([key, documentData], index) => (
        <div className="document-card">
        <div>
            {/* Hidden file input */}
            <input type="file" onChange={handleChange} accept="image/*" style={{ display: 'none' }} ref={fileInput} />
            {/* Button to pick image */}
            <button onClick={handlePickImage} disabled={!(documentData.status === 'Pending' || documentData.status === 'Rejected')}>
              <img src={PICK_IMAGE} alt="Pick" />
            </button>
          </div>
            <div style={{ width: 'calc(100% - 2rem)', paddingLeft: '0.25rem' }}>
                <h3>{documentData.doc_name}</h3>
                <div style={{ display: 'flex', paddingBottom: '0.5rem', color: '#424242', fontSize: '0.625rem', lineHeight: '1rem', fontWeight: '600', paddingTop: '0.25rem' }}>
                <div>Status: <span style={{ paddingLeft: '0.25rem', color: documentData.status === 'Rejected' ? 'red' : 'green' }}>{documentData.status}</span></div>
                    <div style={{ paddingLeft: '1.5rem' }}>Size: <span style={{ paddingLeft: '0.25rem' }}>1.2 MB</span></div>
                </div>
                <div style={{ display: 'flex' }}>
                {fileName && (
                <div style={{ color: '#0047FF', paddingRight: '1rem', fontSize: '0.6875rem', lineHeight: '1rem', fontWeight: '500', textDecoration: 'underline' }}>
                  {fileName}
                </div>
              )}
                  {/* <Link style={{ color: '#00A990', fontSize: '0.6875rem', fontWeight: '500', lineHeight: '1rem' }}>Change</Link> */}
                </div>                
            </div>
            {(documentData.status === 'Pending' || documentData.status === 'Rejected') && (
          <div>
            <div>
              <button onClick={handleUpload} disabled={isSubmitting}>
                {uploadProgress === 100 ? <img src={UPLOAD_SUCCESS} alt="upload" /> : <img src={UPLOAD_IMAGE} alt="upload" />}
              </button>
            </div>
          </div>
        )}
        </div>:(<p style={{padding:'32%'}}>No Document  related to this record id.</p>)
      //    ))
      // ) : (
      //   <p style={{padding:'32%'}}>No Document  related to this record id.</p>
      // )
      }

      {uploadProgress > 0 && uploadProgress < 100 && (
        <div style={{ marginTop: '1rem' }}>
          <progress value={uploadProgress} max="100"></progress>
        </div>
      )}


      </div>

    )
}