export const HOW_RELATED_OBJ = {
    HOUSING_SOCIETY:{Member:"Member", President:"President", secretary:"secretary", others:"others"},
    INDIVIDUAL:{Member:"Member"},
    HOTEL_AND_CORPORATE:{MD:'MD', Management:'Management', Staff:'Staff', Others:'Others'},
    SCHOOL_AND_INSTITUTION: {Management:'Management', Teachers:'Teachers', Students:'Students', Parents:'Parents', Others:'Others'},
    NGO:{MD:'MD',Management:'Management','Trustee_Member':'Trustee Member',Staff:'Staff',Others:'Others'},
    COLLECTOR:{Owner:'Owner',Staff:'Staff',Others:'Others'}
    }
export const STATE_OBJ = {
    KA: "Karnataka",
    AN: "Andaman and Nicobar Islands",
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chandigarh",
    CH: "Chhattisgarh",
    DN: "Dadra and Nagar Haveli",
    DD: "Daman and Diu",
    DL: "Delhi",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu and Kashmir",
    JH: "Jharkhand",
    KL: "Kerala",
    LA: "Ladakh",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PY: "Puducherry",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UK: "Uttarakhand",
    WB: "West Bengal",
};
export const WEB_CONST = {
    START_DATE:"2023-12-01",
    END_DATE:"2023-12-31"
}