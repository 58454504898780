import React, { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
import LeaderboardScore from '../../LeaderboardScore/LeaderboardScore';
import ProfileCard from '../../ProfileCard/ProfileCard';
import Notification from '../../Notification/Notification';
import { DROPDOWN_ICON } from '../../../utils/imagepath';
import './faq.css';
import { getFaqs } from '../../../api/requestorApi/sfdx_requestor_apis';

export default function FAQ() {
  const [faqData, setFaqData] = useState([]);
  const [openAnswer, setOpenAnswer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sfId = "0032w00001JjeegAAB"; 
        const token = "00D0T0000000Svl!ARsAQIN1gWOoeV2F83mDgZswSry_p55rhIRab0X2oXbOh9D4mRAaN6.Tfie4Ng64DLuU0zn.UiUtxJXI6Hs04IWIzSZTBzfQ"; 

        const data = await getFaqs(token, sfId);
        
        if (data && data.faqs && Array.isArray(data.faqs)) {
          setFaqData(data.faqs);
        } else {
          console.error("FAQ data is not in the expected format:", data);
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchData();
  }, []);

  const toggleAnswer = (index) => {
    setOpenAnswer((prevOpen) => (prevOpen === index ? null : index));
  };
  
  return (
    <div className="flex flex-row h-screen">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="help-mid-section">
          <h1>FAQ</h1>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1.5rem' }}>
            {faqData.map((faq, index) => (
              <div key={index} className="">
                <button
                  style={{ display: 'flex', flexDirection: 'row' }}
                  className={`faq-question ${openAnswer === index ? 'active' : ''}`}
                  onClick={() => toggleAnswer(index)}
                >
                  {faq.question}
                  <img
                    src={DROPDOWN_ICON}
                    alt="Dropdown Icon"
                    className={`dropdown-icon ${openAnswer === index ? 'active' : ''}`}
                    style={{ marginLeft: 'auto' }}
                  />
                </button>
                {openAnswer === index && (
                  <div className='faq-card'>
                    <p className="faq-answer">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          {/* <LeaderboardScore /> */}
          <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
}
