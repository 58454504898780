import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaCaretDown } from "react-icons/fa";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import "./AboutPet.css";
import Footer from "../Footer/Footer";
import FooterBottom from "../FooterBottom/FooterBottom";
const {
  WHITE_LOGO,
  RECYCLE_PET,
  STEP1,
  STEP2,
  STEP3,
  PRODUCT1,
  PRODUCT2,
  PRODUCT3,
  PRODUCT4,
  PRODUCT5,
  PRODUCT6,
  PRODUCT7,
  PRODUCT8,
  CONSERVES_NATURAL,
  SAVES_LANDFILL,
  REDUCE_CO2,
  PRESERVES_FLORA,
  JOB_CREATION,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
} = require("../../utils/imagepath");

export default function AboutPet() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="about-pet">
      <div className="banner">
        <div className="main-header">
          <Link className="logo">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn" to="/login">
            Login/Signup
          </Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/Partnership">
            <FaBars className="hamburger" />
          </Link>
        </div>
        <h1>TRUTH ABOUT PLASTIC AND RECYCLING</h1>
      </div>
      <div className="description">
        <h2 data-aos="fade-up" data-aos-duration="700">
          USED PLASTIC IS NOT WASTE, IT IS RECYCLABLE.
        </h2>
        <p data-aos="fade-up" data-aos-duration="700">
          Plastic is a miraculous material that human beings have invented from
          petroleum derivatives. There are myths around plastic being bad and
          causing negative impact on the environment by getting into rivers,
          oceans and dumping grounds. However, in reality plastic is not a
          problem.
        </p>
        <p data-aos="fade-up" data-aos-duration="700">
          The problem is of irresponsible human behavior in disposing the
          plastic. If disposed sensibly, it can actually do well to the
          environment. If recycled, plastic can be made to form recycled
          polyester which in turn is made to produce a number of things like
          shoes, T-shirts, bags, chairs etc. thereby causing less strain on
          natural resources.
        </p>
        <img
          src={RECYCLE_PET}
          alt=""
          style={{ marginTop: "3rem", display: "inline-block" }}
          data-aos="fade-up"
          data-aos-duration="700"
        />
        <h2 data-aos="fade-up" data-aos-duration="700">
          PLASTIC HAS AN AFTERLIFE
        </h2>
        <h3 data-aos="fade-up" data-aos-duration="700">
          HERE'S HOW YOU CAN DISPOSE & RECYCLE PLASTIC RESPONSIBLY!
        </h3>
        <p data-aos="fade-up" data-aos-duration="700">
          In the current scenario 78% of plastic is being recycled. Rest 22% of
          plastic majorly contains plastic items like Wrappers, plastic bags,
          milk pouches, multilayer chips packets etc are not picked up for
          recycling only because they are light weight, dirty/filthy. These
          plastic lie in the dump yards and cause havoc, Very few people know
          that any and every type of plastic can be recycled several times.
        </p>
        <p data-aos="fade-up" data-aos-duration="700">
          The plastic recycling process refers to the process of re-using the
          clean plastic by processing it and turning it into a new item
          altogether. The process includes cleaning of the plastic, cutting it
          into flakes and then melting it at high temperature for molding it
          into desirable use.
        </p>
        <p data-aos="fade-up" data-aos-duration="700">
          Let's know the process in details:
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-flow-row-dense md:grid-cols-3">
        <div className="col-span-2">
          <div className="process-step">
            <div data-aos="fade-right" data-aos-duration="700">
              <h4>
                <span>Step</span> 1
              </h4>
              <h5>COLLECTION</h5>
              <p>
                All the collected waste (Dry and Wet) reaches the housekeeping
                staff from different stakeholders like housing societies,
                schools colleges, corporates, hotels etc. This waste has unclean
                plastic mixed with it. The house keeping segregates the clean
                plastic and sells it to the kabadiwala. The rest of the plastic
                which are mixed with wet waste, and takes time and energy to be
                cleaned are left aside, which goes to the garbage collection
                trucks eventually landing up in dumping ground.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <img src={STEP1} alt="" />
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-flow-row-dense md:grid-cols-3">
        <div className="col-span-2 md:col-span-1">
          <img src={STEP2} alt="" className="h-full" />
        </div>
        <div className="col-span-2">
          <div className="process-step white">
            <div data-aos="fade-left" data-aos-duration="700">
              <h4>
                <span>Step</span> 2
              </h4>
              <h5>COLLECTION</h5>
              <p>
                All the collected waste (Dry and Wet) reaches the housekeeping
                staff from different stakeholders like housing societies,
                schools colleges, corporates, hotels etc. This waste has unclean
                plastic mixed with it. The house keeping segregates the clean
                plastic and sells it to the kabadiwala. The rest of the plastic
                which are mixed with wet waste, and takes time and energy to be
                cleaned are left aside, which goes to the garbage collection
                trucks eventually landing up in dumping ground.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-flow-row-dense md:grid-cols-3">
        <div className="col-span-2">
          <div className="process-step">
            <div data-aos="fade-right" data-aos-duration="700">
              <h4>
                <span>Step</span> 3
              </h4>
              <h5>CLEANING AND SHREDDING/FLAKING</h5>
              <p>
                After the plastic reaches to the recycler all the plastic is
                sorted again, cleaned with large amount of water and then
                shredded into small pieces. The shredder then converts the
                plastic into flakes. Once that is done, the flakes are then
                subjected to further washing, drying and are ready to be shipped
                to the manufacturing units for making several kinds of recycled
                products.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <img src={STEP3} alt="" />
        </div>
      </div>
      <div className="recycled-plastic">
        <h6>
          PRESENTING TRANSFORMED
          <br /> RECYCLED PLASTICS
        </h6>
        <ul>
          <li>
            <img src={PRODUCT1} alt="" />
          </li>
          <li>
            <img src={PRODUCT2} alt="" />
          </li>
          <li>
            <img src={PRODUCT3} alt="" />
          </li>
          <li>
            <img src={PRODUCT4} alt="" />
          </li>
          <li>
            <img src={PRODUCT5} alt="" />
          </li>
          <li>
            <img src={PRODUCT6} alt="" />
          </li>
          <li>
            <img src={PRODUCT7} alt="" />
          </li>
          <li>
            <img src={PRODUCT8} alt="" />
          </li>
        </ul>
      </div>
      <div className="recycle-plastic-reason">
        <h6>REASONS TO RECYCLE PLASTIC</h6>
        <ul>
          <li>
            <img src={CONSERVES_NATURAL} alt="" />
            <span>Conserves Natural Resources</span>
          </li>
          <li>
            <img src={SAVES_LANDFILL} alt="" />
            <span>Saves Landfill Space</span>
          </li>
          <li>
            <img src={REDUCE_CO2} alt="" />
            <span>Reduces CO2 Emission</span>
          </li>
          <li>
            <img src={PRESERVES_FLORA} alt="" />
            <span>Preserves Flora and Fauna</span>
          </li>
          <li>
            <img src={JOB_CREATION} alt="" />
            <span>Job Creation</span>
          </li>
        </ul>
      </div>
      {/* <div className="footer">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="mb-4 md:mb-0">
            <ul>
              <li>
                <Link to="/about-pet">Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link to="/about-bfc">
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link to="/milestone-initative">Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
              <li>
                <Link to="/PartnershipPower">Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <a
              className="social-icon"
              href="https://www.facebook.com/bottles4change/"
              target="_blank"
            >
              <img src={FACEBOOK} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
              target="_blank"
            >
              <img src={TWITTER} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/bottles_4_change/?hl=en"
              target="_blank"
            >
              <img src={INSTAGRAM} alt="" className="ico" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div> */}
      <Footer />
      <FooterBottom />
    </div>
  );
}
