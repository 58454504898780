import React from 'react';

const PhoneNumberLink = ({ phoneNumber }) => {
  const handlePhoneNumberClick = () => {
    debugger
    // Use window.location.href to navigate to the phone dialer
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div onClick={handlePhoneNumberClick}>
      <p className="green-btn">
        Call{' '}
        <a href={`tel:${phoneNumber}`}>
        </a>
      </p>
    </div>
  );
};

export default PhoneNumberLink;
