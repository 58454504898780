import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import { Link, useLocation } from "react-router-dom";
import Survey from "../../components/Survey/Survey";
import "./SurveyTaken.css";
import { getRequestorDashboardViewDet } from "../../api/requestorApi/sfdx_requestor_apis";
const { BACK_BUTTON } = require("../../utils/imagepath");

export default function SurveyTaken() {
  const location = useLocation();
  const [surveyList, setSurveyList] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const { filter, startDate, endDate, duration, user_id } =
    location.state || {};
  useEffect(() => {
    callAPI();
  });
  let start_date = "";
  let end_date = "";
  const callAPI = async () => {
    debugger;
    try {
      if (startDate && endDate) {
        start_date = startDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        end_date = endDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }

      let data = await getRequestorDashboardViewDet(
        user_id,
        duration,
        filter,
        start_date,
        end_date,
        currentPage
      );
      console.log("Ticket Raise API Calling Data-->", data);
      setSurveyList(data.survey_taken);
    } catch (error) {
      console.log("Exceprion in API Calling - ", error);
    }
  };
  //const filtered = surveyList.filtered
  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-wrap">
          <h1>
            <Link to="/Dashboard">
              <img src={BACK_BUTTON} alt="" />
            </Link>
            Survey Taken
          </h1>
          {surveyList ? (
            surveyList.map((item, index) => (
              <div>
                <Survey
                  subject={item.Title__c}
                  description={item.Description__c}
                  Survey_link={item.Survey_Link__c}
                  date={item.CreatedDate.split("T")[0]}
                  stage={item.Stage__c}
                />
              </div>
            ))
          ) : (
            <div>Data Not Available</div>
          )}
          {/* <Survey subject={item.subject} description={item.description} Survey_link={item.survey_link} date={item.date} stage={item.stage}/> */}
          {/* <div className='survey-taken'>
                        <div style={{ paddingRight: '0.5rem', paddingTop: '0.25rem' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8.25 10C7.83579 10 7.5 10.3358 7.5 10.75C7.5 11.1642 7.83579 11.5 8.25 11.5C8.66421 11.5 9 11.1642 9 10.75C9 10.3358 8.66421 10 8.25 10ZM7.5 16.25C7.5 15.8358 7.83579 15.5 8.25 15.5C8.66421 15.5 9 15.8358 9 16.25C9 16.6642 8.66421 17 8.25 17C7.83579 17 7.5 16.6642 7.5 16.25ZM3 6.25C3 4.45507 4.45507 3 6.25 3H17.75C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25ZM6 10.75C6 11.9926 7.00736 13 8.25 13C9.49264 13 10.5 11.9926 10.5 10.75C10.5 9.50736 9.49264 8.5 8.25 8.5C7.00736 8.5 6 9.50736 6 10.75ZM8.25 14C7.00736 14 6 15.0074 6 16.25C6 17.4926 7.00736 18.5 8.25 18.5C9.49264 18.5 10.5 17.4926 10.5 16.25C10.5 15.0074 9.49264 14 8.25 14ZM12 10.75C12 11.1642 12.3358 11.5 12.75 11.5H17.25C17.6642 11.5 18 11.1642 18 10.75C18 10.3358 17.6642 10 17.25 10H12.75C12.3358 10 12 10.3358 12 10.75ZM12.75 15.5C12.3358 15.5 12 15.8358 12 16.25C12 16.6642 12.3358 17 12.75 17H17.25C17.6642 17 18 16.6642 18 16.25C18 15.8358 17.6642 15.5 17.25 15.5H12.75ZM6 6.25C6 6.66421 6.33579 7 6.75 7H17.25C17.6642 7 18 6.66421 18 6.25C18 5.83579 17.6642 5.5 17.25 5.5H6.75C6.33579 5.5 6 5.83579 6 6.25Z" fill="#009E8C"/>
                            </svg> 
                        </div>                         
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h4>Plastic Collection Survey</h4>
                                <span>17 Oct, 2023</span>
                            </div>   
                            <p>We value your input! We've created a short survey to gather insights on plastic collection in Mumbai.</p>                         
                            <Link className='green-btn'>View</Link>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          <LeaderboardScore />
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            <ProfileCard />
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
}
