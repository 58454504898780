/**
 * Author : Himanshu Tripathi
 * description: entry point for the react WebApp
 */
import LandingComponent from "./components/LandingPage/LandingPage";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import TicketRaised from "./pages/TicketRaised/TicketRaised";
import TicketSolved from "./pages/TicketSolved/TicketSolved";
import TicketInProgress from "./pages/TicketInProgress/TicketInProgress";
import FeedbackGiven from "./pages/FeedbackGiven/FeedbackGiven";
import HomePage from "./components/User/User";
import SelectTypeSignup from "./components/Signup/SelectType";
// import { Provider as StoreProvider } from "react-redux";
import Home from "./components/Home/home";
import Community from "./components/Community/community";
import Corporate from "./components/Corporate/Corporate";
import SchoolCollage from "./components/SchoolCollage/SchoolCollage";
import Partnership from "./components/Partnership/Partnership";
import PartnershipPower from "./components/PartnershipPower/PartnershipPower";
import MilestoneInitative from "./components/MilestoneInitative/MilestoneInitative";
import AboutPet from "./components/AboutPet/AboutPet";
import AboutBfc from "./components/AboutBFC/AboutBFC";
import "./app.css";
import HelpSupport from "./components/Sidebar/help_support/help_support";
import FAQ from "./components/Sidebar/faq/faq";
import IndividualSignUp from "./components/Signup/individual/individual";
import OrgsSignUp from "./components/Signup/orgs/orgs";
//import FeedbackPage from './pages/FeedbackPage/FeedbackPage'
//import NewFeedback from './pages/NewFeedback/NewFeedback'
import HardPlasticCollected from "./pages/HardPlasticCollected/HardPlasticCollected";
import SoftPlasticCollected from "./pages/SoftPlasticCollected/SoftPlasticCollected";
import SurveyTaken from "./pages/SurveyTaken/SurveyTaken";
import NewTicket from "./pages/NewTicket/NewTicket";
import RequesterProfile from "./pages/RequesterProfile/RequesterProfile";
import MyTickets from "./pages/MyTickets/MyTickets";
import AllSurvey from "./pages/AllSurvey/AllSurvey";
import CollectorDashboard from "./pages/Collector/Dashboard/CollectorDashboard";
import Resource from "./pages/Resources/Resources";
import NewResource from "./pages/NewResource/NewResource";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import FeedbackPage from "./pages/FeedbackPage/FeedbackPage";
import NewFeedback from "./pages/NewFeedback/NewFeedback";
import UploadDocuments from "./pages/UploadDocuments/UploadDocuments";
import NotFoundComponent from "./pages/NotFound/NotFoundComponent";
import AllNotification from "./pages/AllNotification/AllNotification";
import HelpCases from "./components/Sidebar/help_support/help_cases";
import Privacy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./components/TermsCondition/TermsCondition";
import "./app.css";

import PrivateRoute from "./PrivateRoute";
import DocumentSubmitted from "./pages/documentSubmitted/documentSubmitted";

const App = () => {
  return (
    // <StoreProvider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<LandingComponent />} />
        <Route path="/login" element={<Login />} />
        <Route path="/SelectTypeSignup" element={<SelectTypeSignup />} />

        <Route path="/individual" element={<IndividualSignUp />} />
        <Route path="/orgs" element={<OrgsSignUp />} />
        <Route path="/documents" element={<UploadDocuments />} />

        <Route path="/home" element={<Home />} />

        <Route
          path="/HomePage"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/TicketRaised"
          element={
            <PrivateRoute>
              <TicketRaised />
            </PrivateRoute>
          }
        />
        <Route
          path="/TicketSolved"
          element={
            <PrivateRoute>
              <TicketSolved />
            </PrivateRoute>
          }
        />
        <Route
          path="/underApproval"
          element={
            <PrivateRoute>
              <DocumentSubmitted />
            </PrivateRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <FAQ />
            </PrivateRoute>
          }
        />
        <Route
          path="/TicketInProgress"
          element={
            <PrivateRoute>
              <TicketInProgress />
            </PrivateRoute>
          }
        />
        <Route
          path="/FeedbackGiven"
          element={
            <PrivateRoute>
              <FeedbackGiven />
            </PrivateRoute>
          }
        />
        <Route
          path="/help_support"
          element={
            <PrivateRoute>
              <HelpSupport />
            </PrivateRoute>
          }
        />

        <Route
          path="/Feedback"
          element={
            <PrivateRoute>
              <FeedbackPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/NewFeedback"
          element={
            <PrivateRoute>
              <NewFeedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/HardPlasticCollected"
          element={
            <PrivateRoute>
              <HardPlasticCollected />
            </PrivateRoute>
          }
        />
        <Route
          path="/SoftPlasticCollected"
          element={
            <PrivateRoute>
              <SoftPlasticCollected />
            </PrivateRoute>
          }
        />
        <Route
          path="/SurveyTaken"
          element={
            <PrivateRoute>
              <SurveyTaken />
            </PrivateRoute>
          }
        />
        <Route
          path="/NewTicket"
          element={
            <PrivateRoute>
              <NewTicket />
            </PrivateRoute>
          }
        />
        <Route
          path="/help_cases"
          element={
            <PrivateRoute>
              <HelpCases />
            </PrivateRoute>
          }
        />
        <Route
          path="/RequesterProfile"
          element={
            <PrivateRoute>
              <RequesterProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/MyTickets"
          element={
            <PrivateRoute>
              <MyTickets />
            </PrivateRoute>
          }
        />
        <Route
          path="/AllSurvey"
          element={
            <PrivateRoute>
              <AllSurvey />
            </PrivateRoute>
          }
        />

        <Route
          path="/collector_dashboard"
          element={
            <PrivateRoute>
              <CollectorDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/Resource"
          element={
            <PrivateRoute>
              <Resource />
            </PrivateRoute>
          }
        />
        <Route
          path="/NewResource"
          element={
            <PrivateRoute>
              <NewResource />
            </PrivateRoute>
          }
        />
        <Route
          path="/AllNotification"
          element={
            <PrivateRoute>
              <AllNotification />
            </PrivateRoute>
          }
        />
        <Route
          path="/ForgotPassword"
          element={
            <PrivateRoute>
              <ForgotPassword />
            </PrivateRoute>
          }
        />
        <Route path="/Community" element={<Community />} />
        <Route path="/Corporate" element={<Corporate />} />
        <Route path="/school-collage" element={<SchoolCollage />} />
        <Route path="/Partnership" element={<Partnership />} />
        <Route path="/PartnershipPower" element={<PartnershipPower />} />
        <Route path="/milestone-initative" element={<MilestoneInitative />} />
        <Route path="/about-pet" element={<AboutPet />} />
        <Route path="/about-bfc" element={<AboutBfc />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </Router>
    // </StoreProvider>
  );
};

export default App;
