import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaCaretDown } from "react-icons/fa";
import CountUp from "react-countup";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import "./home.css";
import Footer from "../Footer/Footer";
import FooterBottom from "../FooterBottom/FooterBottom";
const {
  WHITE_LOGO,
  PROGRAMME,
  PROGRAMME_MOBILE,
  CATEGORY_BANNER,
  CATEGORY_BANNER_MOBILE,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
} = require("../../utils/imagepath");

export default function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    // <div className="flex flex-row bg-white-500">
    //   <div className="flex-1">
    //     <img
    //       style={{ height: "100%", width: "100%" }}
    //       src={BACK_IMAGE}
    //       alt=""
    //     />
    //   </div>
    //   <div className="flex-1">
    //     {/* <Link to='/'>
    //            <img
    //            src={CROSS_ICON}
    //            style={{ marginLeft: 'auto', marginRight: '5%', marginTop: '5%' }}
    //            alt="Cross Icon"
    //            />
    //        </Link> */}
    //     <div className="home-right-section mt-10">
    //       <img src={LOGO} width={300} alt="" />
    //     </div>
    //     <div className="home-right-section mt-8">
    //       <img src={HOME_BACK} alt="" />
    //     </div>
    //     <div className="pt-5">
    //       <p
    //         className="home-right-section"
    //         style={{ color: "#000", fontSize: 18, fontWeight: "bold" }}
    //       >
    //         Plastic is not Waste
    //       </p>
    //       <div className="home-right-section">
    //         <p
    //           style={{
    //             color: "#000",
    //             fontSize: 18,
    //             fontWeight: "bold",
    //             marginRight: 5,
    //           }}
    //         >
    //           It's a
    //         </p>
    //         <p style={{ color: "green", fontSize: 18, fontWeight: "bold" }}>
    //           Valuable Resource
    //         </p>
    //       </div>
    //       <div className="home-right-section py-10">
    //         <a
    //           href="https://firebasestorage.googleapis.com/v0/b/bisleri-320a2.appspot.com/o/PDF%2FknowMore.pdf?alt=media&token=38ef0b06-fa68-431a-a105-812abf38febd"
    //           target="_blank"
    //           className="link-text"
    //         >
    //           Know More
    //         </a>
    //       </div>
    //     </div>
    //     <div className="home-right-section" style={{ marginTop: "1rem" }}>
    //       <Link
    //         to="/login"
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           backgroundColor: "#feed42",
    //           color: "#000",
    //           fontWeight: "bold",
    //           borderRadius: 10,
    //           width: "23%",
    //           height: 40,
    //         }}
    //       >
    //         Login
    //       </Link>
    //       <Link
    //         to="/SelectTypeSignup"
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           backgroundColor: "#00a990",
    //           color: "black",
    //           width: "23%",
    //           borderRadius: 10,
    //           color: "#fff",
    //           fontWeight: "bold",
    //           marginLeft: "5%",
    //         }}
    //       >
    //         Register with us
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="home">
      <div className="banner">
        <div className="main-header">
          <Link className="logo">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn" to="/Login">
            Login/Signup
          </Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/Partnership">
            <FaBars className="hamburger" />
          </Link>
        </div>
        <h1 data-aos="fade-up" data-aos-duration="700">
          USED PLASTIC IS NOT WASTE.{" "}
          <span>IT HAS VALUE. IT IS RECYCLABLE.</span>
        </h1>
        <Link
          className="discover-btn"
          data-aos="fade-up"
          data-aos-duration="700"
          to="/about-pet"
        >
          Discover the truth
        </Link>
      </div>
      <div className="divider"></div>
      <div className="programmee">
        <img src={PROGRAMME} alt="" className="programme-banner" />
        <img
          src={PROGRAMME_MOBILE}
          alt=""
          className="programme-banner-mobile"
        />
        <div className="transparent">
          <h2 data-aos="fade-up" data-aos-duration="700">
            INTRODUCING BOTTLES FOR CHANGE
          </h2>
          <div data-aos="fade-up" data-aos-duration="700">
            <p>
              BOTTLES FOR CHANGE aims to create awareness among citizens about
              importance of plastic recycling.
            </p>
            <div>
              <Link className="discover-btn">Explore the Programme</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="category-section">
        <img src={CATEGORY_BANNER} alt="" className="programme-banner" />
        <img
          src={CATEGORY_BANNER_MOBILE}
          alt=""
          className="programme-banner-mobile"
        />
        <div className="transparent">
          <h3>
            <CountUp
              start={0}
              end={4800}
              duration={5}
              delay={2}
              className="count"
            />{" "}
            TONNES
            <span>Of Plastic Recycled</span>
          </h3>
          <div className="flex flex-row">
            <div className="flex-2 column-pad">
              <h3>
                <CountUp
                  start={0}
                  end={500000}
                  duration={5}
                  delay={2}
                  className="count"
                />
                <span>INDIVIDUALS REACHED</span>
              </h3>
            </div>
            <div className="flex-2">
              <h3>
                <CountUp
                  start={0}
                  end={300000}
                  duration={5}
                  delay={2}
                  className="count"
                />
                <span>STUDENTS REACHED</span>
              </h3>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-2 column-pad">
              <h3>
                <CountUp
                  start={0}
                  end={600}
                  duration={5}
                  delay={2}
                  className="count"
                />
                <span>HOUSING SOCIETIES ENROLLED</span>
              </h3>
            </div>
            <div className="flex-2">
              <h3>
                <CountUp
                  start={0}
                  end={300}
                  duration={5}
                  delay={2}
                  className="count"
                />
                +<span>CORPORATES/HOTELS ENGAGED</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="mb-4 md:mb-0">
            <ul>
              <li>
                <Link to="/about-pet">Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link to="/about-bfc">
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link to="/milestone-initative">Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
              <li>
                <Link to="/PartnershipPower">Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <a
              className="social-icon"
              href="https://www.facebook.com/bottles4change/"
              target="_blank"
            >
              <img src={FACEBOOK} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
              target="_blank"
            >
              <img src={TWITTER} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/bottles_4_change/?hl=en"
              target="_blank"
            >
              <img src={INSTAGRAM} alt="" className="ico" />
            </a>
          </div>
        </div>
      </div> */}
      <Footer />
      <FooterBottom />
      {/* <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
