import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import Notification from '../../components/Notification/Notification';
import Feedback from '../../components/FeedbackGiven/FeedbackGiven';
import { getFeedback } from '../../api/requestorApi/sfdx_requestor_apis';
import './FeedbackPage.css';

import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";

const FeedbackPage = ({ auth, history }) => {
  const [feedback, setFeedback] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch sfId
        const sfIdData = await firebase
          .firestore()
          .collection("user")
          .doc(auth.uid)
          .get();
  
        console.log("FB Data ", sfIdData.data());
        const fetchedSfId = sfIdData.data().sf_id  
        // Fetch feedback using sfId
        debugger
        if (fetchedSfId) {
          const feedbackData = await getFeedback(fetchedSfId);
  
          if (feedbackData && feedbackData.myFeedbacks && Array.isArray(feedbackData.myFeedbacks)) {
            setFeedback(feedbackData.myFeedbacks);
            console.log('Feedack Response -- ',feedbackData);
          } else {
            console.error("Feedback data is not in the expected format:", feedbackData);
          }
        }
      } catch (error) {
        console.error("Error fetching feedbacks:", error);
      }
    };
  
    fetchData();
  }, [auth.uid]);
  
  console.log("feedback-------->", feedback);
  

  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className='mid-wrap'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1>Feedbacks</h1>
            <Link to='/NewFeedback' className="green-btn">
              New Feedback
            </Link>
          </div>
          {/* <Feedback feedbackData={feedback} /> */}
          {feedback?feedback.map((item,Key)=>(
            <Feedback feedbackData={item}  arrayInd={Key}/>
          )):<div>Data Not Available</div>}
          
        </div>
      </div>
      <div className="flex-2">
        <div className='right-section'>
          {/* <LeaderboardScore /> */}
          <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
      authError: state.auth.authError,
      auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(FeedbackPage);
