import { Link } from "react-router-dom";
import './ResourceCard.css'
import PhoneNumberLink from "../PhoneNumberLink/PhoneNumberLink";
import { useEffect, useState } from "react";

const ResourceCard=(props)=> {
    const { resourceData } = props;  
    const [fullAddress,setFullAddress]=useState('');
    useEffect(()=>{
        const street = resourceData.add_street || '';
        const city = resourceData.add_city || '';
        const state = resourceData.add_state || '';
        const country = resourceData.add_country || '';      
        const zipCode = resourceData.add_pincode || '';      
        // Concatenate address fields
        const cFullAddress = `${street}, ${city}, ${state} ${zipCode}, ${country}`;
        setFullAddress(cFullAddress);

    },[resourceData]);
    return (
        <div className="resource-card">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M15.9994 2.28613C8.42525 2.28613 2.28516 8.42623 2.28516 16.0004C2.28516 23.5746 8.42525 29.7147 15.9994 29.7147C23.5736 29.7147 29.7137 23.5746 29.7137 16.0004C29.7137 8.42623 23.5736 2.28613 15.9994 2.28613ZM15.9994 24.5718C12.2123 24.5718 9.1423 22.1229 9.1423 18.4494C9.1423 17.0969 10.2387 16.0004 11.5913 16.0004H20.4076C21.7601 16.0004 22.8566 17.0969 22.8566 18.4494C22.8566 22.1229 19.7865 24.5718 15.9994 24.5718ZM15.9994 14.2861C13.9481 14.2861 12.2852 12.6232 12.2852 10.5718C12.2852 8.5205 13.9481 6.85756 15.9994 6.85756C18.0508 6.85756 19.7137 8.5205 19.7137 10.5718C19.7137 12.6232 18.0508 14.2861 15.9994 14.2861Z" fill="#009E8C"/>
                </svg>
            </div>
            <div style={{ width: 'calc(100% - 7.5rem)', paddingLeft: '0.5rem' }}>
                <h2>{resourceData.first_name+' '+resourceData.last_name}</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className='info'>
                        <label>Email:</label> {resourceData.email}
                    </div>
                    <div className='info'>
                        <label>Phone:</label> +91 {resourceData.phone}
                    </div>
                    <div className='info'>
                        <label>Address: </label> 
                        {fullAddress}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                {/* <Link className="green-btn">Call</Link> */}
                {/* <Link className="green-btn" style={{ marginTop: '0.5rem' }}>View Details</Link> */}
                <PhoneNumberLink phoneNumber={resourceData.phone} />
            </div>
        </div>
    )
}
export default ResourceCard;