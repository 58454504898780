import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { FaBars, FaDownload, FaAngleLeft, FaAngleRight } from "react-icons/fa";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./MilestoneInitative.css";
import DownloadAppSection from "../../components/MobileApps/DownloadAppSection";
import FooterBottom from "../FooterBottom/FooterBottom";
const {
  WHITE_LOGO,
  PLAY_STORE,
  THUMBNAIL20,
  THUMBNAIL21,
  THUMBNAIL22,
  THUMBNAIL23,
  THUMBNAIL24,
  THUMBNAIL25,
} = require("../../utils/imagepath");

export default function MilestoneInitative() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <div className="milestone-initative">
        <div className="main-header">
          <Link className="logo">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn" to="/login">
            Login/Signup
          </Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/Partnership">
            <FaBars className="hamburger" />
          </Link>
        </div>
        <div className="content-section">
          <div className="info">
            <h1>MILESTONES & INITIATIVES</h1>
            <h2>BOTTLES FOR CHANGE Launch</h2>
            <p>
              On the World environment day i.e 5th June 2018, Bisleri launched
              “BOTTLES FOR CHANGE”, a Plastic Recycling initiative that focuses
              on efficient disposal and recycling of plastic. The idea is to
              create a positive impact on the environment, ensure that it is
              sustainable and emphasize on the fact that plastic is a versatile
              material, has an afterlife.This launch event was supported and
              graced by dignitaries like Mr. Ramdas Kadam, Minister of
              Environment, Government of Maharashtra, Mr. Aditya Thackeray,
              President Yuva Sena, among others.
            </p>
          </div>
          <div className="carousel">
            <Carousel
              infinite={true}
              containerClass="thumbnail-carousel"
              customLeftArrow={<FaAngleLeft className="arrow-left" />}
              customRightArrow={<FaAngleRight className="arrow-right" />}
              responsive={responsive}
            >
              <div className="item">
                <img src={THUMBNAIL20} alt="" />
              </div>
              <div className="item">
                <img src={THUMBNAIL21} alt="" />
              </div>
              <div className="item">
                <img src={THUMBNAIL22} alt="" />
              </div>
              <div className="item">
                <img src={THUMBNAIL23} alt="" />
              </div>
              <div className="item">
                <img src={THUMBNAIL24} alt="" />
              </div>
              <div className="item">
                <img src={THUMBNAIL25} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="video">
        <iframe
          className="bfcVideo"
          src="https://www.youtube.com/embed/8maVAt1osoQ?rel=0&amp;showinfo=0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
      {/* <div className="wrapper">
        <div className="app-section">
          <div className="app-img">
            <img src={PLAY_STORE} alt="" />
          </div>
          <div className="app-text">
            <h5>DOWNLOAD BOTTLES FOR CHANGE APP NOW</h5>
            <a href="#" className="download-btn">
              <FaDownload className="ico" /> Download the App
            </a>
            <p>
              With this app you can locate the nearest plastic agent
              (kabadiwala) in your area and put segregated plastic in the right
              channel. Segregated plastic will be picked by our plastic agent
              and then sent for recycling.
            </p>
          </div>
        </div>
      </div> */}
      <DownloadAppSection />
      <FooterBottom />
      {/* <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link>Terms & Conditions</Link>
          </li>
          <li>
            <Link>Privacy</Link>
          </li>
        </ul>
      </div> */}
    </>
  );
}
