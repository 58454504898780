import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signUp } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { STATE_OBJ, HOW_RELATED_OBJ } from "../../../utils/constants";
import validator from "validator";
import Alert from "../../ui_utilities/Alert";
import swal from "sweetalert";
import OTPVerification from "../../../components/OTPVerification/OTPVerification";
import { firebase } from "../../../config/fb_config";
import Swal from "sweetalert2";
import { sendOTP } from "../../../api/requestorApi/sfdx_requestor_apis";
import { FaEye } from "react-icons/fa";
const {
  BACK_IMAGE,
  BACK_BUTTON,
  CROSS_ICON,
} = require("../../../utils/imagepath");

const OrgsSignUp = ({ auth, authError, history, signUp, navigationLink }) => {
  const [otpRepsonse, setOTPResponse] = useState(null);
  const [otpScreen, setOTPScreen] = useState(false);
  const [relatedObj, setRelatedObj] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    orgName: "",
    howRelated: "",
    contactPerson: "",
    country: "IN",
    state: "KA",
    city: "",
    pincode: "",
    streetAddress: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: "on",
  });

  const [showAlert, setShowAlert] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  /* useEffect(() => {
        if (auth && auth.uid ) {
            return navigate("/dashboard");
        } else {
            return navigate("/login");
        }
    }, [auth, navigate]); */

  useEffect(() => {
    debugger;
    let titlkeEffect = new URLSearchParams(location.search).get("title");
    const userTypeEffect = new URLSearchParams(location.search).get("userType");

    if (userTypeEffect === "collector") {
      setRelatedObj(HOW_RELATED_OBJ.COLLECTOR);
    } else {
      switch (titlkeEffect.toLowerCase()) {
        case "corporates":
          setRelatedObj(HOW_RELATED_OBJ.HOTEL_AND_CORPORATE);
          break;
        case "hotels and restaurants":
          setRelatedObj(HOW_RELATED_OBJ.HOTEL_AND_CORPORATE);
          break;
        case "schools and colleges":
          setRelatedObj(HOW_RELATED_OBJ.SCHOOL_AND_INSTITUTION);
          break;
        case "housing society":
          setRelatedObj(HOW_RELATED_OBJ.HOUSING_SOCIETY);
          break;
        default:
          setRelatedObj(HOW_RELATED_OBJ.HOTEL_AND_CORPORATE);
      }
    }
    // if (titlkeEffect.toLowerCase() === "ngo-social enterprise") {
    //   titlkeEffect = "ngo";
    //   setRelatedObj(HOW_RELATED_OBJ.NGO);
    // } else if (titlkeEffect.toLowerCase() === "plastic agents") {
    //   titlkeEffect = "plastic_agent";
    // }
    debugger;
    if (titlkeEffect.toLowerCase() === "ngo-social enterprise") {
      titlkeEffect = "ngo";
      // setRelatedObj(HOW_RELATED_OBJ.NGO);
    } else if (titlkeEffect.toLowerCase() === "plastic agents") {
      titlkeEffect = "plastic_agent";
    } else if (titlkeEffect.toLowerCase() === "hotels and restaurants") {
      titlkeEffect = "hotels";
    } else if (titlkeEffect.toLowerCase() === "schools and colleges") {
      titlkeEffect = "school";
    } else if (titlkeEffect.toLowerCase() === "housing society") {
      titlkeEffect = "housing_society";
    }
    setShowAlert(authError ? true : false);
  }, [authError]);

  useEffect(() => {
    if (navigationLink) {
      return navigate(navigationLink);
    }
  }, [navigationLink]);
  const handleSendOTP = async () => {
    const mobileNumber = "+91" + user.phoneNumber;
    let mobile_json = {
      phone: mobileNumber,
    };
    try {
      const result = await sendOTP(mobile_json);
      console.log("OTP API Response", result);
      //console.log("OTP-->", data.OTP);
      if (result.Status !== "Success") {
        Swal.fire({
          title: "Send OTP",
          text: "Opps! Something went wrong, Unable to send OTP",
          icon: "error",
        });
        return;
      }
      //setOTPResponse(data.OTP);
      setOTPScreen(true);
      return true;
    } catch (error) {
      console.error("Error sending OTP:", error);
      //setOTPResponse({ error: "Error sending OTP" });
      swal({
        title: "Registration",
        text: "Something went wrong while sending OTP, please try again",
        icon: "error",
      });
      return false;
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleNewPassword = () => {
    setNewShowPassword(!showNewPassword);
  };
  const handleChange = (e) => {
    debugger;
    if (e.target.id === "phoneNumber") {
      const inputValue = e.target.value;
      if (!isNaN(inputValue) && inputValue.length <= 10) {
        setUser({
          ...user,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setUser({
        ...user,
        [e.target.id]: e.target.value,
      });
    }
    console.log(e.target.id, " ", e.target.value);
  };

  const handleFormSubmit = async () => {
    //TODO: signup into firebase and Salesforce
    // cut from here
    if (validator.isEmpty(user.firstName)) {
      swal({
        title: "Registration",
        text: "Please enter first name",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.lastName)) {
      swal({
        title: "Registration",
        text: "Please enter last name",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.orgName)) {
      swal({
        title: "Registration",
        text: "Please organisation type",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.howRelated) || user.howRelated === "None") {
      swal({
        title: "Registration",
        text: "Please select, how are you related?",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.contactPerson)) {
      swal({
        title: "Registration",
        text: "Please enter contact person name",
        icon: "error",
      });
      return;
    }
    debugger;
    if (validator.isEmpty(user.state)) {
      swal({
        title: "Registration",
        text: "Please choose a state",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.city)) {
      swal({
        title: "Registration",
        text: "Please enter the city",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.pincode)) {
      swal({
        title: "Registration",
        text: "Please enter pincode",
        icon: "error",
      });
      return;
    } else {
      try {
        console.log("User Pincode:", user.pincode);
        const pincodeRef = firebase.firestore().collection("pincodes");
        const querySnapshot = await pincodeRef.get();

        if (querySnapshot.empty) {
          console.log("Pincode collection is empty.");
          Swal.fire({
            title: "Pincode",
            text: "Pincode collection is empty",
            icon: "error",
          });
          return; // Added return statement to exit the function
        }

        let pincodeFound = false;

        querySnapshot.forEach((doc) => {
          const firestorePincode = doc.data().pincode;
          console.log("Checking Firestore Pincode:", firestorePincode);
          if (firestorePincode === user.pincode) {
            console.log("Pincode found in Firestore.");
            pincodeFound = true;
          }
        });

        if (!pincodeFound) {
          console.log("Pincode does not match in Firestore.");
          Swal.fire({
            title: "Pincode",
            text: "We are not working in this pincode area.",
            icon: "error",
          });
          return;
        }
      } catch (error) {
        console.error("Error checking pincode:", error);
        Swal.fire({
          title: "Pincode",
          text: "Opps! Something went wrong while validating pincode, Please try agian.",
          icon: "error",
        });
        return;
      }
    }
    if (validator.isEmpty(user.streetAddress)) {
      swal({
        title: "Registration",
        text: "Please enter street address",
        icon: "error",
      });
      return;
    }
    if (!validator.isMobilePhone(user.phoneNumber)) {
      swal({
        title: "Registration",
        text: "Please enter a valid phone",
        icon: "error",
      });
      return;
    }
    if (!user.phoneNumber || user.phoneNumber.length !== 10) {
      swal({
        title: "Registration",
        text: "Mobile number must be of 10 digits",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.phoneNumber)) {
      swal({
        title: "Registration",
        text: "Please enter phone number",
        icon: "error",
      });
      return;
    }
    if (user.email === "" || user.email === " ") {
      setUser((prevState) => ({
        ...prevState,
        email: user.phoneNumber + "@bfc.com",
      }));
    } else if (!validator.isEmail(user.email)) {
      swal({
        title: "Registration",
        text: "Please enter a valid email",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.password)) {
      swal({
        title: "Registration",
        text: "Please enter the password",
        icon: "error",
      });
      return;
    }
    if (validator.isEmpty(user.confirmPassword)) {
      swal({
        title: "Registration",
        text: "Please enter the confirm password",
        icon: "error",
      });
      return;
    }
    if (user.password.length < 6 || user.confirmPassword < 6) {
      Swal.fire({
        title: "Registration",
        text: "Password must be six character long.",
        icon: "error",
      });
      return;
    }
    if (user.confirmPassword !== user.password) {
      swal({
        title: "Registration",
        text: "Password does not matched",
        icon: "error",
      });
      return;
    }
    if (user.agreeToTerms === "off") {
      swal({
        title: "Registration",
        text: "Please agree to terms & conditions",
        icon: "error",
      });
      return;
    }
    debugger;
    try {
      await firebase
        .firestore()
        .collection("user")
        .where("phone", "==", user.phoneNumber)
        .get()
        .then((fbResponse) => {
          console.log("Object -->", fbResponse.docs);
          console.log("Object -->", fbResponse.docs.length);
          if (fbResponse.docs.length > 0) {
            console.log(fbResponse.docs[0].data().email);
            swal({
              title: "Registration",
              text: "This mobile number has already been registred with us.",
              icon: "error",
            });
            return;
          } else {
            //handleSendOTP();
            validateEmailId();
          }
        })
        .catch((err) => {
          console.log("Querying data from the firebase", err);
          swal({
            title: "Registration",
            text: "Something went wrong, please try again",
            icon: "error",
          });
          return;
        });
    } catch (error) {
      console.log("Exceptiob ocured-->", error);
      swal({
        title: "Registration",
        text: "Something went wrong, please try again",
        icon: "error",
      });
      return;
    }

    //handleSendOTP();
    //TODO: add other validations
    /**
     *
     */
  };
  const validateEmailId = async () => {
    try {
      await firebase
        .firestore()
        .collection("user")
        .where("email", "==", user.email)
        .get()
        .then((fbResponse) => {
          console.log("Object -->", fbResponse.docs);
          console.log("Object -->", fbResponse.docs.length);
          if (fbResponse.docs.length > 0) {
            console.log(fbResponse.docs[0].data().email);
            swal({
              title: "Registration",
              text: "This email ID has already been registred with us.",
              icon: "error",
            });
            return;
          } else {
            handleSendOTP();
          }
        })
        .catch((err) => {
          console.log("Querying data from the firebase", err);
          swal({
            title: "Registration",
            text: "Something went wrong, please try again",
            icon: "error",
          });
          return;
        });
    } catch (error) {
      console.log("Exceptiob ocured-->", error);
      swal({
        title: "Registration",
        text: "Something went wrong, please try again",
        icon: "error",
      });
      return;
    }
  };
  const submitFrom = () => {
    //TODO: signup into firebase and Salesforce
    //TODO: add other validations
    /**
     *
     */
    let titlekl = new URLSearchParams(location.search).get("title");
    const userType = new URLSearchParams(location.search).get("userType");
    //   if(userType==="collector"){
    //     setRelatedObj(HOW_RELATED_OBJ.COLLECTOR);
    //   }
    //   else{
    //     switch(titlekl.toLowerCase()){
    //         case 'corporates':
    //             setRelatedObj(HOW_RELATED_OBJ.HOTEL_AND_CORPORATE);
    //             break;
    //         case 'hotels and restaurants':
    //             setRelatedObj(HOW_RELATED_OBJ.HOTEL_AND_CORPORATE);
    //             break;
    //         case 'schools and colleges':
    //             setRelatedObj(HOW_RELATED_OBJ.SCHOOL_AND_INSTITUTION);
    //             break;
    //         case 'housing society':
    //                 setRelatedObj(HOW_RELATED_OBJ.HOUSING_SOCIETY);
    //                 break;
    //         default:
    //             setRelatedObj(HOW_RELATED_OBJ.HOTEL_AND_CORPORATE);
    //     }
    //   }
    if (titlekl.toLowerCase() === "ngo-social enterprise") {
      titlekl = "ngo";
      // setRelatedObj(HOW_RELATED_OBJ.NGO);
    } else if (titlekl.toLowerCase() === "plastic agents") {
      titlekl = "plastic_agent";
    } else if (titlekl.toLowerCase() === "hotels and restaurants") {
      titlekl = "hotels";
    } else if (titlekl.toLowerCase() === "schools and colleges") {
      titlekl = "school";
    } else if (titlekl.toLowerCase() === "housing society") {
      titlekl = "housing_society";
    }

    let sfRequestor = {
      user_type: userType,
      org_type: titlekl.toLowerCase(),
      first_name: user.firstName,
      last_name: user.lastName,
      org_name: user.orgName,
      org_relation: user.howRelated,
      contact_person: user.contactPerson,
      email_id: user.email,
      add_city: user.city,
      add_street: user.streetAddress,
      add_state: user.state,
      add_country: user.country,
      add_pincode: user.pincode,
      phone: user.phoneNumber,
      password: user.password,
    };
    console.log("rquest user data=>", sfRequestor);
    debugger;
    signUp(sfRequestor, userType === "requestor" ? "Active" : "New");
    console.log("Submitting form:", user);
  };
  const title = new URLSearchParams(location.search).get("title");

  const handleAlerts = (alertType) => (event) => {
    if (alertType === "CLOSE") setShowAlert(false);
  };
  const setCurrentScreen = () => {
    debugger;
    setOTPScreen(false);
  };
  return (
    <div>
      {!otpScreen ? (
        <div className="flex flex-row bg-white-500">
          <div className="flex-1">
            <img
              style={{ height: "100%", width: "100%" }}
              src={BACK_IMAGE}
              alt=""
            />
          </div>
          <div className="flex-1">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "2.5rem 0",
                justifyContent: "space-between",
              }}
            >
              <Link to="/SelectTypeSignup">
                <img
                  style={{ marginLeft: 40 }}
                  src={BACK_BUTTON}
                  alt="Cross Icon"
                />
              </Link>
              <div className="flex justify-center items-center">
                <p
                  style={{
                    color: "#00a990",
                    fontWeight: "500",
                    fontSize: 24,
                  }}
                >
                  {title}
                </p>
              </div>
              <Link to="/">
                <img
                  style={{ marginRight: 40 }}
                  src={CROSS_ICON}
                  alt="Cross Icon"
                />
              </Link>
            </div>
            <div
              style={{
                width: "88%",
                margin: "0 auto",
                paddingBottom: "2rem",
              }}
            >
              {showAlert ? (
                <Alert
                  alert={{
                    header: authError.header,
                    message: authError.msg,
                    className: authError.color,
                  }}
                  clickHandler={handleAlerts}
                />
              ) : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <label>
                    First Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    value={user.firstName}
                    id="firstName"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. John"
                  />
                </div>
                <div className="flex-1">
                  <label>
                    Last Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    value={user.lastName}
                    id="lastName"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. Don"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <label>
                    Name of {title}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    value={user.orgName}
                    id="orgName"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. Assaan Services"
                  />
                </div>
                <div className="flex-1">
                  <label>
                    How are you related?<span className="text-red-600">*</span>
                  </label>
                  {/* <input
                                type="text"
                                id="howRelated"
                                value={user.howRelated}
                                onChange={handleChange}
                                className="form-control"
                            /> */}
                  <select
                    id="howRelated"
                    className="block w-full px-4 py-2 mt-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    value={user.howRelated}
                    onChange={handleChange}
                  >
                    <option value="">--None--</option>
                    {Object.entries(relatedObj).map(([code, name]) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <label>
                    Contact Person<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="contactPerson"
                    value={user.contactPerson}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. Sam Doe"
                  />
                </div>
                <div className="flex-1">
                  <label>
                    Street Address<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="streetAddress"
                    value={user.streetAddress}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. 4th cross road, JP Nagar"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <label>
                    Country<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    value={"India"}
                    id="country"
                    onChange={handleChange}
                    className="form-control"
                    disabled={true}
                  />
                </div>
                <div className="flex-1">
                  <label>
                    State<span className="text-red-600">*</span>
                  </label>
                  {/* <input
                                type="text"
                                id="state"
                                value={user.state}
                                onChange={handleChange}
                                className="form-control"
                            /> */}
                  <select
                    id="state"
                    className="block w-full px-4 py-2 mt-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    value={user.state}
                    onChange={handleChange}
                  >
                    <option value="">--None--</option>
                    {Object.entries(STATE_OBJ).map(([code, name]) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <label>
                    City<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    value={user.city}
                    id="city"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. Bengaluru"
                  />
                </div>
                <div className="flex-1">
                  <label>
                    Pincode<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    value={user.pincode}
                    id="pincode"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="eg. 560098"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <label>
                    Phone Number<span className="text-red-600"> *</span>
                  </label>
                  <input
                    type="number"
                    id="phoneNumber"
                    value={user.phoneNumber}
                    onChange={handleChange}
                    maxLength={10}
                    className="form-control"
                    placeholder="eg. 9999999999"
                  />
                </div>
                <div className="flex-1">
                  <label>Email Id</label>
                  <input
                    type="text"
                    id="email"
                    value={user.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="johndoe@example.com"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1" style={{ position: "relative" }}>
                  <label>
                    Create Password<span className="text-red-600">*</span>
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={user.password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="********"
                  />
                  <FaEye
                    onClick={handleTogglePassword}
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: 42,
                      right: 12,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="flex-1" style={{ position: "relative" }}>
                  <label>
                    Confirm Password<span className="text-red-600">*</span>
                  </label>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={user.confirmPassword}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="********"
                  />
                  <FaEye
                    onClick={handleToggleNewPassword}
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: 42,
                      right: 12,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <div class="form-group">
                    <input
                      type="checkbox"
                      id="agreeToTerms"
                      checked={user.agreeToTerms === "on"}
                      onChange={handleChange}
                    />
                    <label for="agreeToTerms">I agree to</label>
                    <Link
                      to="/"
                      style={{ color: "#009E8C", fontWeight: "700" }}
                    >
                      Terms & Conditions
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                  gap: "1.25rem",
                }}
              >
                <div className="flex-1">
                  <button
                    disabled={!user.agreeToTerms}
                    onClick={handleFormSubmit}
                    className="green-btn"
                    style={{ width: "100%" }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <OTPVerification
            expectedOtp={otpRepsonse}
            callBackFn={submitFrom}
            email={user.email}
            phone={user.phoneNumber}
            calltoBackScreen={setCurrentScreen}
            callBaclSendOTP={handleSendOTP}
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    navigationLink: state.auth.navigationLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds, userType, status) => dispatch(signUp(creds, userType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgsSignUp);
