import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import Ticket from "../../components/Ticket/Ticket";
import { Link, useLocation } from "react-router-dom";
import "./TicketRaised.css";
import { keyframes } from "@emotion/react";
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
import { getCollectorDashboardViewDet } from "../../api/collectorApi/sfdx_collector_apis";
import { getRequestorDashboardViewDet } from "../../api/requestorApi/sfdx_requestor_apis";
const { BACK_BUTTON } = require("../../utils/imagepath");

const TicketRaised = ({ auth }) => {
  debugger;
  const [userType, setUserType] = useState(null);
  const location = useLocation();
  const [filteredTickets, setFilteredTickets] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const {
    caseList,
    ticketType,
    title,
    filter,
    startDate,
    endDate,
    duration,
    user_id,
  } = location.state || {};
  let start_date = "";
  let end_date = "";
  console.log("Ticket Raised-->", ticketType);
  console.log("Ticket Raised-->", filter);
  useEffect(() => {
    debugger;
    // if (ticketType === "raised") {
    //   const filteredTickets = caseList.filter(
    //     (ticket) => ticket.ticket_stage !== "Closedd"
    //   );
    //   //setFilteredTickets(filteredTickets);
    // } else if (ticketType === "progress") {
    //   const filteredTickets = caseList.filter(
    //     (ticket) => ticket.ticket_stage === "In-Progress"
    //   );
    //   //setFilteredTickets(filteredTickets);
    // } else if (ticketType === "overdue") {
    //   //setFilteredTickets(caseList);
    // } else if (ticketType === "New") {
    //   const filteredTickets = caseList.filter(
    //     (ticket) => ticket.ticket_stage === "New"
    //   );
    //   //setFilteredTickets(filteredTickets);
    // } else {
    //   const filteredTickets = caseList.filter(
    //     (ticket) => ticket.ticket_stage === "Closed"
    //   );
    //   //setFilteredTickets(filteredTickets);
    // }

    fetchData();
  }, [currentPage]);
  const callAPI = async (user_type) => {
    debugger;
    try {
      if (startDate && endDate) {
        start_date = startDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        end_date = endDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }
      let data = "";
      if (user_type === "requestor") {
        data = await getRequestorDashboardViewDet(
          user_id,
          duration,
          filter,
          start_date,
          end_date,
          currentPage
        );
      } else {
        data = await getCollectorDashboardViewDet(
          user_id,
          duration,
          filter,
          start_date,
          end_date,
          currentPage
        );
      }
      console.log("Ticket Raise API Calling Data-->", data);
      
      setFilteredTickets(data.caseList);
    } catch (error) {
      console.log("Exceprion in API Calling - ", error);
    }
  };
  const nextPage = () => {
    debugger;
    // setNextPaging((prevPage) => prevPage + 20);
    setCurrentPage((prevPage) => prevPage + 20);
    // getMyTicketsData(SFID, userType, currentPage);
    // handleTabClick("openTickets");
    // handleTabClick("resolvedTickets");
  };

  const prevPage = () => {
    debugger;
    // setCurrentPage((prevPage) => prevPage - 20);
    setCurrentPage((prevPage) => prevPage - 20);
    //getMyTicketsData(SFID, userType, currentPage);
  };
  const fetchData = async () => {
    try {
      // Fetch sfId
      const sfIdData = await firebase
        .firestore()
        .collection("user")
        .doc(auth.uid)
        .get();
      console.log("sidebar --- >", sfIdData.data());
      setUserType(sfIdData.data().user_type);
      if (user_id) {
        callAPI(sfIdData.data().user_type);
      }
    } catch (error) {
      console.error("Error fetching Tickets:", error);
    }
  };
  console.log("caseList -------------------->", caseList);

  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-wrap">
          {userType === "requestor" ? (
            <h1>
              <Link to="/Dashboard">
                <img src={BACK_BUTTON} alt="" />
              </Link>
              {title}
            </h1>
          ) : (
            <h1>
              <Link to="/collector_dashboard">
                <img src={BACK_BUTTON} alt="" />
              </Link>
              {title}
            </h1>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: 15,
              marginTop: 5,
            }}
          >
            <button
              onClick={prevPage}
              disabled={currentPage === 0}
              className="text-btn"
            >
              Previous
            </button>
            <button
              onClick={() => {
                nextPage();
              }}
              className="text-btn"
            >
              Next
            </button>
          </div>
          <div>
            {filteredTickets ? (
              filteredTickets.map((item, index) => (
                // React element for each item in the array
                <Ticket
                  subject={item.Subject ? item.Subject : ""}
                  address={
                    item.Address__Street__s +
                    " " +
                    item.Address__City__s +
                    " " +
                    item.Address__StateCode__s +
                    " " +
                    item.Address__CountryCode__s +
                    " " +
                    item.Address__PostalCode__s
                  }
                  ticketId={item.CaseNumber ? item.CaseNumber : 0}
                  rating_experience={
                    item.ratingExperiences ? item.ratingExperiences : null
                  }
                  rating_submitted_status={item.Rating_Submitted_Status__c}
                  dateRaised={
                    item.CreatedDate ? item.CreatedDate.split("T")[0] : ""
                  }
                  ticketAge={item.Case_Age__c ? item.Case_Age__c : 0}
                  stage={item.Stage__c ? item.Stage__c : "New"}
                  hardPlasticKg={
                    item.Request_Collections__r
                      ? item.Request_Collections__r.records.find(
                          (record) => record.Type__c === "Hard"
                        )?.Weight__c
                      : null
                  }
                  hardPlasticGm={
                    item.Request_Collections__r
                      ? item.Request_Collections__r.records.find(
                          (record) => record.Type__c === "Hard"
                        )?.Weight_in_Gram__c
                      : null
                  }
                  softPlasticKg={
                    item.Request_Collections__r
                      ? item.Request_Collections__r.records.find(
                          (record) => record.Type__c === "Soft"
                        )?.Weight__c
                      : null
                  }
                  softPlasticGm={
                    item.Request_Collections__r
                      ? item.Request_Collections__r.records.find(
                          (record) => record.Type__c === "Soft"
                        )?.Weight_in_Gram__c
                      : null
                  }
                  collection_date={
                    item.Collection_Date__c ? item.Collection_Date__c : null
                  }
                  resolvedDate={
                    item.Resolved_Time__c
                      ? item.Resolved_Time__c.split("T")[0]
                      : ""
                  }
                />
              ))
            ) : (
              <div>Data Not Available</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          {/* <LeaderboardScore /> */}
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, null)(TicketRaised);
