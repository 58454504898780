import Sidebar from '../../components/Sidebar/Sidebar'
import Profile from '../../components/Profile/Profile'
import React, { useEffect, useState } from 'react';
import './RequesterProfile.css'
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";

const RequesterProfile = ({ auth }) => { 

    const [user, setUser] = useState(null);
    const [profileName, setProfileName] = useState(null);

    const handleChange = (e) => {
      
        setUser({
            ...user,
            [e.target.id]: e.target.value,
        });
        console.log(e.target.id, " ", e.target.value);
    };

    useEffect(() => {
        if(auth && auth.uid) {
            firebase
                .firestore()
                .collection("user")
                .doc(auth.uid)
                .get()
                .then((doc) => {
                    console.log("FB Data ", doc.data());
                    setUser(doc.data());
                    setProfileName(doc.data().first_name + " " + doc.data().last_name);
                })
                .catch((err) => {
                    console.log("Error to fetch mentor profile ", err);
                });
        }
    }, [auth]);

    return (
        <div className="flex flex-row">
          
            <div className="flex-2">
                <Sidebar />
            </div>
            <div className="flex-1">
                <Profile profile={user} uid={auth.uid} handleChange={handleChange} profileName={profileName} setProfileName={setProfileName}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
    };
};

export default connect(mapStateToProps, null)(RequesterProfile);