import React from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaDownload,
  FaCaretDown,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./SchoolCollage.css";
import Footer from "../Footer/Footer";
import FooterBottom from "../FooterBottom/FooterBottom";
import DownloadAppSection from "../../components/MobileApps/DownloadAppSection";
const {
  WHITE_LOGO,
  RECYCLE_PROCESS,
  PLAY_STORE,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  THUMBNAIL8,
  THUMBNAIL9,
  THUMBNAIL10,
  THUMBNAIL11,
  THUMBNAIL12,
  THUMBNAIL13,
} = require("../../utils/imagepath");

export default function SchoolCollage() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="school-collage">
      <div className="banner">
        <div className="main-header">
          <Link className="logo">
            <img src={WHITE_LOGO} alt="" />
          </Link>
          <Link className="login-btn" to="/login">
            Login/Signup
          </Link>
          <span className="has-tag">#BottlesForChange</span>
          <Link to="/Partnership">
            <FaBars className="hamburger" />
          </Link>
        </div>
        <h1>SCHOOL & COLLEGE PROGRAMME</h1>
      </div>
      <div className="common-description">
        <div className="wrapper">
          <h1>
            WE CAN HELP YOU MAKE YOUR
            <br /> CAMPUS CLEANER, TOGETHER.
          </h1>
          <p>
            It's never too early to teach children the importance of recycling
            plastic. After all, the youth of today is the future of tomorrow.
            With this programme we educate youth about the importance of
            disposing used plastic correctly, so that it can be sent directly
            for recycling. Inculcating this habit is easy, it's green, and it's
            a simple way to create a healthier environment today and for your
            future generations. Participate in the{" "}
            <strong>'BOTTLES FOR CHANGE'</strong> programme and lead the path to
            a cleaner and greener tomorrow
          </p>
          <h2>
            SCHOOLS AND COLLEGES WHO'VE JOINED
            <br /> THE INITIATIVE TO RECYCLE RESPONSIBLY
          </h2>
        </div>
      </div>
      <Carousel
        infinite={true}
        containerClass="thumbnail-carousel"
        customLeftArrow={<FaAngleLeft className="arrow-left" />}
        customRightArrow={<FaAngleRight className="arrow-right" />}
        responsive={responsive}
      >
        <div className="item">
          <img src={THUMBNAIL8} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL9} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL10} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL11} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL12} alt="" />
        </div>
        <div className="item">
          <img src={THUMBNAIL13} alt="" />
        </div>
      </Carousel>
      <div className="recyle-process">
        <h3>START RECYCLING IN YOUR SCHOOLS AND COLLEGES</h3>
        <div className="grey-block">
          <img src={RECYCLE_PROCESS} alt="" />
        </div>
      </div>
      {/* <div className="join-initiative">
        <h4>JOIN THE INITIATIVE</h4>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Name of the School/College<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the School/College"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              How are you Related<span>*</span>
            </label>
            <select className="form-control">
              <option>Select</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Name of the Key Contact Person<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Name of the Key Contact Person"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Mobile Number<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Mobile Number"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>Alternate Number</label>
            <input
              type="text"
              value=""
              placeholder="Enter Alternate Number"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>
              Address<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Address"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              State<span>*</span>
            </label>
            <select className="form-control">
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Delhi">Delhi</option>
              <option value="Dadra and Nagar Haveli and Daman &amp; Diu">
                Dadra and Nagar Haveli and Daman &amp; Diu
              </option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
            </select>
          </div>
          <div className="flex-1">
            <label>
              City<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter City"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              Pincode<span>*</span>
            </label>
            <input
              type="text"
              value=""
              placeholder="Enter Pincode"
              className="form-control"
            />
          </div>
          <div className="flex-1">
            <label>Email Id</label>
            <input
              type="text"
              value=""
              placeholder="Enter Email Id"
              className="form-control"
            />
          </div>
        </div>
        <div className="flex flex-row" style={{ gap: 60, marginBottom: 24 }}>
          <div className="flex-1">
            <label>
              <input type="checkbox" style={{ marginRight: 5 }} />I agree to{" "}
              <Link className="link">Terms & Conditions</Link>
              <span>*</span>
            </label>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1" style={{ textAlign: "center" }}>
            <Link className="discover-btn">Submit</Link>
          </div>
        </div>
      </div> */}
      {/* <div className="wrapper">
        <div className="app-section">
          <div className="app-img">
            <img src={PLAY_STORE} alt="" />
          </div>
          <div className="app-text">
            <h5>DOWNLOAD BOTTLES FOR CHANGE APP NOW</h5>
            <a href="#" className="download-btn">
              <FaDownload className="ico" /> Download the App
            </a>
            <p>
              With this app you can locate the nearest plastic agent
              (kabadiwala) in your area and put segregated plastic in the right
              channel. Segregated plastic will be picked by our plastic agent
              and then sent for recycling.
            </p>
          </div>
        </div>
      </div> */}
      <DownloadAppSection />
      <Footer />
      <FooterBottom />
      {/* <div className="footer">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="mb-4 md:mb-0">
            <ul>
              <li>
                <Link to="/about-pet">Truth about Plastic Recycling</Link>
              </li>
              <li>
                <Link to="/about-bfc">
                  About <b>BOTTLES FOR CHANGE</b>
                </Link>
              </li>
              <li>
                <Link to="/milestone-initative">Milestones & Initiatives</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <ul>
              <li style={{ display: "flex", alignItems: "center" }}>
                <u>Join the Initiative</u>{" "}
                <FaCaretDown style={{ marginLeft: "0.5rem" }} />
              </li>
              <li>
                <Link to="/community">
                  Housing Society/Residential Building
                </Link>
              </li>
              <li>
                <Link to="/corporate">Corporate/Hotel</Link>
              </li>
              <li>
                <Link to="/school-collage">School/College</Link>
              </li>
              <li>
                <Link to="/PartnershipPower">Partnership is Power</Link>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <a
              className="social-icon"
              href="https://www.facebook.com/bottles4change/"
              target="_blank"
            >
              <img src={FACEBOOK} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
              target="_blank"
            >
              <img src={TWITTER} alt="" className="ico" />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/bottles_4_change/?hl=en"
              target="_blank"
            >
              <img src={INSTAGRAM} alt="" className="ico" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <li>&copy; 2019 Bisleri All Rights Reserved.</li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
