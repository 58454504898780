import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
// import LeaderboardScore from "../../LeaderboardScore/LeaderboardScore";
// import ProfileCard from "../../ProfileCard/ProfileCard";
// import Notification from "../../Notification/Notification";
import { getHelp } from "../../../api/requestorApi/sfdx_requestor_apis";
import "./help_support.css";
import {
  EXPLORE_PROGRAMME_IMAGE,
  SUCCESS_IMAGE,
  LOCATIONS,
} from "../../../utils/imagepath";
import Modal from "react-modal";
import { postCase } from "../../../api/requestorApi/sfdx_requestor_apis";
import { connect } from "react-redux";
import { firebase } from "../../../config/fb_config";
import swal from "sweetalert";

const HelpSupport = ({ auth }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modalLocations, setModalLocations] = useState(false);
  const [subject, setSubject] = useState("");
  const [sfId, setSfId] = useState("");
  const [fbUser, setFbUser] = useState(null);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [queries, setQueries] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    debugger;
    firebase
      .firestore()
      .collection("user")
      .doc(auth.uid)
      .get()
      .then((doc) => {
        console.log("FB Data ", doc.data());
        setFbUser(doc.data());
        setSfId(doc.data().sf_id);
        getSupportQueries(doc.data().sf_id);
      })
      .catch((err) => {
        console.log("Error to fetch mentor profile ", err);
      });
  }, [auth.uid]);
  const getSupportQueries = async (SFiD) => {
    debugger;
    try {
      const response_data = await getHelp(SFiD);
      console.log("API response - ", response_data);
      if (response_data) {
        setQueries(response_data);
      }
    } catch (error) {
      swal({
        title: "Exception",
        text: "Something went wrong, please try again.",
        icon: "error",
      });
      console.log("Exception - ", error);
    }
  };
  const handleChangesubject = (event) => {
    setSubject(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleClick = (LinkURL) => {
    window.open(LinkURL, "_blank");
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "75%",
      padding: "20px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsSubmitted(false);
    setSubject("");
    setDescription("");
  };

  const handleRequestClick = () => {
    openModal();
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleSubmission = async () => {
    debugger;
    try {
      //  setIsLoading(true);

      // const sfId = "0030T00000SolwwQAB";
      // const token =
      //   "00D0T0000000Svl!ARsAQIN1gWOoeV2F83mDgZswSry_p55rhIRab0X2oXbOh9D4mRAaN6.Tfie4Ng64DLuU0zn.UiUtxJXI6Hs04IWIzSZTBzfQ";

      if (!subject.trim() || !description.trim()) {
        throw new Error("Subject and description are required.");
      }
      if (!subject.trim()) {
        swal({
          title: "Subject",
          text: "Please enter the subject.",
          icon: "error",
        });
        return;
      }
      if (!description.trim()) {
        swal({
          title: "Description",
          text: "Please enter the description.",
          icon: "error",
        });
        return;
      }
      const newCase = {
        title: subject,
        description: description,
        priority: "High",
      };
      //return;
      const result = await postCase(newCase, sfId);
      console.log("Case creation result:", result);

      if (result && result.status) {
        setIsSubmitted(true);
        setErrorMessage(null);
      } else {
        setErrorMessage("Error creating case. Please try again.");
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Error creating case:", error);
      setErrorMessage(error.message || "An error occurred. Please try again.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  return (
    <div className="flex flex-row h-screen">
      {isLoading && <p>Loading...</p>}
      {modalLocations && (
        <div>
          <div className="modal">
            <div className="modal-dialog">
              <div className="location-map">
                <span
                  className="close"
                  onClick={() => {
                    setModalLocations(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                      fill="#242424"
                    />
                  </svg>
                </span>
                <img src={LOCATIONS} alt="Submitted" />
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <Modal
          isOpen={errorModalOpen}
          onRequestClose={closeErrorModal}
          style={customStyles}
          contentLabel="Error Modal"
        >
          <div style={{ textAlign: "center" }}>
            <h2>Error</h2>
            <p>{errorMessage}</p>
            <button onClick={closeErrorModal}>Close</button>
          </div>
        </Modal>
      )}
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="help-mid-section">
          <h1>Help & Support</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
              marginTop: "1.5rem",
              justifyContent: "space-between",
            }}
          >
            <div className="help-grey-card">
              <h3>Call our customer care executive</h3>
              <h4 className="text-gray-500">
                We are available from 8 AM to 8 PM, Monday to Saturday
              </h4>
              <Link className="details-btn">1800 121 1007</Link>
            </div>
            <div className="help-grey-card">
              <h3>Write to us</h3>
              <h4 className="text-gray-500">
                We usually get back to you in 1-2 business days
              </h4>
              <Link className="details-btn">wecare@bisleri.co.in</Link>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
              marginTop: "1.5rem",
              //justifyContent: "space-between",
            }}
          >
            {queries
              ? queries.map((item, key) => (
                  <div
                    id={key}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      display: "inline-block",
                      position: "relative",
                      borderRadius: "7px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={item.background_url}
                      alt="Explore Programme"
                    />
                    {/* <Link className='green-btn' tyle={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "0.5rem 1.5rem",
                    backgroundColor: "#FEED42",
                    borderRadius: 10,
                    cursor: 'pointer',
                    zIndex: 1
                  }} target='_blank'><p style={{ color: "#000", fontWeight: "bold" }}>
                    Explore the Programme
                  </p></Link> */}
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/bisleri-320a2.appspot.com/o/PDF%2FknowMore.pdf?alt=media&token=38ef0b06-fa68-431a-a105-812abf38febd"
                      target="_blank"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "40%",
                        transform: "translate(-50%, -50%)",
                        padding: "0.5rem 1.5rem",
                        backgroundColor: "#FEED42",
                        borderRadius: 10,
                        cursor: "pointer",
                        zIndex: 0.5,
                      }}
                    >
                      <p style={{ color: "#000", fontWeight: "bold" }}>
                        Download PDF
                      </p>
                    </a>
                    <button
                      onClick={() => handleClick(item.content_url)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "60%",
                        transform: "translate(-50%, -50%)",
                        padding: "0.5rem 1.5rem",
                        backgroundColor: "#FEED42",
                        borderRadius: 10,
                        cursor: "pointer",
                        zIndex: 0.5,
                      }}
                    >
                      <p style={{ color: "#000", fontWeight: "bold" }}>
                        Explore the Programme
                      </p>
                    </button>
                  </div>
                ))
              : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
              marginTop: "1.5rem",
              //justifyContent: "space-between",
            }}
          >
            <button
              onClick={handleRequestClick}
              style={{
                height: 45,
                borderRadius: 10,
                fontWeight: "bold",
                backgroundColor: "#00a990",
                padding: "0.5rem 1.5rem",
                color: "#fff",
              }}
            >
              Request a Callback
            </button>
            <Link to="/help_cases">
              <button
                style={{
                  height: 45,
                  borderRadius: 10,
                  fontWeight: "bold",
                  backgroundColor: "#00a990",
                  padding: "0.5rem 1.5rem",
                  color: "#fff",
                }}
              >
                All Support Queries
              </button>
            </Link>
            <button
              onClick={() => {
                setModalLocations(true);
              }}
              style={{
                height: 45,
                borderRadius: 10,
                fontWeight: "bold",
                backgroundColor: "#00a990",
                padding: "0.5rem 1.5rem",
                color: "#fff",
              }}
            >
              View Our Locations
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Request Callback Modal"
      >
        <div style={{ textAlign: "" }}>
          {!isSubmitted ? (
            <div>
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  marginBottom: "1%",
                  marginTop: "2%",
                  textAlign: "center",
                }}
              >
                Request a Callback
              </h2>
              <form>
                <div style={{ marginBottom: "3%", marginTop: "1%" }}>
                  <label
                    htmlFor="subject"
                    style={{
                      display: "block",
                      marginBottom: "1%",
                      fontWeight: "600",
                    }}
                  >
                    Subject:
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={handleChangesubject}
                    name="subject"
                    placeholder="Enter subject"
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                  />
                </div>
                <div style={{ marginBottom: "2%" }}>
                  <label
                    htmlFor="description"
                    style={{
                      display: "block",
                      marginBottom: "1%",
                      fontWeight: "600",
                    }}
                  >
                    Description:
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={description}
                    onChange={handleChangeDescription}
                    placeholder="Enter description"
                    rows="8"
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2%",
                  }}
                >
                  <button
                    onClick={closeModal}
                    style={{
                      marginRight: "4%",
                      height: 40,
                      width: "25%",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "#00a990",
                      fontWeight: "bold",
                      backgroundColor: "#fff",
                      color: "#00a990",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmission}
                    style={{
                      height: 40,
                      width: "25%",
                      borderRadius: 10,
                      backgroundColor: "#00a990",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                    type="button"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  marginBottom: "1%",
                  marginTop: "2%",
                }}
              >
                Request submitted successfully
              </h2>
              <img
                src={SUCCESS_IMAGE}
                alt="Submitted"
                style={{ height: "20%", width: "36%", marginTop: "1%" }}
              />
              <button
                onClick={closeModal}
                style={{
                  height: 40,
                  width: "25%",
                  borderRadius: 10,
                  backgroundColor: "#00a990",
                  fontWeight: "bold",
                  color: "#fff",
                  marginTop: "1%",
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(HelpSupport);
