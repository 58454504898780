// Footer.js
import React from "react";
import { Link } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
//import PropTypes from "prop-types";
const { FACEBOOK, TWITTER, INSTAGRAM } = require("../../utils/imagepath");

const Footer = () => {
  return (
    <div className="footer">
      <div className="grid grid-cols-1 md:grid-cols-4">
        <div className="mb-4 md:mb-0">
          <ul>
            <li>
              <Link to="/about-pet">Truth about Plastic Recycling</Link>
            </li>
            <li>
              <Link to="/about-bfc">
                About <b>BOTTLES FOR CHANGE</b>
              </Link>
            </li>
            <li>
              <Link to="/milestone-initative">Milestones & Initiatives</Link>
            </li>
          </ul>
        </div>
        <div className="mb-4 md:mb-0">
          <ul>
            <li style={{ display: "flex", alignItems: "center" }}>
              <u>Join the Initiative</u>{" "}
              <FaCaretDown style={{ marginLeft: "0.5rem" }} />
            </li>
            <li>
              <Link to="/community">Housing Society/Residential Building</Link>
            </li>
            <li>
              <Link to="/corporate">Corporate/Hotel</Link>
            </li>
            <li>
              <Link to="/school-collage">School/College</Link>
            </li>
            <li>
              <Link to="/PartnershipPower">Partnership is Power</Link>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <a
            className="social-icon"
            href="https://www.facebook.com/bottles4change/"
            target="_blank"
          >
            <img src={FACEBOOK} alt="" className="ico" />
          </a>
          <a
            className="social-icon"
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FBottles4Change"
            target="_blank"
          >
            <img src={TWITTER} alt="" className="ico" />
          </a>
          <a
            className="social-icon"
            href="https://www.instagram.com/bottles_4_change/?hl=en"
            target="_blank"
          >
            <img src={INSTAGRAM} alt="" className="ico" />
          </a>
        </div>
      </div>
    </div>
  );
};

// Footer.propTypes = {
//   socialLinks: PropTypes.arrayOf(
//     PropTypes.shape({
//       href: PropTypes.string.isRequired,
//       imgSrc: PropTypes.string.isRequired,
//       alt: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

export default Footer;
