import Sidebar from '../../components/Sidebar/Sidebar'
//import Ticket from '../../components/Ticket/Ticket'
import { Link } from 'react-router-dom'
import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
import Notification from '../../components/Notification/Notification'
import './SoftPlasticCollected.css'
const {BACK_BUTTON} =  require('../../utils/imagepath')

export default function PlasticCollected(props) { 
    const caseList = props?.location?.state?.caseList || [];
    console.log('caseList -------------------->', caseList);
    return (
        <div className="flex flex-row">
            <div className="flex-2">
                <Sidebar />
            </div>
            <div className="flex-1">
                <div className='mid-wrap'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1>
                            <Link to='/Dashboard'>
                                <img src={BACK_BUTTON} alt="" />
                            </Link>
                            Soft Plastic Collected (Kg)
                        </h1>
                        <Link className='green-btn'>Download as PDF</Link>
                    </div> 
                    <div className='total-plastic-collected'>
                        <div>Total Hard Plastic Collected</div>
                        <div>933.5 Kg</div>
                    </div>  
                    <div className='plastic-collection'>
                        <div className='location'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M12.9497 11.955C15.6834 9.2201 15.6834 4.78601 12.9497 2.05115C10.2161 -0.683716 5.78392 -0.683716 3.05025 2.05115C0.316582 4.78601 0.316582 9.2201 3.05025 11.955L4.57128 13.4538L6.61408 15.4389L6.74691 15.5567C7.52168 16.1847 8.65623 16.1455 9.38611 15.4391L11.8223 13.0691L12.9497 11.955ZM8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7C11 8.65685 9.65685 10 8 10Z" fill="white"/>
                            </svg>
                        </div>
                        <div style={{ paddingLeft: '1rem' }}>
                            <h3>Plastic Collection</h3>
                            <h4>Bandra, Mumbai</h4>
                            <div style={{ display: 'inline-block' }}> 
                                <label className='label'>Soft Plastic Collected:</label>
                                <span>2,334 Kg 50 Gms</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <label className='label'>Date:</label>
                                <span>09 Oct, 2023</span>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
            <div className="flex-2">
                <div className='right-section'>   
                    <LeaderboardScore />                    
                    <div style={{marginTop:'1.5rem', marginBottom: '1.5rem'}}>
                        <ProfileCard />
                    </div>
                    <Notification />
                </div>
            </div>
        </div>
    )
}