import React, { useState } from "react";
import Sidebar from '../../components/Sidebar/Sidebar'
import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
// import Notification from '../../components/Notification/Notification'
import NotificationCard from '../../components/NotificationCard/NotificationCard'
import { useEffect } from "react";
import { Link } from 'react-router-dom'
import Survey from '../../components/Survey/Survey'
import { getRequesterNotifications } from "../../api/requestorApi/sfdx_requestor_apis";
import { getCollectorNotifications } from "../../api/collectorApi/sfdx_collector_apis";

import '../AllSurvey/AllSurvey.css'
import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";
const {BACK_BUTTON} =  require('../../utils/imagepath')

const AllNotification=({auth})=> { 
  const [surveys, setSurveys] = useState([]);
  const [completedSurveys, setCompletedSurveys] = useState([]);
  const [newSurveys, setNewSurveys] = useState([]);
  const [sfId,setSfId]=useState(null);
  const [userType, setUserType] = useState(null);
  useEffect(() => {
      try {
        //const sfId = "0030T00000SomdCQAR";  
        debugger
        
        firebase
        .firestore()
        .collection("user")
        .doc(auth.uid)
        .get()
        .then((doc) => {
            console.log("FB Data ", doc.data());
            setUserType(doc.data().user_type);
            setSfId(doc.data().sf_id);
            fetchData(doc.data().sf_id);
        })
        .catch((err) => {
            console.log("Error to fetch mentor profile ", err);
        });
        // Assuming data.FinalNotification contains the surveys array        
      } catch (error) {
        console.error("Error fetching Survey:", error);
      }
  }, [auth.uid]);
  const fetchData = async (SFID) => {
    let data=null;
    if(userType==="requestor"){
      data = await getRequesterNotifications(SFID);
    }
    else{
      data = await getCollectorNotifications(SFID);
    }
    console.log('Notification data ',data);
    const surveysData = data.FinalNotification?.broadcast || [];
        // const completedSurveys = surveysData[0].completed;
        // const newSurveys = surveysData[0].newSurveys;
        if (Array.isArray(surveysData)) {
          setSurveys(surveysData);
          // setCompletedSurveys(completedSurveys);
          // setNewSurveys(newSurveys);
        } else {
          console.error("Survey data is not in the expected format:", data);
        }
  }
  
  
  
    const [activeTab, setActiveTab] = useState("openSurvey");
    const [filterSurvey,setFilterSurvey]=useState([{
        caseNumber:'000626',
            subject:'Plastic Collection Survey',
            description:'We value your input! We have created a short survey to gather insights on plastic collection in Mumbai.',
            date:'16 Oct, 2023',
            stage:'Closed'
    }]);
    const suveyData = [
        {
            caseNumber:'000627',
            subject:'Plastic Collection Survey',
            description:'We value your input! We have created a short survey to gather insights on plastic collection in Mumbai.',
            date:'17 Oct, 2023',
            stage:'New'
        },
        {
            caseNumber:'000628',
            subject:'Rate Our Service',
            description:'We value your input! We have created a short survey to gather insights on plastic collection in Mumbai.',
            date:'18 Oct, 2023',
            stage:'New'
        },
        {
            caseNumber:'000626',
            subject:'Plastic Collection Survey',
            description:'We value your input! We have created a short survey to gather insights on plastic collection in Mumbai.',
            date:'16 Oct, 2023',
            stage:'Closed'
        }
];
const handleTabClick = (tab) => {
    tab==="closedSurvey"?setFilterSurvey(suveyData.filter(ticket => ticket.stage === "Closed")):setFilterSurvey(suveyData.filter(ticket => ticket.stage !== "Closed"));
    setActiveTab(tab);
  console.log('filtered data-->',
  
  
  
  );
  };
    return (
        <div className="flex flex-row">
            <div className="flex-2">
                <Sidebar />
            </div>
            <div className="flex-1">
              <div className='mid-wrap'>
                <h1>
                  {userType==="requestor"?
                  <Link to='/Dashboard'>
                    <img src={BACK_BUTTON} alt="" />
                  </Link>:<Link to='/collector_dashboard'>
                    <img src={BACK_BUTTON} alt="" />
                  </Link>}
                  Your Notifications
                </h1>
                {/* <div className="tab-buttons">
                  <button
                    onClick={() => handleTabClick("openSurvey")}
                    className={activeTab === "openSurvey" ? "active" : ""}
                  >
                    New Notifications
                  </button>
                  <button
                    onClick={() => handleTabClick("closedSurvey")}
                    className={activeTab === "closedSurvey" ? "active" : ""}
                  >
                    Viewed Notifications
                  </button>
                </div> */}

                <div className="tab-content">
                  {activeTab === "openSurvey" && (                
                    surveys.map(item=>(
                      <div>
                        <NotificationCard subject={item.title} description={item.description} date={item.created_datetime} />
                      </div>
                    ))
                   )}
                    {activeTab === "closedSurvey" && (
                      completedSurveys.map(item=>(
                        <div key={item.caseNumber}>
                          <NotificationCard subject={item.subject} type={'Notification'} description={item.description} Survey_link={item.survey_link} date={item.date} stage={item.stage} />
                        </div>
                    ))
                  )}
                </div> 
              </div>
            </div>
            <div className="flex-2">
                <div className='right-section'>   
                    {/* <LeaderboardScore />                     */}
                    <div style={{marginTop:'1.5rem', marginBottom: '1.5rem'}}>
                        {/* <ProfileCard /> */}
                    </div>
                    {/* <Notification /> */}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
  return {
      authError: state.auth.authError,
      auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps,null)(AllNotification);