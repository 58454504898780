import Sidebar from '../../components/Sidebar/Sidebar'
import { Link } from 'react-router-dom'
import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
import Notification from '../../components/Notification/Notification'
import '../TicketRaised/TicketRaised.css'
const {BACK_BUTTON} =  require('../../utils/imagepath')

export default function TicketSolved() {
    return (
        <div className="flex flex-row h-screen">
            <div className="flex-2">
                <Sidebar />
            </div>
            <div className="flex-1">
                <div className='ticket-raised'>
                    <h1>
                        <Link to='/Dashboard'>
                            <img src={BACK_BUTTON} alt="" />
                        </Link>
                        Tickets Solved
                    </h1>
                    <div className='grey-block'>
                        <div className='location-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M13.818 10.264C14.2668 9.4213 14.5212 8.45927 14.5212 7.43783C14.5212 4.11261 11.8255 1.41699 8.50033 1.41699C5.17511 1.41699 2.47949 4.11261 2.47949 7.43783C2.47949 8.53448 2.77269 9.56266 3.28497 10.4482C3.29321 10.4625 3.30151 10.4767 3.30987 10.4909C3.4822 10.7832 3.67854 11.0598 3.89616 11.3177C3.89861 11.3206 3.90112 11.3236 3.90358 11.3265C3.90554 11.3288 3.90767 11.3313 3.90964 11.3337H3.89616L8.14616 16.292C8.67741 16.8232 8.67741 16.8232 9.03158 16.292L13.1045 11.3337H13.091C13.0929 11.3314 13.0948 11.3292 13.0967 11.327L13.097 11.3266L13.0972 11.3263C13.123 11.2959 13.1484 11.2652 13.1736 11.2343C13.3742 10.9877 13.5556 10.7249 13.7157 10.4482C13.7508 10.3875 13.7849 10.3261 13.818 10.264ZM8.50206 9.73991C7.32845 9.73991 6.37706 8.78851 6.37706 7.61491C6.37706 6.4413 7.32845 5.48991 8.50206 5.48991C9.67566 5.48991 10.6271 6.4413 10.6271 7.61491C10.6271 8.78851 9.67566 9.73991 8.50206 9.73991Z" fill="white"/>
                            </svg>
                        </div>
                        <div style={{ width: '100%' }}>
                            <h2>School Plastic Recycling</h2>
                            <h3>Andheri East, Mumbai</h3>
                            <div className='ticket-detail'>
                                <div>
                                    <label>Ticket ID:</label>
                                    BS-034
                                </div>
                                <div>
                                    <label>Date raised:</label>
                                    09/09/2023
                                </div>
                                <div>
                                    <label>Age of Ticket:</label>
                                    3 Days
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='ticket-status pending'>In Progress</div>
                                <Link className='green-btn'>View</Link>
                            </div>
                        </div>
                    </div>
                    <div className='grey-block'>
                        <div className='location-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M13.818 10.264C14.2668 9.4213 14.5212 8.45927 14.5212 7.43783C14.5212 4.11261 11.8255 1.41699 8.50033 1.41699C5.17511 1.41699 2.47949 4.11261 2.47949 7.43783C2.47949 8.53448 2.77269 9.56266 3.28497 10.4482C3.29321 10.4625 3.30151 10.4767 3.30987 10.4909C3.4822 10.7832 3.67854 11.0598 3.89616 11.3177C3.89861 11.3206 3.90112 11.3236 3.90358 11.3265C3.90554 11.3288 3.90767 11.3313 3.90964 11.3337H3.89616L8.14616 16.292C8.67741 16.8232 8.67741 16.8232 9.03158 16.292L13.1045 11.3337H13.091C13.0929 11.3314 13.0948 11.3292 13.0967 11.327L13.097 11.3266L13.0972 11.3263C13.123 11.2959 13.1484 11.2652 13.1736 11.2343C13.3742 10.9877 13.5556 10.7249 13.7157 10.4482C13.7508 10.3875 13.7849 10.3261 13.818 10.264ZM8.50206 9.73991C7.32845 9.73991 6.37706 8.78851 6.37706 7.61491C6.37706 6.4413 7.32845 5.48991 8.50206 5.48991C9.67566 5.48991 10.6271 6.4413 10.6271 7.61491C10.6271 8.78851 9.67566 9.73991 8.50206 9.73991Z" fill="white"/>
                            </svg>
                        </div>
                        <div style={{ width: '100%' }}>
                            <h2>Plastic Collection Campaign</h2>
                            <h3>Bandra Reclamation, Mumbai</h3>
                            <div className='ticket-detail'>
                                <div>
                                    <label>Ticket ID:</label>
                                    BS-002
                                </div>
                                <div>
                                    <label>Date raised:</label>
                                    09/09/2023
                                </div>
                                <div>
                                    <label>Age of Ticket:</label>
                                    3 Days
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='ticket-status pending'>In Progress</div>
                                <Link className='green-btn'>View</Link>
                            </div>
                        </div>
                    </div>
                    <div className='grey-block'>
                        <div className='location-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M13.818 10.264C14.2668 9.4213 14.5212 8.45927 14.5212 7.43783C14.5212 4.11261 11.8255 1.41699 8.50033 1.41699C5.17511 1.41699 2.47949 4.11261 2.47949 7.43783C2.47949 8.53448 2.77269 9.56266 3.28497 10.4482C3.29321 10.4625 3.30151 10.4767 3.30987 10.4909C3.4822 10.7832 3.67854 11.0598 3.89616 11.3177C3.89861 11.3206 3.90112 11.3236 3.90358 11.3265C3.90554 11.3288 3.90767 11.3313 3.90964 11.3337H3.89616L8.14616 16.292C8.67741 16.8232 8.67741 16.8232 9.03158 16.292L13.1045 11.3337H13.091C13.0929 11.3314 13.0948 11.3292 13.0967 11.327L13.097 11.3266L13.0972 11.3263C13.123 11.2959 13.1484 11.2652 13.1736 11.2343C13.3742 10.9877 13.5556 10.7249 13.7157 10.4482C13.7508 10.3875 13.7849 10.3261 13.818 10.264ZM8.50206 9.73991C7.32845 9.73991 6.37706 8.78851 6.37706 7.61491C6.37706 6.4413 7.32845 5.48991 8.50206 5.48991C9.67566 5.48991 10.6271 6.4413 10.6271 7.61491C10.6271 8.78851 9.67566 9.73991 8.50206 9.73991Z" fill="white"/>
                            </svg>
                        </div>
                        <div style={{ width: '100%' }}>
                            <h2>Community Recycling Drive</h2>
                            <h3>Bandra Reclamation, Mumbai</h3>
                            <div className='ticket-detail'>
                                <div>
                                    <label>Ticket ID:</label>
                                    BS-045
                                </div>
                                <div>
                                    <label>Date raised:</label>
                                    09/09/2023
                                </div>
                                <div>
                                    <label>Age of Ticket:</label>
                                    3 Days
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='ticket-status pending'>In Progress</div>
                                <Link className='green-btn'>View</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-2">
                <div className='right-section'>   
                    <LeaderboardScore />                    
                    <div style={{marginTop:'1.5rem', marginBottom: '1.5rem'}}>
                        <ProfileCard />
                    </div>
                    <Notification />
                </div>
            </div>
        </div>
    )
}