import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import LeaderboardScore from "../../components/LeaderboardScore/LeaderboardScore";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Notification from "../../components/Notification/Notification";
import { createRequest } from "../../api/requestorApi/sfdx_requestor_apis";
import { BACK_BUTTON } from "../../utils/imagepath";


import { connect } from "react-redux";
import { firebase } from "../../config/fb_config";

const NewTicket = ({ auth, history }) => {

  const [subject, setSubject] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
    const [pincode, setPincode] = useState("");
    const [country, setCountry] = useState("India");
    const [sfId, setSfId] = useState("");
    

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
      };
    
      const handleStateChange = (event) => {
        setState(event.target.value);
      };
    
      const handleCityChange = (event) => {
        setCity(event.target.value);
      };
    
      const handleStreetChange = (event) => {
        setStreet(event.target.value);
      };
    
      const handlePincodeChange = (event) => {
        setPincode(event.target.value);
      };

      useEffect(() => {
        const fetchSfId = async () => {
          try {
            const doc = await firebase.firestore().collection('user').doc(auth.uid).get();
            console.log('FB Data ', doc.data());
            setSfId(doc.data().sf_id);
          } catch (error) {
            console.error('Error fetching mentor profile ', error);
          }
        };
    
        if (auth.uid) {
          fetchSfId();
        }
      }, [auth.uid]);
    
      const handleSuccess = (response) => {
        console.log('Request submitted successfully:', response);
        // setshowModal(true);
        window.location.href = '/MyTickets';
      };
    
      const handleSubmit = () => {
        const request = {
          subject,
          add_state: state,
          add_city: city,
          add_street: street,
          add_pincode: pincode,
          add_country: 'IN',
        };
    
        if (sfId) {
          // Pass the callback function to createRequest
          createRequest(request, sfId, handleSuccess)
            .catch((error) => {
              console.error('Error submitting request:', error);
            });
        } else {
          console.error('sfId is not available.');
        }
      };
    
    return (
        <div className="flex flex-row">
            <div className="flex-2">
                <Sidebar />
            </div>
            <div className="flex-1">
                <div className="profile mid-wrap">
                    <Link to='/MyTickets'>
                        <img src={BACK_BUTTON} alt="" />
                    </Link>
                    <h1 style={{marginTop:'1rem', marginBottom: '1.5rem'}}>New Ticket</h1>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem', gap: '1.25rem' }}>
                        <div className="flex-1">
                            <label>Subject</label>
                            <input type='text' value={subject} onChange={handleSubjectChange} className='form-control' placeholder='eg. School Plastic Recycling' />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                        <div className="flex-1">
                            <label>Country</label>
                            <input type='text' value={country} style={{backgroundColor:'#ddd'}} disabled="disabled" className='form-control' />
                        </div>
                        <div className="flex-1">
                            <label>State</label>
                            <input type='text' className='form-control' value={state} onChange={handleStateChange} placeholder='eg. Karnataka' />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                        <div className="flex-1">
                            <label>City</label>
                            <input type='text' className='form-control' value={city} onChange={handleCityChange} placeholder='eg. Bengaluru' />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                        <div className="flex-1">
                            <label>Street Address</label>
                            <input type='text' className='form-control' value={street} onChange={handleStreetChange} placeholder='eg. Andheri East' />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1.25rem', gap: '1.25rem' }}>
                        <div className="flex-1">
                            <label>Pincode</label>
                            <input type='text' className='form-control' value={pincode} onChange={handlePincodeChange} placeholder='eg. 560078' />
                        </div> 
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2.5rem', gap: '1.25rem' }}>
                        <Link className='ordered-btn'>Cancel</Link>
                        <button onClick={handleSubmit} className='green-btn'>Submit</button>
                    </div>
                </div>
            </div>
            <div className="flex-2">
                <div className='right-section'>   
                    <LeaderboardScore />                    
                    <div style={{marginTop:'1.5rem', marginBottom: '1.5rem'}}>
                        <ProfileCard />
                    </div>
                    <Notification />
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
    };
  };
  
  export default connect(mapStateToProps, null)(NewTicket);