import { Link } from 'react-router-dom'
import './LeaderboardScore.css'

export default function LeaderboardScore() { 
    return (<></>)
        {/* <div className="leaderboard-score">
            <h1>120</h1>
            <span>Leaderboard Score</span>
            <Link to='/' className="view-details">
                View Details
            </Link>
            <div className='crown'>
                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="41" viewBox="0 0 62 41" fill="none">
                    <path d="M33.2604 8.25893C34.4875 7.47768 35.3056 6.06027 35.3056 4.46429C35.3056 1.99777 33.3788 0 31 0C28.6212 0 26.6944 1.99777 26.6944 4.46429C26.6944 6.07143 27.5125 7.47768 28.7396 8.25893L22.5719 21.0491C21.5924 23.0804 19.0521 23.6607 17.3406 22.2433L7.75 14.2857C8.28819 13.5379 8.61111 12.6116 8.61111 11.6071C8.61111 9.14062 6.68437 7.14286 4.30556 7.14286C1.92674 7.14286 0 9.14062 0 11.6071C0 14.0737 1.92674 16.0714 4.30556 16.0714H4.3809L9.3 44.1295C9.89201 47.5223 12.7444 50 16.0812 50H45.9187C49.2448 50 52.0972 47.5335 52.7 44.1295L57.6191 16.0714H57.6944C60.0733 16.0714 62 14.0737 62 11.6071C62 9.14062 60.0733 7.14286 57.6944 7.14286C55.3156 7.14286 53.3889 9.14062 53.3889 11.6071C53.3889 12.6116 53.7118 13.5379 54.25 14.2857L44.6594 22.2433C42.9479 23.6607 40.4076 23.0804 39.4281 21.0491L33.2604 8.25893Z" fill="#FFD23E"/>
                </svg>
            </div>
        </div> */}
    
}