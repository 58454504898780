const initState = {
    authError: null,
    waitForSignup: false,
    navigationLink:null
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case "SIGNUP_START":
            return { ...state, waitForSignup: true };
        case "SIGNUP_FAIL":
            return { ...state, waitForSignup: true };

        case "LOGIN_ERROR":
            console.log("login error");
            return {
                ...state,
                authError: {
                    header: "Warning",
                    msg: "",
                    color: "bg-yellow-500",
                },
            };

        case "LOGIN_SUCCESS":
            debugger;
            console.log("login success",action);
            return {
                ...state,
                authError: null,
                navigationLink: action.navTo,
            };

        case "SIGNUP_SUCCESS":
            console.log("signup success");
            return {
                ...state,
                waitForSignup: false,
                authError: null,
                navigationLink: "/login",
            };
        case "SIGNOUT_SUCCESS":
            console.log("signout success");
            return {
                ...state,
                authError: { type: "SIGNOUT_SUCCESS" },
            };

        case "INCORRECT_CREDENTIAL":
            console.log("Credential Were wrong");
            return {
                ...state,
                authError: {
                    header: "Incorrect",
                    msg: "credentials",
                    color: "bg-red-400",
                },
            };

        case "EMAIL_NOT_VERIFIED":
            console.log("EMAIL NOT VERIFIED");
            return {
                ...state,
                authError: {
                    header: "Warning",
                    msg: "Email not verified yet",
                    color: "bg-yellow-500",
                },
            };

        case "SIGNUP_ERROR":
            console.log("signup error " + JSON.stringify(action));

            return {
                ...state,
                authError: {
                    header: "Something went wrong",
                    msg: "There was an issue while signing up, please try again later.",
                    color: "bg-red-400",
                },
            };

        case "EMAIL_EXISTS":
            console.log("email exists " + JSON.stringify(action));

            return {
                ...state,
                authError: {
                    header: "Invalid",
                    msg: "The email address is already in use by another account.",
                    color: "bg-red-400",
                },
            };

        case "VERIFIY_EMAIL":
            console.log("Email Verification Sent");
            return {
                ...state,
                authError: {
                    header: "Verify",
                    msg: "your email!",
                    color: "bg-green-600",
                },
            };

        case "PASSWORD_REGEX_SUCCESS":
            console.log("Password has special characters");
            return {
                ...state,
                authError: null,
            };

        case "RESET_PASSWORD_ERROR":
            console.log("Reset password error " + JSON.stringify(action));

            return {
                ...state,
                authError: {
                    header: "Account ! ",
                    msg: "doesn't exist",
                    color: "bg-red-400",
                },
            };

        case "RESET_PASSWORD_SUCCESS":
            console.log("Email Verification Sent");
            return {
                ...state,
                authError: {
                    header: "Reset ",
                    msg: "Password link sent!",
                    color: "bg-green-600",
                },
            };

        default:
            return state;
    }
};

export default authReducer;