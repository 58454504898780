import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { firebase } from "../../config/fb_config";

function MyClassComponentWithNavigation(props) {
  const navigate = useNavigate();
  const { auth, profile, history } = props;
  useEffect(() => {
    console.log("Email verified ", auth.emailVerified);
    console.log("Auth Id ", auth);
    if (auth && auth.uid /* auth.uid && !auth.emailVerified */) {
      firebase
        .firestore()
        .collection("user")
        .doc(auth.uid)
        .get()
        .then((doc) => {
          const userData = doc.data();
          console.log("FB Data ", userData);
          if (userData && userData.user_type === "requestor") {
            navigate("/dashboard");
          } else {
            if (doc.data().status === "New") navigate("/documents");
            else navigate("/collector_dashboard");
          }
        })
        .catch((err) => {
          console.log("Error to fetch mentor profile ", err);
        });
    } else {
      //console.log("Profile");
      //console.log("UserType", profile.user_type);
      //console.log("Email ", auth.email);
      //console.log("Auth email verified ", auth.emailVerified);
      navigate("/home");
    }
  }, []);
  return <></>;
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(MyClassComponentWithNavigation);
