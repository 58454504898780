import { Link } from "react-router-dom";
import React from "react";
import "./Ticket.css";
import "../FeedbackGiven/FeedbackGiven.css";
import MyTickets from "../../pages/MyTickets/MyTickets.css";
import { useState } from "react";
import { updateCaseData } from "../../api/collectorApi/sfdx_collector_apis";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { firebase } from "../../config/fb_config";
import { createRating } from "../../api/requestorApi/sfdx_requestor_apis";
import { FaStar } from "react-icons/fa";

export default function Ticket(props) {
  debugger;
  const [modalState, setModalState] = useState(false);
  const [modalRateExperience, setModalRateExperience] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedSFid, setselectedSFid] = useState(null);
  const [hardKg, setHardKg] = useState(0);
  const [hardGm, setHardGm] = useState(0);
  const [softKg, setSoftKg] = useState(0);
  const [softGm, setSoftGm] = useState(0);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const rating = [1, 2, 3, 4, 5];
  const {
    subject,
    address,
    street,
    country,
    state,
    city,
    pincode,
    rating_experience,
    rating_submitted_status,
    ticketId,
    dateRaised,
    collection_date,
    ticketAge,
    hardPlasticKg,
    hardPlasticGm,
    softPlasticKg,
    softPlasticGm,
    stage,
    resolvedDate,
    userType,
    sfid,
    callBackFunc,
  } = props;
  debugger;
  const handleCheckout = () => {
    console.log("selected ticket item -- - -", sfid);
    setModalState(true);
  };
  const callParentFunction = () => {
    callBackFunc();
  };
  const [checkout, setCheckout] = useState({
    hardPlasticInKg: 0,
    hardPlasticInGm: 0,
    softPlasticInKg: 0,
    softPlasticInGm: 0,
  });
  const handleChange = (e) => {
    debugger;
    setCheckout({
      ...checkout,
      [e.target.id]: e.target.value,
    });
    console.log(e.target.id, " ", e.target.valsue);
  };
  const handleTicketClose = () => {
    console.log("hard plastic in Kg--->", checkout.hardPlasticInKg);
    //return
    if (!checkout.hardPlasticInKg || checkout.hardPlasticInKg <= 0) {
      Swal.fire({
        title: "Checkout",
        text: "Please enter hard plastic in Kg.",
        icon: "info",
      });
      return;
    }
    if (!checkout.hardPlasticInGm || checkout.hardPlasticInGm <= 0) {
      Swal.fire({
        title: "Checkout",
        text: "Please enter hard plastic in Gm.",
        icon: "info",
      });
      return;
    }
    if (!checkout.softPlasticInKg || checkout.softPlasticInKg <= 0) {
      Swal.fire({
        title: "Checkout",
        text: "Please enter soft plastic in Kg.",
        icon: "info",
      });
      return;
    }
    if (!checkout.softPlasticInGm || checkout.softPlasticInGm <= 0) {
      Swal.fire({
        title: "Checkout",
        text: "Please enter soft plastic in Gm.",
        icon: "info",
      });
      return;
    }
    const request = {
      case_no: ticketId,
      hard_plastic_kg: checkout.hardPlasticInKg,
      hard_plastic_gm: checkout.hardPlasticInGm,
      soft_plastic_kg: checkout.softPlasticInKg,
      soft_plastic_gm: checkout.softPlasticInGm,
      status: "closed",
    };
    console.log("ticket object==>", request);

    debugger;
    updateCaseData(request, sfid, handleSuccess).catch((error) => {
      debugger;
      console.error("Error submitting feedback:", error);
    });
  };
  const handleSuccess = (response) => {
    debugger;
    console.log("checkout ", response);
    swal({
      title: "Success",
      text: "Ticket closed successfully.",
      icon: "success",
    });
    setModalState(false);
    callParentFunction();
  };
  const handleRateExperience = async () => {
    debugger;
    if (!rating_experience || rating_experience.length <= 0) {
      const Questionnaire = firebase
        .firestore()
        .collection("Rating Questionnaire");
      const snapshot = await Questionnaire.get();
      const dataArray = snapshot.docs.map((doc) => ({
        answer: "false",
        ...doc.data(),
      }));

      setQuestionnaireData(dataArray);
      console.log("Rating Questionnaire==>", dataArray);
    } else {
      setQuestionnaireData(rating_experience);
      console.log("Rating Questionnaire==>", rating_experience);
    }
    setModalRateExperience(true);
  };
  const handleCheckboxClick = (index, key) => {
    console.log("function called", index);
    if (rating_submitted_status === "true") return;
    setQuestionnaireData((prevData) => {
      const newData = [...prevData];
      // newData[index].answer =
      //   newData[index].answer === "true" ? "false" : "true";
      newData[key].rating = index;
      return newData;
    });
    console.log("data after event-=?", questionnaireData);
  };
  const handleRatingSubmit = async () => {
    debugger;
    // [
    //   {
    //     "question": "Test111",
    //     "answer": "false"
    //   },
    //   {
    //     "question": "Test222",
    //     "answer": "false"
    //   }
    // ]
    //   [
    //     {
    //         "answer": "false",
    //         "question": "Was the pickup process efficient?"
    //     },
    //     {
    //         "answer": "true",
    //         "question": "Did you find the pickup staff to be professional?"
    //     }
    // ]
    debugger;
    let validationFlag = false;
    for (let i = 0; i < questionnaireData.length; i++) {
      if (!questionnaireData[i].rating || questionnaireData[i].rating <= 0) {
        Swal.fire({
          title: "Rate Experience",
          text: "Please choose rating for all questions.",
          icon: "info",
        });
        validationFlag = true;
        break;
      }
    }
    if (validationFlag) return;
    console.log("Validated");
    createRating(questionnaireData, ticketId, handleSuccessRating).catch(
      (error) => {
        debugger;
        console.error("Error submitting feedback:", error);
        Swal.fire({
          title: "Submit Rating",
          text: "Oops! something went wrong, please try again.",
        });
      }
    );
  };
  const handleSuccessRating = () => {
    Swal.fire({
      title: "Submit Rating",
      text: "Rating submitted successfully.",
      icon: "success",
    });
    setModalRateExperience(false);
    //rating_submitted_status = "true";
  };
  return (
    <div className="ticket-raised">
      {modalState ? (
        <div className="modal">
          <div className="modal-dialog">
            <div className="checkout-modal">
              <h2 style={{ marginBottom: "3rem" }}>Plastic Collected</h2>
              <div
                style={{ display: "flex", gap: "2rem", marginBottom: "3rem" }}
              >
                <div>
                  <h4
                    style={{
                      textAlign: "left",
                      marginBottom: "0.5rem",
                      fontWeight: "500",
                    }}
                  >
                    Hard Plastic
                  </h4>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        type="number"
                        id="hardPlasticInKg"
                        value={checkout.hardPlasticInKg}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: "6rem" }}
                      />
                      <span
                        style={{
                          color: "#000",
                          fontSize: "1rem",
                          padding: "0.5rem",
                          fontWeight: "600",
                        }}
                      >
                        Kg
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="number"
                        id="hardPlasticInGm"
                        value={checkout.hardPlasticInGm}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: "6rem" }}
                      />
                      <span
                        style={{
                          color: "#000",
                          fontSize: "1rem",
                          padding: "0.5rem",
                          fontWeight: "600",
                        }}
                      >
                        Gm
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <h4
                    style={{
                      textAlign: "left",
                      marginBottom: "0.5rem",
                      fontWeight: "500",
                    }}
                  >
                    Soft Plastic
                  </h4>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        type="number"
                        id="softPlasticInKg"
                        value={checkout.softPlasticInKg}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: "6rem" }}
                      />
                      <span
                        style={{
                          color: "#000",
                          fontSize: "1rem",
                          padding: "0.5rem",
                          fontWeight: "600",
                        }}
                      >
                        Kg
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="number"
                        id="softPlasticInGm"
                        value={checkout.softPlasticInGm}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: "6rem" }}
                      />
                      <span
                        style={{
                          color: "#000",
                          fontSize: "1rem",
                          padding: "0.5rem",
                          fontWeight: "600",
                        }}
                      >
                        Gm
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="ordered-btn"
                onClick={() => {
                  setModalState(false);
                }}
                style={{ marginRight: "1rem" }}
              >
                Cancel
              </button>
              <button
                className="green-btn"
                onClick={() => {
                  handleTicketClose();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {modalRateExperience ? (
        <div className="modal">
          <div className="modal-dialog">
            <div className="checkout-modal rate-experience">
              <h2 className="mb-5">Rate you experience</h2>
              {/* <div className="checkbox-group"> */}
              {/* <div class="rate"> */}
              {questionnaireData
                ? questionnaireData.map((item, key) => {
                    return (
                      <div style={{ flex: 1, flexDirection: "row" }}>
                        <label className="label mb-2 flex">
                          {rating.map((rating, index) => {
                            return (
                              <>
                                {/* style={{ color: index < rating ? 'gold' : 'gray' }} */}
                                <FaStar
                                  onClick={() =>
                                    handleCheckboxClick(index + 1, key)
                                  }
                                  style={{
                                    color:
                                      index < item.rating ? "gold" : "gray",
                                  }}
                                />
                                {/* <input
                                  type="radio"
                                  checked="checked"
                                  className="mr-3"
                                  id={key}
                                  name="rate"
                                  onChange={() => handleCheckboxClick(key)}
                                /> */}
                                {/* <label for={key} title="text">
                                  {rate} stars
                                </label> */}
                              </>
                            );
                          })}
                          <span className="question-rating ps-3">
                            {item.question}
                          </span>
                        </label>
                      </div>
                    );
                  })
                : null}
              {/* </div> */}
              {/* </div> */}
              <button
                className="ordered-btn"
                onClick={() => {
                  setModalRateExperience(false);
                }}
                style={{ marginRight: "1rem" }}
              >
                Close
              </button>
              {rating_submitted_status === "false" ? (
                <button
                  className="green-btn"
                  onClick={() => {
                    handleRatingSubmit();
                  }}
                >
                  Submit
                </button>
              ) : null}
              {/* {rating_submitted_status ? (
                rating_submitted_status === "false" ? (
                  <Link
                    className="green-btn"
                    onClick={() => {
                      handleRatingSubmit();
                    }}
                  >
                    Submit
                  </Link>
                ) : null
              ) : null} */}
            </div>
          </div>
        </div>
      ) : null}
      <div className="grey-block">
        <div className="location-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M13.818 10.264C14.2668 9.4213 14.5212 8.45927 14.5212 7.43783C14.5212 4.11261 11.8255 1.41699 8.50033 1.41699C5.17511 1.41699 2.47949 4.11261 2.47949 7.43783C2.47949 8.53448 2.77269 9.56266 3.28497 10.4482C3.29321 10.4625 3.30151 10.4767 3.30987 10.4909C3.4822 10.7832 3.67854 11.0598 3.89616 11.3177C3.89861 11.3206 3.90112 11.3236 3.90358 11.3265C3.90554 11.3288 3.90767 11.3313 3.90964 11.3337H3.89616L8.14616 16.292C8.67741 16.8232 8.67741 16.8232 9.03158 16.292L13.1045 11.3337H13.091C13.0929 11.3314 13.0948 11.3292 13.0967 11.327L13.097 11.3266L13.0972 11.3263C13.123 11.2959 13.1484 11.2652 13.1736 11.2343C13.3742 10.9877 13.5556 10.7249 13.7157 10.4482C13.7508 10.3875 13.7849 10.3261 13.818 10.264ZM8.50206 9.73991C7.32845 9.73991 6.37706 8.78851 6.37706 7.61491C6.37706 6.4413 7.32845 5.48991 8.50206 5.48991C9.67566 5.48991 10.6271 6.4413 10.6271 7.61491C10.6271 8.78851 9.67566 9.73991 8.50206 9.73991Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ width: "100%" }}>
          <h2>{subject}</h2>
          {/* <div class="ticket-detail">
            <label>
              Ticket ID:
              <b class="ticket_id_font">{ticketId}</b>
            </label>
          </div> */}

          <h3>{address ? address.replaceAll("null", " ") : ""}</h3>
          <div className="ticket-detail">
            <div>
              <label>Collection ID:</label>
              {ticketId}
            </div>
            <div>
              <label>Date Raised:</label>
              {dateRaised}
            </div>
            <div>
              <label>Pickup date</label>
              {collection_date}
            </div>
            {stage === "Closed" ? (
              <div>
                <label>Date Resolved:</label>
                {resolvedDate}
              </div>
            ) : (
              <div>
                <label>Age of Ticket:</label>
                {ticketAge}
              </div>
            )}
            {stage === "Closed" ? (
              <div>
                <label>Hard Plastic in Kg</label>
                {hardPlasticKg ? (hardPlasticKg ? hardPlasticKg : 0) : 0}
              </div>
            ) : null}
            {stage === "Closed" ? (
              <div>
                <label>Hard Plastic in Gm</label>
                {hardPlasticGm ? (hardPlasticGm ? hardPlasticGm : 0) : 0}
              </div>
            ) : null}
            {stage === "Closed" ? (
              <div>
                <label>Soft Plastic in Kg</label>
                {softPlasticKg ? (softPlasticKg ? softPlasticKg : 0) : 0}
              </div>
            ) : null}
            {stage === "Closed" ? (
              <div>
                <label>Soft Plastic in Gm</label>
                {softPlasticGm ? (softPlasticGm ? softPlasticGm : 0) : 0}
              </div>
            ) : null}

            {/* <div>
                            <label>Age of Ticket:</label>
                            3 Days
                        </div> */}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {stage === "New" ? (
              <div className="ticket-status">Pending</div>
            ) : stage === "In-Progress" ? (
              <div className="ticket-status pending">{stage}</div>
            ) : (
              <div className="ticket-status resolved">{stage}</div>
            )}
            {/* <div className='ticket-status resolved'>Closed</div> */}
            {/* <Link className='green-btn'>View</Link> */}
            {userType === "collector" ? (
              stage !== "Closed" ? (
                <button
                  className="green-btn"
                  style={{ marginTop: "0.5rem" }}
                  onClick={() => {
                    setModalState(true);
                  }}
                >
                  Checkout
                </button>
              ) : null
            ) : stage === "Closed" ? (
              userType === "requestor" ? (
                <div>
                  <button
                    className="green-btn"
                    style={{ marginTop: "0.5rem" }}
                    onClick={() => {
                      handleRateExperience();
                    }}
                  >
                    Rate the collection
                  </button>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
