import Swal from "sweetalert2";
import { createRequestor } from "../../api/requestorApi/sfdx_requestor_apis";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    debugger;
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        const user = firebase.auth().currentUser;
        /* if (!user.emailVerified) {
                  user.sendEmailVerification();
                  firebase.auth().signOut();
                  dispatch({ type: "EMAIL_NOT_VERIFIED" });
              } else { */
        firebase
          .firestore()
          .collection("user")
          .doc(user.uid)
          .get()
          .then((doc) => {
            console.log("FB Data ", doc.data());
            debugger;
            if (doc.data().user_type === "requestor") {
              dispatch({
                type: "LOGIN_SUCCESS",
                navTo: "/dashboard",
              });
            } else {
              dispatch({
                type: "LOGIN_SUCCESS",
                navTo:
                  doc.data().status === "New"
                    ? "/documents"
                    : "/collector_dashboard",
              });
              // if(doc.data().status==="New"){
              //     dispatch({
              //         type: "LOGIN_SUCCESS",
              //         navTo: "/UploadDocuments",
              //     });
              // }else{
              //     dispatch({
              //         type: "LOGIN_SUCCESS",
              //         navTo: "/collector_dashboard",
              //     });
              // }
            }
          })
          .catch((err) => {
            console.log("Error to fetch mentor profile ", err);
          })
          .finally(() => {});
      })
      .catch((err) => {
        dispatch({ type: "INCORRECT_CREDENTIAL", err });
      });
  };
};

export const signUp = (newUser, stauts) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SIGNUP_START" });
    debugger;
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user_email = newUser.email_id;
    const user_pass = newUser.password;

    try {
      let resp;
      try {
        resp = await firebase
          .auth()
          .createUserWithEmailAndPassword(newUser.email_id, newUser.password);
        await firebase.auth().signOut();
        // Continue with the sign-up process
      } catch (error) {
        debugger;
        if (error.code === "auth/email-already-in-use") {
          // Email already in use, update UI accordingly
          console.log(
            "The email address is already in use by another account."
          );
          dispatch({ type: "EMAIL_EXISTS", error: error });
        } else {
          // Other types of errors
          dispatch({ type: "SIGNUP_ERROR", error: error });
          console.log("Error during sign up:", error.message);
        }
        return;
      }

      delete newUser.password;
      newUser.firebase_id = resp.user.uid;

      // Salesforce API call
      const data = await createRequestor(newUser);

      if (data && !data.status) {
        dispatch({ type: "SIGNUP_ERROR", error: "email already exists" });
        Swal.fire({
          title: "Signup",
          text: "Email id is already registered",
          icon: "error",
        });
        return;
      } else if (data && data.status) {
        debugger;
        //{"user_type":"requestor","org_type":"individual","first_name":"test one","last_name":"ssurame","email_id":"test_123@gmail.com","add_city":"beng","add_street":"asdasd","add_state":"KA","add_country":"IN","add_pincode":"123123","phone":"1231231233","firebase_id":"TNUwenGLJfV88QZI9qaU8Xk62Sx2"}
        await firestore
          .collection("user")
          .doc(resp.user.uid)
          .set({
            first_name: newUser.first_name ? newUser.first_name : "",
            email: newUser.email_id ? newUser.email_id : "",
            last_name: newUser.last_name ? newUser.last_name : "",
            user_type: newUser.user_type ? newUser.user_type : "",
            street: newUser.add_street ? newUser.add_street : "",
            city: newUser.add_city ? newUser.add_city : "",
            state: newUser.add_state ? newUser.add_state : "",
            org_type: newUser.org_type ? newUser.org_type : "",
            country: newUser.add_country ? newUser.add_country : "",
            status: stauts ? stauts : "New",
            phone: newUser.phone ? newUser.phone : "",
            pincode: newUser.add_pincode ? newUser.add_pincode : "",
            sf_id: data.user_info.sfdc_id ? data.user_info.sfdc_id : "",
            acc_id: data.user_info.account_id ? data.user_info.account_id : "",
          });
        Swal.fire({
          title: "Signup",
          text: "You have successfully Registered!",
          icon: "success",
        });
        dispatch({ type: "SIGNUP_SUCCESS" });
      }
    } catch (error) {
      console.error("Error during signup:", error);
      await deleteUserAccount(firebase, user_email, user_pass);
      dispatch({ type: "SIGNUP_ERROR", error });
    }
  };
};

const deleteUserAccount = async (firebase, user_email, user_pass) => {
  try {
    debugger;
    await firebase.auth().signInWithEmailAndPassword(user_email, user_pass);
    await firebase.auth().currentUser.delete();

    console.log("User deleted successfully");
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

export const signOut = () => {
  debugger;
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};
