import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import './ProfileBlock.css'
// const {PROFILE_IMAGE} =  require('../../utils/imagepath')
const {PERSON} =  require('../../utils/imagepath')

export default function ProfileBlock({profileName, email, userType, editClickHandler}) { 

    const [buttonLabel, setButtonLabel] = useState("Edit");

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSave = () => {
        debugger;
        if(!editClickHandler()){
            setButtonLabel(buttonLabel === "Edit" ? "Save" : "Edit");
        }
       
    }


    return (
        <div className='profile-block'>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                {/* <img src={PROFILE_IMAGE} alt="" className='user-img' /> */}
                <img src={PERSON} alt="" className='user-img' />
                <div style={{ paddingLeft: '1.5rem' }}>
                    <h3>{profileName}</h3>
                    <Link className='email'>
                        {email}
                    </Link>
                    <span className='profile-type'>{capitalizeFirstLetter(userType)}</span>
                </div>
            </div>
            <div>
                {/* <Link className='ordered-btn'>
                    Change Photo
                </Link> */}
                {/* <Link className='green-btn' onClick={handleSave}>
                    {buttonLabel}
                </Link> */}
            </div>
        </div>
    )
};