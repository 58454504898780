import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
// import LeaderboardScore from '../../components/LeaderboardScore/LeaderboardScore'
// import ProfileCard from '../../components/ProfileCard/ProfileCard'
// import Notification from '../../components/Notification/Notification'
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Support from "../../../components/SupportCard/Support";
// import { getRequesterNotifications } from "../../api/requestorApi/sfdx_requestor_apis";
import { getSupportCases } from "../../../api/requestorApi/sfdx_requestor_apis";
import "../help_support/help_support.css";
import { connect } from "react-redux";
// import { firebase } from "../../config/fb_config";
import { firebase } from "../../../config/fb_config";
import swal from "sweetalert";
// import { getCollectorNotifications } from "../../api/collectorApi/sfdx_collector_apis";
const { BACK_BUTTON } = require("../../../utils/imagepath");

const HelpCases = ({ auth }) => {
  console.log("All servey -- -- ", auth);
  const [openCase, setOpenCase] = useState([]);
  const [closedCase, setClosedCase] = useState([]);
  const [newSurveys, setNewSurveys] = useState([]);
  const [sfId, setSfId] = useState(null);
  const [activeTab, setActiveTab] = useState("openQueries");
  const [filteredQueries, setFilteredQueries] = useState(null);
  const [userType, setUserType] = useState(null);
  const [queries, setQueries] = useState([]);
  console.log("Support Query-->", queries);
  useEffect(() => {
    try {
      firebase
        .firestore()
        .collection("user")
        .doc(auth.uid)
        .get()
        .then((doc) => {
          console.log("FB Data ", doc.data());
          setUserType(doc.data().user_type);
          setSfId(doc.data().sf_id);
          fetchData(doc.data().sf_id);
        })
        .catch((err) => {
          console.log("Error to fetch fb user", err);
        });
    } catch (error) {
      console.error("Error fetching support cases:", error);
    }

    //fetchData();
  }, [auth.uid]);
  const fetchData = async (SFID) => {
    debugger;
    try {
      const response_data = await getSupportCases(SFID);
      console.log("API response - ", response_data);
      if (response_data && response_data.status) {
        setQueries(response_data.mysupport);
      }
      debugger;
      if (Array.isArray(response_data.mysupport)) {
        const open = response_data.mysupport.filter(
          (item) => item.stage !== "Closed"
        );
        const closed = response_data.mysupport.filter(
          (item) => item.stage === "Closed"
        );
        setOpenCase(
          response_data.mysupport.filter((item) => item.stage !== "Closed")
        );
        setClosedCase(
          response_data.mysupport.filter((item) => item.stage === "Closed")
        );
      } else {
        console.error("Support data is not in the expected format:", queries);
      }
    } catch (error) {
      console.log("Exception fetching support cases - ", error);
    }
  };
  const handleTabClick = (tab) => {
    debugger;
    tab === "closedQueries"
      ? setFilteredQueries(
          queries.filter((ticket) => ticket.stage === "Closed")
        )
      : setFilteredQueries(
          queries.filter((ticket) => ticket.stage !== "Closed")
        );
    setActiveTab(tab);
    console.log("filtered data-->");
  };
  return (
    <div className="flex flex-row">
      <div className="flex-2">
        <Sidebar />
      </div>
      <div className="flex-1">
        <div className="mid-wrap">
          <h1>
            <Link to="/help_support">
              <img src={BACK_BUTTON} alt="" />
            </Link>
            {/* {userType === "requestor" ? (
              <Link to="/help_support">
                <img src={BACK_BUTTON} alt="" />
              </Link>
            ) : (
              <Link to="/collector_dashboard">
                <img src={BACK_BUTTON} alt="" />
              </Link>
            )} */}
            Support Queries
          </h1>
          <div className="tab-buttons">
            <button
              onClick={() => handleTabClick("openQueries")}
              className={activeTab === "openQueries" ? "active" : ""}
            >
              Open
            </button>
            <button
              onClick={() => handleTabClick("closedQueries")}
              className={activeTab === "closedQueries" ? "active" : ""}
            >
              Closed
            </button>
          </div>

          <div className="tab-content">
            {activeTab === "openQueries" &&
              (openCase.length > 0 ? (
                openCase.map((item) => (
                  <div>
                    <Support
                      subject={item.subject}
                      description={item.description}
                      date={item.date_raised}
                      stage={item.stage}
                    />
                  </div>
                ))
              ) : (
                <p
                  className="pt-4"
                  style={{ top: "50%", left: "50%", position: "absolute" }}
                >
                  No open queries available.
                </p>
              ))}
            {activeTab === "closedQueries" &&
              (closedCase.length > 0 ? (
                closedCase.map((item) => (
                  <div key={item.caseNumber}>
                    <Support
                      subject={item.subject}
                      description={item.description}
                      date={item.date_raised}
                      stage={item.stage}
                    />
                  </div>
                ))
              ) : (
                <p
                  className="pt-4"
                  style={{ top: "50%", left: "50%", position: "absolute" }}
                >
                  No closed queries available.
                </p>
              ))}
          </div>
        </div>
      </div>
      <div className="flex-2">
        <div className="right-section">
          {/* <LeaderboardScore />                     */}
          <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            {/* <ProfileCard /> */}
          </div>
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, null)(HelpCases);
